import * as React from 'react';

function ClickToListenIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} viewBox="0 0 20 20" fill="none" {...props}>
      <g id="Icon">
        <path
          id="Vector"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10 4C6.68629 4 4 6.68629 4 10C4 12.5572 5.60013 14.7431 7.85723 15.6064C8.37308 15.8037 8.63131 16.3818 8.43402 16.8976C8.23672 17.4134 7.65861 17.6717 7.14277 17.4744C4.13713 16.3248 2 13.4132 2 10C2 5.58172 5.58172 2 10 2C13.4132 2 16.3248 4.13713 17.4744 7.14277C17.6717 7.65861 17.4134 8.23672 16.8976 8.43402C16.3818 8.63131 15.8037 8.37308 15.6064 7.85723C14.7431 5.60013 12.5572 4 10 4ZM8.85176 9.4842C8.72147 9.09332 9.09334 8.72145 9.48422 8.85174L18.0224 11.6978C18.4413 11.8374 18.4868 12.4119 18.095 12.6157L15.4208 14.0066L18.435 17.0208C18.8256 17.4113 18.8256 18.0445 18.435 18.435C18.0445 18.8255 17.4113 18.8255 17.0208 18.435L14.0066 15.4208L12.6157 18.0949C12.412 18.4867 11.8375 18.4413 11.6978 18.0223L8.85176 9.4842Z"
          className="fill-icon-secondary"
        />
      </g>
    </svg>
  );
}

export default ClickToListenIcon;
