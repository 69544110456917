import * as React from 'react';

function UrlIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.892 3.128a5.646 5.646 0 017.985 7.985l-1.113 1.113a1 1 0 01-1.415-1.415l1.113-1.113a3.646 3.646 0 10-5.156-5.156L9.193 5.655A1 1 0 017.78 4.241l1.113-1.113zm4.335 3.645a1 1 0 010 1.415l-5.04 5.037a1 1 0 11-1.414-1.414l5.04-5.038a1 1 0 011.414 0zm-7.58 2.422a1 1 0 10-1.414-1.414l-1.11 1.11a5.644 5.644 0 007.982 7.982l1.11-1.11a1 1 0 00-1.414-1.414l-1.11 1.11a3.644 3.644 0 11-5.154-5.154l1.11-1.11z"
        className="fill-icon-secondary"
      />
    </svg>
  );
}

export default UrlIcon;
