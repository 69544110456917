import { WebAppImportFlow } from 'components/library/import/constants';

import { createEventEmitter } from 'lib/speechify/EventEmitter';
import { replaceUrlWithoutAnyEffect } from 'utils/navigation';
import { Nullable } from 'utils/types';

export enum ContentMetaType {
  PDF = 'PDF',

  HTML = 'HTML',
  DOCX = 'DOCX',
  TXT = 'TXT',
  SCAN = 'SCAN',
  EPUB = 'EPUB',

  UNKNOWN = 'UNKNOWN'
}

export type ContentMetaTypeLowercase = Lowercase<ContentMetaType>;

export enum ContentType {
  libraryItem = 'libraryItem',
  file = 'file',
  url = 'url'
}

export abstract class BaseListenableContent {
  protected eventEmitter = createEventEmitter<{
    onTitleUpdate: {};
  }>({
    events: ['onTitleUpdate']
  });

  public readonly itemIdPromise: Promise<string>;

  protected _titleOverride: string | null = null;
  protected _itemIdResolver!: (itemId: string) => void;

  abstract get title(): string;
  abstract get metaType(): ContentMetaType;
  abstract get contentType(): ContentType;
  abstract get wordCount(): Nullable<number>;

  // Import flow is only defined for recently imported content.
  abstract get importFlow(): Nullable<WebAppImportFlow>;

  constructor() {
    this.itemIdPromise = new Promise(resolve => {
      this._itemIdResolver = resolve;
    });
  }

  updateTitle(title: string) {
    this._titleOverride = title;
    this.eventEmitter.emit('onTitleUpdate', { title });
  }

  isPDF = (): boolean => this.metaType === ContentMetaType.PDF;

  listenToTitleUpdate(event: 'onTitleUpdate', listener: (latestTitle: string) => void) {
    const callback = () => listener(this.title);
    this.eventEmitter.on(event, callback);
    listener(this.title);
    return () => this.eventEmitter.off(event, callback);
  }

  onItemIdReady(itemId: string): void {
    // this is used by useListenableContentItemId state to trigger rerender when item ID is ready,
    // which is useful for hiding/displaying features that depends on item ID readiness.
    this._itemIdResolver(itemId);
  }

  subscribeToAutoUpdateUrlWhenItemIdReady() {
    let canceled = false;
    this.itemIdPromise.then(itemId => {
      if (canceled) return;

      // replace client side URL with /item/{itemId}
      replaceUrlWithoutAnyEffect(`/item/${itemId}`);
    });
    return () => {
      canceled = true;
    };
  }
}
