import { useAskAiStore } from 'modules/ask-ai/store';
import { usePlaybackStore } from 'modules/listening/stores/playback/playbackStore';
import { PlaybackInfo, SDKVoiceFacade, VoiceInfo } from 'modules/sdk/lib';

import { useVoiceStore } from '../voicesStore';

export const playPreview = (voiceInfo: VoiceInfo, currentPlaybackInfo: PlaybackInfo | null = null) => {
  const playbackInfo = currentPlaybackInfo ?? usePlaybackStore.getState().currentPlaybackInfo;
  const { playbackInfo: askAiPlaybackInfo } = useAskAiStore.getState();
  if (askAiPlaybackInfo?.latestState.isPlaying) return;
  if (playbackInfo?.latestState.isPlaying) return;

  useVoiceStore.setState(state => ({ ...state, isPreviewPlaying: true }));
  SDKVoiceFacade.singleton.playPreview(voiceInfo, isPreviewPlaying => {
    useVoiceStore.setState(state => ({ ...state, isPreviewPlaying }));
  });
};
