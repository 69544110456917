import { initializeSubscriptionStore } from './initializeSubscriptionStore';
import { alsoTrackWordsFor, startWordTracker } from './startWordTracker';
import { switchToFree } from './switchToFree';

export const subscriptionStoreActions = {
  initializeSubscriptionStore,
  startWordTracker,
  alsoTrackWordsFor,
  switchToFree
};
