import React, { useEffect, useRef } from 'react';

import ZoomInIcon from '../icons/ZoomInIcon';
import ZoomOutIcon from '../icons/ZoomOutIcon';

export interface ZoomControlProps {
  maxZoom?: number;
  minZoom?: number;
  onZoomChange: (zoom: number, constraints: { minZoom: number; maxZoom: number }) => void;
  zoom: number;
  zoomStep?: number;
}

const ZoomControl: React.FC<ZoomControlProps> = ({ minZoom = 20, maxZoom = 200, zoomStep = 10, onZoomChange, zoom }) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const handleInputBlur = () => {
    if (inputRef.current) {
      inputRef.current.value = zoom.toString();
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(event.target.value, 10);
    const newZoom = isNaN(value) ? minZoom : Math.min(Math.max(minZoom, value), maxZoom);
    onZoomChange(newZoom, { minZoom, maxZoom });
  };

  const handleZoomIn = () => {
    const newZoom = Math.min(zoom + zoomStep, maxZoom);
    onZoomChange(newZoom, { minZoom, maxZoom });
    if (inputRef.current) {
      inputRef.current.value = newZoom.toString();
    }
  };

  const handleZoomOut = () => {
    const newZoom = Math.max(zoom - zoomStep, minZoom);
    onZoomChange(newZoom, { minZoom, maxZoom });
    if (inputRef.current) {
      inputRef.current.value = newZoom.toString();
    }
  };

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.value = zoom.toString();
    }
  }, [zoom]);

  return (
    <div className="flex justify-between items-center px-spl-7 pt-spl-6">
      <div className="text-text-primary text-heading-5">Zoom</div>
      <div className="flex items-center gap-spl-6">
        <button className="flex items-center justify-center focus:outline-none disabled:opacity-60" onClick={handleZoomOut} disabled={zoom <= minZoom}>
          <ZoomOutIcon />
        </button>
        <div className="relative">
          <input
            className="text-body-6 text-text-primary py-spl-4 pr-[14px] pl-[6px] w-16 tabular-nums rounded-200 border-s bg-surface-primary border-border-primary text-center no-arrows"
            defaultValue={zoom}
            onBlur={handleInputBlur}
            onChange={handleInputChange}
            maxLength={3}
            ref={inputRef}
            type="number"
            pattern="\d*"
          />
          <span className="absolute right-2 top-1/2 transform -translate-y-1/2 text-gray-500">%</span>
        </div>
        <button className="flex items-center justify-center focus:outline-none disabled:opacity-60" onClick={handleZoomIn} disabled={zoom >= maxZoom}>
          <ZoomInIcon />
        </button>
      </div>
    </div>
  );
};

export default ZoomControl;
