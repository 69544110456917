import * as React from 'react';

function ChevronDownIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} viewBox="0 0 16 16" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.197 5.136a.75.75 0 010 1.06L8.53 10.865a.75.75 0 01-1.06 0L2.802 6.197a.75.75 0 111.06-1.06L8 9.272l4.136-4.137a.75.75 0 011.061 0z"
        fill="currentColor"
      />
    </svg>
  );
}

export default ChevronDownIcon;
