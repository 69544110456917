// Whenever you update this enum, please update this docs with screenshot and notify Simeon:
// https://docs.google.com/document/d/1loTa7LUJ5tXVJ40s5bgKk77awR1BQEXbj85DnwaLTvI/edit?tab=t.t2fc56xm8233
export enum SDKContentSubType {
  AI_CHAT = 'ai_chat',
  AI_STORY = 'ai_story',
  GOOGLE_DRIVE = 'google_drive',
  DROPBOX = 'dropbox',
  ONE_DRIVE = 'onedrive',
  CANVAS = 'canvas',
  TYPE_OR_PASTE_TEXT = 'type_or_paste_text',
  LOCAL_FILES = 'local_files',
  WEB_LINK = 'paste_weblink',
  CHROME_EXTENSION_PDF_PLAY = 'pill_player_pdf_play_button',
  LIBRARY = 'library' // 'library' is used for legacy library items that are imported before analytics adapter was added to SDK
}
