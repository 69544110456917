import { SpeechifyPersistedStoreState } from 'store';
import { StoreApi, create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';

import { REDUX_IDB_DB_NAME, REDUX_IDB_OBJECT_STORE_NAME } from 'store/constants';

import { forceMigrationOnInitialPersist } from '../utils/forceMigrationOnInitialPersist';
import { nestedJsonParse } from '../utils/parse';
import { IndexedDBStorage } from '../utils/storage';
import { PersistentStoreOptions, StateCreator } from './types';

export interface LocalStorageStoreOptions<State, PersistedState = State> extends PersistentStoreOptions<State, PersistedState> {
  backfillStateFromReduxPersist?: (reduxPersistedState: SpeechifyPersistedStoreState, defaultState: State) => State;
}

export type LocalStorageStore<State> = {
  (): State;
  <PartialState>(selector: (state: State) => PartialState): PartialState;
  getState: StoreApi<State>['getState'];
  setState: StoreApi<State>['setState'];
  subscribe: StoreApi<State>['subscribe'];
  waitForInitialHydration: () => Promise<void>;
  registerOnResetCleanup: (fn: () => void) => void;
  reset: () => void;
};

export function createLocalStorageStore<State, PersistedState = State>(
  initialState: StateCreator<State>,
  options: LocalStorageStoreOptions<State, PersistedState>
) {
  if (options.version && options.version <= 0) {
    throw new Error('IndexedDbStore: version must be greater than 0');
  }

  if (!options.storageName) {
    throw new Error('IndexedDbStore: storageName is required');
  }

  const { backfillStateFromReduxPersist } = options;

  const store = create(
    persist(initialState, {
      ...options,
      skipHydration: typeof window === 'undefined', // skip hydration on SSR
      name: options.storageName,
      storage: forceMigrationOnInitialPersist(createJSONStorage(() => localStorage)),
      version: options.version ?? 1,
      migrate: async (state, version, ...args) => {
        state ||= initialState();
        if (version === 0 && typeof backfillStateFromReduxPersist === 'function') {
          const speechifyWebDb = new IndexedDBStorage(REDUX_IDB_OBJECT_STORE_NAME, REDUX_IDB_DB_NAME);
          const persistedReduxStateJsonString = (await speechifyWebDb.getItem('persist:root')) || '{}';
          const persistedReduxRootState = nestedJsonParse(persistedReduxStateJsonString) as SpeechifyPersistedStoreState;
          const backfilledState = backfillStateFromReduxPersist(persistedReduxRootState, state as State);
          return backfilledState;
        }
        return (options.migrate && options.migrate(state, version, ...args)) || state;
      }
    })
  );

  const useStore = <U>(selector: (state: State) => U) => {
    return store(selector ?? (state => state));
  };

  const waitForInitialHydration = async () => {
    return Promise.race([
      new Promise(reject => {
        setTimeout(() => {
          reject(new Error('waitForInitialHydration timed out'));
        }, 2000);
      }),
      new Promise<void>(resolve => {
        if (store.persist.hasHydrated()) {
          resolve();
          return;
        }
        const cleanUp = store.persist.onFinishHydration(() => {
          cleanUp();
          resolve();
        });
      })
    ]);
  };

  let cleanupFunctions: (() => void)[] = [];

  const registerOnResetCleanup = (fn: () => void) => {
    cleanupFunctions.push(fn);
  };

  const reset = () => {
    cleanupFunctions.forEach(fn => fn());
    store.persist.rehydrate();
    cleanupFunctions = [];
  };

  Object.assign(useStore, { ...store, waitForInitialHydration, registerOnResetCleanup, reset });

  return useStore as LocalStorageStore<State> & {
    persist: (typeof store)['persist'];
  };
}
