import { VoiceInfo } from 'modules/sdk/lib';

import { useVoiceStore } from '../voicesStore';

export const deleteVoiceFromHistory = (voice: VoiceInfo) => {
  useVoiceStore.setState(state => {
    return {
      selectionHistory: state.selectionHistory.filter(v => v.id !== voice.id)
    };
  });
};
