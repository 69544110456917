import { IRecord, RecordType } from 'interfaces';

export const supportedRecordTypes = [RecordType.DEFAULT, RecordType.FILE, RecordType.PDF, RecordType.SCAN, RecordType.TXT, RecordType.WEB, RecordType.EPUB];

const checkFileType = (itemType: $TSFixMe) => ['file', 'pdf'].includes(itemType);

export const itemTypeFilterFn =
  (selectedItemType: $TSFixMe, folderId: string | null = null) =>
  (item: IRecord) => {
    const itemRecordType = item.recordType?.toLowerCase();
    const isItemIncluded =
      selectedItemType === 'all' || selectedItemType === itemRecordType || (checkFileType(selectedItemType) && checkFileType(itemRecordType));

    return isItemIncluded && supportedRecordTypes.includes(item.recordType) && item.parentFolderId === folderId;
  };
