import { Buffer } from 'buffer';

import { AbstractLocalKeyValueStorageAdapter } from '@speechifyinc/multiplatform-sdk/api/adapters/keyvalue';
import { Result, SDKError } from '@speechifyinc/multiplatform-sdk/api/util';
import { BoundaryPair } from '@speechifyinc/multiplatform-sdk/api/util/boundary';

import { Callback } from './lib/typeAliases';

export class WebLocalKeyValueStore extends AbstractLocalKeyValueStorageAdapter {
  private storage: { [key: string]: string } = {};

  // ESLint: '_keys' is defined but never used & '_callback' is defined but never used
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-unused-vars
  getData(_keys: string[], _callback: Callback<Result<Int8Array>[]>) {
    throw new Error('not implemented');
  }

  // ESLint: '_keys' is defined but never used & '_callback' is defined but never used
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-unused-vars
  putData(_keys: BoundaryPair<string, Int8Array>[], _callback: Callback<Result<void>[]>) {
    throw new Error('not implemented');
  }

  // ESLint: '_keys' is defined but never used & '_callback' is defined but never used
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-unused-vars
  deleteData(_keys: string[], _callback: Callback<Result<void>[]>) {
    throw new Error('not implemented');
  }

  deleteSingle(key: string, callback: Callback<void>) {
    const k = makeKey(key);
    if (typeof window !== 'undefined') {
      window.localStorage.removeItem(k);
    } else {
      delete this.storage[k];
    }
    callback(new Result.Success<void>(undefined));
  }

  putSingle(key: string, data: Int8Array, callback: Callback<void>) {
    const k = makeKey(key);
    if (typeof window !== 'undefined') {
      window.localStorage.setItem(k, Buffer.from(data).toString('base64'));
    } else {
      this.storage[k] = Buffer.from(data).toString('base64');
    }
    callback(new Result.Success<void>(undefined));
  }

  getSingle(key: string, callback: Callback<Int8Array>) {
    const k = makeKey(key);
    const value = typeof window !== 'undefined' ? window.localStorage.getItem(k) : this.storage[k];

    if (!value) {
      return callback(new Result.Failure(new SDKError.ResourceNotFound(key, 'No value for this key set.')));
    }
    callback(new Result.Success(new Int8Array(Buffer.from(value, 'base64'))));
  }
}

function makeKey(key: string): string {
  return `MP_SDK_${key}`;
}
