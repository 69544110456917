import { createSlice } from '@reduxjs/toolkit';
export type WordsListenedByVoice = { [voiceId: string]: number };

const name = 'app';
const initialState: { locale: string } = {
  locale: 'en'
};

const { actions: generatedActions, reducer } = createSlice({
  name,
  initialState,
  reducers: {
    setLocale: (state, action) => {
      state.locale = action.payload;
    }
  }
});

const actions = { ...generatedActions };
export { actions };

export default reducer;
