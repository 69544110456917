import { createSelector, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'store';

const name = 'onboarding';

const READER_ONBOARDING_STEPS = {
  CHANGE_VOICE: 'changeVoice',
  CHANGE_VOICE_MENU_OPENED: 'changeVoiceMenuOpened',
  ADJUST_SPEED: 'adjustSpeed',
  ADJUST_SPEED_MENU_OPENED: 'adjustSpeedMenuOpened',
  OPEN_SETTINGS: 'openSettings',
  OPEN_SETTINGS_MENU_OPENED: 'openSettingsMenuOpened',
  USE_PLAYPAUSE_CONTROL: 'usePlayPauseControl',
  GO_BACK_TO_LIBRARY: 'goBackToLibrary'
} as const;

type ObjectValues<t> = t[keyof t];
type ReaderOnboardingSteps = ObjectValues<typeof READER_ONBOARDING_STEPS>;

type OnboardingState = {
  onboardingCompleted: boolean;
  readerOnboardingStarted: boolean;
  readerOnboardingCompleted: boolean;
  checklistShowing: boolean;
  itemIndex: number;
  progressPercentage: number;
  showReaderOnboardingTips: boolean;
  showReaderOnboardingTipsDisplayButton: boolean;
  currentReaderOnboardingStep: ReaderOnboardingSteps | null;
  completedReaderSteps: ReaderOnboardingSteps[];
  openedGoogleDoc: boolean;
  onboardingCompletedNotificationDismissed: boolean;
};

const initialState: OnboardingState = {
  onboardingCompleted: false,
  readerOnboardingStarted: false,
  readerOnboardingCompleted: false,
  checklistShowing: true,
  itemIndex: 0,
  showReaderOnboardingTips: true,
  showReaderOnboardingTipsDisplayButton: true,
  progressPercentage: 0,
  currentReaderOnboardingStep: null,
  completedReaderSteps: [],
  openedGoogleDoc: false,
  onboardingCompletedNotificationDismissed: false
};

const { actions: generatedActions, reducer } = createSlice({
  name,
  initialState,
  reducers: {
    setProgress: (state, action: { payload: { itemIndex: number; percentage: number } }) => {
      state.itemIndex = action.payload.itemIndex;
      state.progressPercentage = action.payload.percentage;
    },
    setChecklistShowing: (state, action: { payload: boolean }) => {
      state.checklistShowing = action.payload;
    },
    setReaderOnboardingStep: (state, action: { payload: { step: ReaderOnboardingSteps | null } }) => {
      state.showReaderOnboardingTips = true;
      state.currentReaderOnboardingStep = action.payload.step;
      if (action.payload.step && state.showReaderOnboardingTips) {
        state.completedReaderSteps = [...state.completedReaderSteps, action.payload.step];
      }
    },
    setReaderOnboardingTipsShowing: (state, action: { payload: boolean }) => {
      state.showReaderOnboardingTips = action.payload;
    },
    setShowReaderOnboardingTipsDisplayButton: (state, action: { payload: boolean }) => {
      state.showReaderOnboardingTipsDisplayButton = action.payload;
    },
    setReaderOnboardingCompleted: (state, action: { payload: boolean }) => {
      state.readerOnboardingCompleted = action.payload;
    },
    setGoogleDocOpened: (state, action: { payload: boolean }) => {
      state.openedGoogleDoc = action.payload;
    },
    setOnboardingCompleted: (state, action: { payload: boolean }) => {
      state.onboardingCompleted = action.payload;
    },
    setOnboardingCompletedNotificationDismissed: (state, action: { payload: boolean }) => {
      state.onboardingCompletedNotificationDismissed = action.payload;
    },
    setReaderOnboardingStarted: (state, action: { payload: boolean }) => {
      state.readerOnboardingStarted = action.payload;
    }
  }
});

export const getOnboardingProgress = () =>
  createSelector(
    (state: RootState) => state.onboarding,
    (onboarding: OnboardingState) => ({ itemIndex: onboarding.itemIndex, percentage: onboarding.progressPercentage })
  );

export const getCurrentReaderOnboardingStep = () =>
  createSelector(
    (state: RootState) => state.onboarding,
    (onboarding: OnboardingState) => onboarding.currentReaderOnboardingStep
  );

const actions = { ...generatedActions };

const selectors = {
  getOnboardingProgress,
  getCurrentReaderOnboardingStep
};

export { actions, selectors };

export default reducer;
