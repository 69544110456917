import * as React from 'react';

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 4a2 2 0 012-2h14a2 2 0 012 2v10a2 2 0 01-2 2h-2a1 1 0 110-2h2V7H3v7h2a1 1 0 110 2H3a2 2 0 01-2-2V4zm2 1h14V4H3v1zm7.636 3.264a.9.9 0 00-1.272 0l-3 3a.9.9 0 001.272 1.272L9 11.016v7.397a1 1 0 102 0v-7.398l1.364 1.521a.9.9 0 101.272-1.272l-3-3z"
        className="fill-icon-secondary"
      />
    </svg>
  );
}

export default SvgComponent;
