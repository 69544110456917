import { createAsyncThunk } from '@reduxjs/toolkit';

import { ErrorSource } from 'config/constants/errors';
import { logError } from 'lib/observability';
import * as speechify from 'lib/speechify';

import { actions } from './index';

export const deletePersonalVoice = createAsyncThunk('personalVoices/delete', async (voiceId: string, { rejectWithValue }) => {
  try {
    await speechify.deletePersonalVoice(voiceId);
    return voiceId;
  } catch (error) {
    // ESLint: Unexpected any
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const err = error as any;

    logError(err, ErrorSource.PERSONAL_VOICES, {
      context: {
        operation: 'delete_personal_voice',
        voiceId
      }
    });

    if (!err.response) {
      throw err.message;
    }

    rejectWithValue(err.response.data);
  }
});

export const refresh = createAsyncThunk('personalVoices/refresh', async (_, { dispatch }) => {
  const personalVoices = await speechify.getPersonalVoices();

  if (personalVoices) {
    dispatch(actions.set(personalVoices));
  }
});
