import { usePlaybackStore } from 'modules/listening/stores/playback/playbackStore';
import { SDKVoiceFacade, VoiceInfo } from 'modules/sdk/lib';
import type { ListeningDependencies } from 'modules/sdk/listeningDependencies';
import { ExtensionMessageEmitter, ExtensionVoice } from 'utils/extension';

import { useVoiceStore } from '../voicesStore';
import { setVoice } from './setVoice';
import { stopPreview } from './stopPreview';

const isEquivalentVoice = (extensionVoice: ExtensionVoice, webVoiceInfo: VoiceInfo): boolean => {
  const extensionVoiceSpec = SDKVoiceFacade.singleton.generateVoiceSpec(extensionVoice);
  if (!extensionVoiceSpec) {
    return false;
  }
  const extensionVoiceInfo = SDKVoiceFacade.singleton.mapToVoiceInfo(extensionVoiceSpec);
  return webVoiceInfo.id === extensionVoiceInfo.id;
};

export const initializeVoiceStore = async (deps: ListeningDependencies) => {
  useVoiceStore.setState({
    isLoading: false,
    lastPlayingVoice: deps.playbackInfo.voiceOfCurrentUtterance,
    recommendedVoices: SDKVoiceFacade.singleton.getRecommendedVoiceInfos(),
    personalVoices: SDKVoiceFacade.singleton.getPersonalVoiceInfos(),
    allVoices: SDKVoiceFacade.singleton.getAllVoiceInfos()
  });

  // note: it's safe to not clean this up because the listener will be removed when the PlaybackInfo is destroyed, see PlaybackInfo.destroy() implementation
  const playbackInfo = usePlaybackStore.getState().currentPlaybackInfo;

  playbackInfo?.addStateListener(state => {
    if (state.isPlaying) {
      stopPreview();
    }
  });

  const cleanupExtensionSetVoiceMessageListener = ExtensionMessageEmitter.on('setVoice', ({ voice }) => {
    const { allVoices, lastPlayingVoice } = useVoiceStore.getState();
    const voiceToSet = allVoices.find(v => isEquivalentVoice(voice, v));
    if (voiceToSet && lastPlayingVoice !== voiceToSet) setVoice(voiceToSet, { shouldPlayPreview: false, isChangeFromExtension: true });
  });

  useVoiceStore.registerOnResetCleanup(cleanupExtensionSetVoiceMessageListener);
};
