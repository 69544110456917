import React from 'react';

export default function GoogleDriveIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M26.7896 25.9833L28.3838 31.5752L32.5614 36.1521C33.1265 35.8209 33.6074 35.3402 33.9456 34.744L37.8271 27.9064C38.1653 27.3124 38.3333 26.6478 38.3333 25.9833L32.3724 24.8081L26.7896 25.9833Z"
        fill="#EA4335"
      />
      <path
        d="M25.7712 3.84845C25.2062 3.51726 24.5572 3.3335 23.8808 3.3335H16.1199C15.4477 3.3335 14.7924 3.52153 14.2295 3.84845L15.9014 9.57709L19.9846 13.9873L19.9993 14.0173L24.1119 9.55358L25.7712 3.84845Z"
        fill="#188038"
      />
      <path
        d="M13.2082 25.9826L7.25777 24.5938L1.6665 25.9826C1.6665 26.645 1.83454 27.3074 2.1706 27.9036L6.05214 34.7433C6.3903 35.3373 6.87129 35.8181 7.4363 36.1514L11.551 31.8395L13.2082 25.9826Z"
        fill="#1967D2"
      />
      <path
        d="M32.4901 14.6585L27.1551 5.25675C26.8169 4.6606 26.3359 4.17983 25.7709 3.84863L19.999 14.0174L26.7896 25.9832H38.3313C38.3313 25.3187 38.1633 24.6563 37.8251 24.0602L32.4901 14.6585Z"
        fill="#FBBC04"
      />
      <path
        d="M26.7896 25.9829H13.2084L7.43652 36.1517C8.00153 36.4829 8.65055 36.6667 9.32688 36.6667H30.669C31.3454 36.6667 31.9944 36.4829 32.5594 36.1517L26.7896 25.9829Z"
        fill="#4285F4"
      />
      <path
        d="M19.9988 14.0174L14.229 3.84863C13.664 4.17983 13.1851 4.65846 12.8469 5.25248L2.1706 24.0666C1.83454 24.6606 1.6665 25.323 1.6665 25.9832H13.2082L19.9988 14.0174Z"
        fill="#34A853"
      />
    </svg>
  );
}
