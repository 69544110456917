import { createContext, useContext, useState } from 'react';

export type SearchState =
  | {
      open: false;
    }
  | {
      open: true;
      query: string;
    };

export function useVoiceSearch() {
  const [searchState, setSearchState] = useState<SearchState>({
    open: false
  });

  const handleSearchChange = (query: string) => {
    setSearchState({
      open: true,
      query
    });
  };

  const handleSearchOpenChange = (open: boolean) => {
    if (open) {
      setSearchState({ open: true, query: '' });
    } else {
      setSearchState({ open: false });
    }
  };

  return {
    searchOpen: searchState.open,
    query: searchState.open ? searchState.query : null,
    handleSearchChange,
    handleSearchOpenChange
  };
}

type VoiceSearchContextType = ReturnType<typeof useVoiceSearch>;

export const VoiceSearchContext = createContext<VoiceSearchContextType | undefined>(undefined);

export const useVoiceSearchContext = () => {
  const context = useContext(VoiceSearchContext);
  if (context === undefined) {
    throw new Error('useVoiceSearchContext must be used within a VoiceSearchProvider');
  }
  return context;
};
