import type {
  ContentOverlayRange,
  CurrentWordAndSentenceOverlayHelper,
  OriginalContentOverlayProvider,
  RenderedContentOverlayProvider
} from '@speechifyinc/multiplatform-sdk';

import { EventEmitter, createEventEmitter } from 'lib/speechify/EventEmitter';
import { BaseDestructible } from 'utils/destructible';

export type SentenceMark = {
  rects: DOMRect[];
  playFromHere: (e: MouseEvent | TouchEvent) => void;
};

export type HighlightingInfo = {
  word: {
    rects: DOMRect[];
  };
  sentence: {
    rects: DOMRect[];
  };
};

// Relative position of the playback cursor to the current viewport/scroll position
export type PlaybackCursorPosition = 'above' | 'below' | 'notFound';

export abstract class OverlayInfo<OverlayType> extends BaseDestructible {
  abstract get overlayHelper(): CurrentWordAndSentenceOverlayHelper<OverlayType>;
  abstract get overlayProvider(): RenderedContentOverlayProvider<OverlayType> | OriginalContentOverlayProvider<OverlayType>;
  abstract overlayRangeToRects(range: ContentOverlayRange<OverlayType>, baseRect: DOMRect): DOMRect[];
  abstract getBaseRect(): DOMRect;
  abstract get scroller(): HTMLElement;

  // null means the sentence is not visible
  abstract getActiveSentenceTopPositionForAutoScroll(sentenceOverlayRanges: Array<ContentOverlayRange<OverlayType>>): Promise<number | null>;

  abstract get highlightContainer(): HTMLElement;

  // TODO(albertusdev): Potentially provide a default implementation here that just attaches to overlayHelper and customHighlightingEventEmitter
  // This is used for building highlighting feature
  public abstract listenToCurrentWordAndSentenceHighlighting(callback: (currentWordAndSentence: HighlightingInfo) => void): () => void;

  // This is used for building click to listen feature
  public abstract getRelevantClickToListenSentenceMark(x: number, y: number): Promise<SentenceMark | null>;

  protected customHighlightingEventEmitter: EventEmitter<{
    zoom: {
      zoomScale: number;
    };
  }> = createEventEmitter({ events: ['zoom'] });

  public emitZoomEvent(zoomScale: number) {
    this.customHighlightingEventEmitter.emit('zoom', { zoomScale });
  }

  public abstract getCurrentPlaybackCursorPosition(): PlaybackCursorPosition;

  public inlineCustomPostPageContentContainer: HTMLElement | null = null;
  public inlineCustomPostPageContentContainerUpdatedCallbacks: ((container: HTMLElement | null) => void)[] = [];

  public setInlineCustomPostPageContentContainer(container: HTMLElement | null): void {
    this.inlineCustomPostPageContentContainer = container;
    this.inlineCustomPostPageContentContainerUpdatedCallbacks.forEach(callback => callback(container));
  }

  public onInlineCustomPostPageContentContainerUpdated(callback: (container: HTMLElement | null) => void): () => void {
    this.inlineCustomPostPageContentContainerUpdatedCallbacks.push(callback);
    return () => {
      this.inlineCustomPostPageContentContainerUpdatedCallbacks = this.inlineCustomPostPageContentContainerUpdatedCallbacks.filter(cb => cb !== callback);
    };
  }
}
