export const firebaseConfig = {
  apiKey: process.env.NEXT_PUBLIC_FB_API_KEY,
  authDomain: process.env.NEXT_PUBLIC_FB_AUTH_DOMAIN,
  databaseURL: process.env.NEXT_PUBLIC_FB_DATABASE_URL,
  projectId: process.env.NEXT_PUBLIC_FB_PROJECT_ID,
  storageBucket: process.env.NEXT_PUBLIC_FB_STORAGE_BUCKET,
  messagingSenderId: process.env.NEXT_PUBLIC_FB_MESSAGING_SENDER_ID,
  appId: process.env.NEXT_PUBLIC_FB_APP_ID,
  dynamicLinkDomain: process.env.NEXT_PUBLIC_FB_DYNAMIC_LINK_DOMAIN,
  iosBundleId: process.env.NEXT_PUBLIC_FB_IOS_BUNDLE_ID,
  iosAppstoreId: process.env.NEXT_PUBLIC_FB_IOS_APPSTORE_ID
};
