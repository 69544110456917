import { navStoreActions, useNavStore } from 'modules/listening/navStore';
import { usePlaybackStore } from 'modules/listening/stores/playback/playbackStore';
import { SLIDE_TRANSITION_DURATION_IN_MS } from 'modules/listening/utils/constants';

export const handleOnSearchIconClickForPDF = () => {
  const activeTab = useNavStore.getState().activeTab;
  const currentPspdfKitFacade = usePlaybackStore.getState().currentPspdfKitFacade;
  if (activeTab === 'search') {
    navStoreActions.clearActiveTab();
    currentPspdfKitFacade?.hideSearchUI();
  } else {
    if (activeTab !== null) {
      navStoreActions.clearActiveTab();

      // set timeout to finish the animation of switching tabs
      // this is needed because somehow when you trigger the search UI when the active tab slide out animation has not done it will result in distorted UI
      setTimeout(() => {
        navStoreActions.setActiveTab('search');
        currentPspdfKitFacade?.openSearchUI();
      }, SLIDE_TRANSITION_DURATION_IN_MS + 100);
    } else {
      navStoreActions.setActiveTab('search');
      currentPspdfKitFacade?.openSearchUI();
    }
  }
};
