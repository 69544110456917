import type { ContentOverlayRange } from '@speechifyinc/multiplatform-sdk';
import { debounce } from 'lodash';

import { createNonPersistentStore } from 'lib/zustand';
import { EmbeddedOverlayInfo } from 'modules/sdk/lib/facade/overlay/EmbeddedOverlayInfo';
import { EmbeddedListeningDependencies, ListeningDependencies } from 'modules/sdk/listeningDependencies';

import { useListeningSettingsStore } from '../settings/settingsStore';
import { addEventListener, easing, getAutoScrollAnchor, isMouseOverScrollbar } from './utils';

type BaseFields = {
  state: string;
  temporaryEnabled: boolean;
};

type NotInitializedState = BaseFields & {
  state: 'not_initialized';
};

export type AutoScrollStoreInitializedState = BaseFields & {
  cleanUpFunction: () => void;
  forceScrollToActiveSentence: () => void;
  overlayInfo: ListeningDependencies['overlayInfo'] | EmbeddedListeningDependencies['overlayInfo'];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  activeSentenceOverlayRanges?: ContentOverlayRange<any>[];
  onGoingScrollAnimation?: number;
};

type State = NotInitializedState | AutoScrollStoreInitializedState;

export const createAutoScrollStore = () => {
  return createNonPersistentStore<State>(
    () => {
      return {
        state: 'not_initialized',
        temporaryEnabled: false
      };
    },
    {
      isListeningScreenStore: true
    }
  );
};

export const useAutoScrollStore = createAutoScrollStore();

export const isAutoScrollEnabled = (targetAutoScrollStore: ReturnType<typeof createAutoScrollStore> = useAutoScrollStore) => {
  const settings = useListeningSettingsStore.getState().autoScroll;
  const temporary = targetAutoScrollStore.getState().temporaryEnabled;
  return settings && temporary;
};

export const initializeAutoScroll = async ({
  overlayInfo,
  playbackInfo,
  targetAutoScrollStore
}: {
  overlayInfo: AutoScrollStoreInitializedState['overlayInfo'];
  playbackInfo: ListeningDependencies['playbackInfo'];
  targetAutoScrollStore?: ReturnType<typeof createAutoScrollStore>;
}) => {
  targetAutoScrollStore ??= useAutoScrollStore;

  const state = targetAutoScrollStore.getState();

  if (state.state === 'initialized') {
    state.cleanUpFunction();
  }

  const getSentenceBoundary = (sentenceOverlayRanges: Array<ContentOverlayRange<unknown>> | null | undefined) => {
    if (!sentenceOverlayRanges || sentenceOverlayRanges.length === 0) {
      return null;
    }

    const sentenceStart = sentenceOverlayRanges[0];
    const sentenceEnd = sentenceOverlayRanges[sentenceOverlayRanges.length - 1];

    const sentenceBoundary = {
      start: {
        ref: sentenceStart.ref,
        index: sentenceStart.startIndex
      },
      end: {
        ref: sentenceEnd.ref,
        index: sentenceEnd.endIndex
      }
    };

    return sentenceBoundary;
  };

  type SentenceBoundary = ReturnType<typeof getSentenceBoundary>;

  const areEqual = (first: SentenceBoundary, second: SentenceBoundary) =>
    first?.start.index === second?.start.index &&
    first?.end.index === second?.end.index &&
    first?.start.ref === second?.start.ref &&
    first?.end.ref === second?.end.ref;

  let isManualScroll = false;
  const resetManualScroll = debounce(() => {
    isManualScroll = false;
  }, 300);

  const onManualScroll = (shouldResetFlagAfterwards: boolean = true) => {
    isManualScroll = true;
    if (playbackInfo.latestState.isPlaying) {
      disableAutoScroll(targetAutoScrollStore);
    }
    if (shouldResetFlagAfterwards) {
      resetManualScroll();
    }
  };

  const onWheel = () => onManualScroll();

  let latestTouchEvent: TouchEvent | null = null;

  const onTouchStart = (event: TouchEvent) => {
    latestTouchEvent = event;
  };

  const onTouchMove = debounce((event: TouchEvent) => {
    if (!latestTouchEvent) {
      return;
    }

    const touchEnd = event.changedTouches[0];
    const touchStart = latestTouchEvent.changedTouches[0];
    const deltaY = touchEnd.clientY - touchStart.clientY;

    if (Math.abs(deltaY) >= 5) {
      onManualScroll();
    }

    // Update latestTouchEvent for continuous scrolling
    latestTouchEvent = event;
  }, 100);

  const onTouchEnd = () => {
    latestTouchEvent = null;
  };

  const onMouseDown = (e: MouseEvent) => {
    const isOverScrollbar = isMouseOverScrollbar(overlayInfo.scroller, e);

    if (isOverScrollbar) {
      onManualScroll(false);
    }
  };

  const onMouseUp = () => resetManualScroll();

  const onKeyDown = (event: KeyboardEvent) => {
    if (
      event.key === 'ArrowUp' ||
      event.key === 'ArrowDown' ||
      event.key === 'PageUp' ||
      event.key === 'PageDown' ||
      event.key === 'Home' ||
      event.key === 'End'
    ) {
      onManualScroll();
    }
  };

  const smoothScrollBy = (change: number) => {
    const state = targetAutoScrollStore.getState();

    if (state.state !== 'initialized') {
      console.error('Cannot smooth scroll when not initialized');
      return;
    }

    if (!state.temporaryEnabled) {
      return;
    }

    const overlayInfo = state.overlayInfo;

    const offsetTop = overlayInfo instanceof EmbeddedOverlayInfo ? overlayInfo.overlayElement.offsetTop : 0;

    if (offsetTop) {
      overlayInfo.scroller.scroll({ top: overlayInfo.scroller.scrollTop + offsetTop + change, left: 0, behavior: 'instant' });
      return;
    }

    const initialScrollTop = overlayInfo.scroller.scrollTop;
    const duration = 200;

    let start: number;
    let cTop = initialScrollTop;

    const animationFunction = (timestamp: number) => {
      if (!start) {
        start = timestamp;
      }

      const elapsedTime = timestamp - start;

      const partialScrollTarget = easing({
        elapsedTime,
        initialScrollTop,
        change,
        duration
      });

      if (elapsedTime <= duration) {
        const onGoingScrollAnimation = requestAnimationFrame(animationFunction);
        targetAutoScrollStore.setState(prevState => ({ ...prevState, onGoingScrollAnimation }));
      }

      // TODO(overhaul): Add disable auto scroll logic here.
      // If auto scroll setting is disabled, stop autoscroll.
      // if (!autoScrollState.current || isManualScrollRef.current) {
      // return;
      // }

      const state = targetAutoScrollStore.getState();

      if (!state.temporaryEnabled || isManualScroll) {
        return;
      }

      const diff = partialScrollTarget - cTop;

      if ((change > 0 && diff <= 0) || (change < 0 && diff >= 0)) {
        return;
      }

      cTop = partialScrollTarget;

      overlayInfo.scroller.scroll({ top: partialScrollTarget, left: 0, behavior: 'instant' });
    };

    const onGoingScrollAnimation = requestAnimationFrame(animationFunction);
    targetAutoScrollStore.setState(prevState => ({ ...prevState, onGoingScrollAnimation }));
  };

  const scrollToActiveSentence = async () => {
    const state = targetAutoScrollStore.getState();

    if (state.state !== 'initialized') {
      return;
    }

    if (!state.temporaryEnabled) {
      return;
    }

    if (!state.activeSentenceOverlayRanges) {
      return;
    }

    const autoScrollTop = await overlayInfo.getActiveSentenceTopPositionForAutoScroll(state.activeSentenceOverlayRanges);

    if (!autoScrollTop) return;

    const anchor = getAutoScrollAnchor();
    const bringToAnchor = autoScrollTop - anchor;
    const currentTop = overlayInfo.scroller.scrollTop;
    const change = bringToAnchor - currentTop;

    // embedded, only scroll if sentence is outside of the viewport
    if (overlayInfo instanceof EmbeddedOverlayInfo) {
      if (overlayInfo.isActiveSentenceInView()) {
        return;
      }
    }

    smoothScrollBy(change);
  };

  const removeOverlayHelperListener = overlayInfo.overlayHelper.addEventListener(async event => {
    const state = targetAutoScrollStore.getState();
    if (state.state !== 'initialized') {
      return;
    }

    if (event.sentenceOverlayRanges.length === 0) {
      return;
    }

    const sentenceBoundary = getSentenceBoundary(event.sentenceOverlayRanges);
    if (!sentenceBoundary) {
      return;
    }

    const activeSentenceOverlayRanges = state.activeSentenceOverlayRanges;

    const currentSentenceBoundary = getSentenceBoundary(activeSentenceOverlayRanges);
    if (areEqual(sentenceBoundary, currentSentenceBoundary)) {
      return;
    }

    targetAutoScrollStore.setState(prevState => ({ ...prevState, activeSentenceOverlayRanges: event.sentenceOverlayRanges }));

    await scrollToActiveSentence();
  });

  const scroller = overlayInfo.scroller;
  const removeOnWheel = addEventListener(scroller, 'wheel', onWheel);
  const removeOnTouchStart = addEventListener(scroller, 'touchstart', onTouchStart as EventListener);
  const removeOnTouchMove = addEventListener(scroller, 'touchmove', onTouchMove as unknown as EventListener);
  const removeOnTouchEnd = addEventListener(scroller, 'touchend', onTouchEnd);
  const removeOnTouchCancel = addEventListener(scroller, 'touchcancel', onTouchEnd);

  // TODO(albertusdev): Revisit mousedown here as it's not working when user do scroll via scroll bar,
  // must be something on PSPDFKIt scrollbar setting...

  const removeOnMouseDown = addEventListener(scroller, 'mousedown', onMouseDown as EventListener);
  const removeOnMouseUp = addEventListener(scroller, 'mouseup', onMouseUp);
  const removeOnKeyDown = addEventListener(window, 'keydown', onKeyDown as EventListener);

  const onClickOnNavigationElements = (event: MouseEvent) => {
    // Step 2: Check if the clicked element or any of its parents have the attribute
    let targetElement: HTMLElement | null = event.target as HTMLElement;

    while (targetElement && !targetElement.hasAttribute('data-should-disable-autoscroll-temporarily')) {
      targetElement = targetElement.parentElement;
    }

    // If the attribute is found, disable auto-scroll temporarily
    if (targetElement && targetElement.hasAttribute('data-should-disable-autoscroll-temporarily')) {
      targetAutoScrollStore.setState(prevState => ({ ...prevState, temporaryEnabled: false }));
    }
  };
  const removeOnClick = addEventListener(window, 'click', onClickOnNavigationElements as EventListener);

  const cleanUpFunction = () => {
    removeOverlayHelperListener();
    removeOnWheel();
    removeOnTouchStart();
    removeOnTouchMove();
    removeOnTouchEnd();
    removeOnTouchCancel();
    removeOnMouseDown();
    removeOnMouseUp();
    removeOnKeyDown();
    removeOnClick();
  };

  targetAutoScrollStore.setState(prevState => ({
    ...prevState,
    cleanUpFunction,
    forceScrollToActiveSentence: scrollToActiveSentence,
    overlayInfo,
    temporaryEnabled: false,
    state: 'initialized'
  }));

  return cleanUpFunction;
};

const enableAutoScroll = (targetAutoScrollStore: ReturnType<typeof createAutoScrollStore> = useAutoScrollStore) => {
  targetAutoScrollStore.setState(prevState => ({ ...prevState, temporaryEnabled: true }));
  const state = targetAutoScrollStore.getState();
  if (state.state === 'initialized') {
    state.forceScrollToActiveSentence();
  }
};

const disableAutoScroll = (targetAutoScrollStore: ReturnType<typeof createAutoScrollStore> = useAutoScrollStore) => {
  const state = targetAutoScrollStore.getState();

  if (state.state !== 'initialized') {
    return;
  }

  if (state.onGoingScrollAnimation) {
    cancelAnimationFrame(state.onGoingScrollAnimation);
  }

  targetAutoScrollStore.setState(prevState => ({ ...prevState, temporaryEnabled: false, onGoingScrollAnimation: undefined }));
};

export const autoScrollStoreActions = {
  initializeAutoScroll,
  enableAutoScroll,
  disableAutoScroll
};
