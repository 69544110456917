import type { VoiceInfo } from 'modules/sdk/lib';
import { subscriptionStoreSelectors } from 'modules/subscription/stores/selectors';
import { VoiceLockedReasonEnum, WithVoiceState } from 'modules/voices/types';

import { getLatestPlayingVoice } from './getLatestPlayingVoice';

export const getVoiceState = ({
  voiceInfo,
  latestPlayingVoice,
  isPremiumUser,
  hasHdWords
}: {
  voiceInfo: VoiceInfo;
  latestPlayingVoice?: VoiceInfo;
  isPremiumUser?: boolean;
  hasHdWords?: boolean;
}): WithVoiceState => {
  latestPlayingVoice ??= getLatestPlayingVoice();
  hasHdWords ??= subscriptionStoreSelectors.getHasHdWords();
  isPremiumUser ??= subscriptionStoreSelectors.getIsPremium();

  if (voiceInfo.isPremium && !hasHdWords) {
    return {
      state: 'locked',
      lockedReason: isPremiumUser ? VoiceLockedReasonEnum.RunOutOfHdWords : VoiceLockedReasonEnum.NotSubscribed
    };
  }
  if (voiceInfo.id === latestPlayingVoice?.id) {
    return { state: 'active' };
  }
  return { state: 'default' };
};
