export const browserNameAndVersion = (delimiter = ''): string => {
  const agent = navigator.userAgent.toLowerCase();
  const regStr_ie = /msie [\d.]+/gi;
  const regStr_ff = /firefox\/[\d.]+/gi;
  const regStr_chrome = /chrome\/[\d.]+/gi;
  const regStr_saf = /safari\/[\d.]+/gi;
  const regStr_opr = /opr\/[\d.]+/gi;
  const regStr_edg = /edg\/[\d.]+/gi;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let browserNV: any;

  // ie
  if (agent.indexOf('msie') > 0) {
    browserNV = agent.match(regStr_ie);
  }

  // firefox
  if (agent.indexOf('firefox') > 0) {
    browserNV = agent.match(regStr_ff);
  }

  // chrome
  // @ts-expect-error TS2339: Property 'chrome' does not exist on type 'Window & typeof globalThis'.
  if (agent.indexOf('chrome') > 0 && Boolean(globalThis.chrome)) {
    if (agent.indexOf('opr/') > 0) {
      // opera
      browserNV = agent.match(regStr_opr);
    } else if (agent.indexOf('edg/') > 0) {
      // edge
      browserNV = agent.match(regStr_edg);
    } else {
      browserNV = agent.match(regStr_chrome);
    }
  }

  // safari
  if (agent.indexOf('safari') > 0 && agent.indexOf('chrome') < 0) {
    browserNV = agent.match(regStr_saf);
  }

  browserNV = browserNV.toString();

  // other
  if ('' == browserNV) {
    browserNV = 'Unknown';
  }

  if (browserNV.indexOf('firefox') != -1 || agent.indexOf('chrome') != -1) {
    browserNV = browserNV.replace('/', delimiter);
  }

  if (browserNV.indexOf('msie') != -1) {
    browserNV = browserNV.replace('msie', 'ie').replace(/\s/g, delimiter);
  }

  return browserNV;
};

export enum BrowserNameEnum {
  Arc = 'Arc',
  Brave = 'Brave',
  Chrome = 'Chrome',
  Firefox = 'Firefox',
  MicrosoftEdge = 'Microsoft Edge',
  Opera = 'Opera',
  Other = 'Other'
}

export const getBrowserName = async () => {
  const { userAgent, brave } = navigator as Navigator & {
    brave?: { isBrave: () => Promise<boolean> };
  };
  const agent = userAgent.toLowerCase();

  const isChrome = /chrome|crios/.test(agent) && !/edge|edg\/|opr\//.test(agent);
  const isBrave = isChrome && brave?.isBrave && (await brave.isBrave());

  if (isBrave) return BrowserNameEnum.Brave;
  // @ts-expect-error TS(7017): Element implicitly has an 'any' type because type ... Remove this comment to see the full error message
  if (isChrome && Boolean(globalThis.chrome)) return BrowserNameEnum.Chrome;
  if (agent.includes('edg/')) return BrowserNameEnum.MicrosoftEdge;
  // @ts-expect-error TS(7017): Element implicitly has an 'any' type because type ... Remove this comment to see the full error message
  if (agent.includes('opr/') && Boolean(globalThis.opr)) return BrowserNameEnum.Opera;
  if (agent.indexOf('firefox')) return BrowserNameEnum.Firefox;
  if (getComputedStyle(document.documentElement).getPropertyValue('--arc-palette-title')) return BrowserNameEnum.Arc;

  return BrowserNameEnum.Other;
};

export const isOnMicrosoftEdgeBrowser = (): boolean => {
  if (typeof navigator === 'undefined') return false;

  const { userAgent } = navigator as Navigator;
  const agent = userAgent.toLowerCase();

  if (agent.includes('edg/')) return true;
  return false;
};

export const isWindows = (): boolean => {
  return navigator.platform.startsWith('Win') || navigator.userAgent.includes('Windows');
};
