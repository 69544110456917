export type PromiseWithCompletionController<T> = {
  promise: Promise<T>;
  completionController: PromiseCompletionController<T>;
};

export type PromiseCompletionController<T> = {
  resolve(v: T): void;
  reject(reason: Error | undefined): void;
  ensureResolved(v: T): void;
  ensureRejected(reason: Error | undefined): void;
};

export const PromiseWithCompletionControllerUtil = {
  create<T>(): PromiseWithCompletionController<T> {
    let completionController: PromiseCompletionController<T>;

    const promise = new Promise<T>(function (resolve, reject) {
      let isComplete = false;

      function throwIfComplete() {
        if (isComplete === true) throw new Error('Tried to pass a new result/rejection when Promise is already complete!');
      }

      completionController = {
        resolve: (v: T) => {
          throwIfComplete();
          isComplete = true;
          resolve(v);
        },
        reject: (reason: Error | undefined) => {
          throwIfComplete();
          isComplete = true;
          reject(reason);
        },
        ensureResolved: (v: T) => {
          isComplete = true;
          resolve(v);
        },
        ensureRejected: (reason: Error | undefined) => {
          isComplete = true;
          reject(reason);
        }
      };
    });

    return {
      promise: promise,
      completionController: completionController!
    };
  },

  resolve<T>(val: T): PromiseWithCompletionController<T> {
    const result = PromiseWithCompletionControllerUtil.create<T>();
    result.completionController.resolve(val);
    return result;
  },

  reject<T>(reason: Error | undefined): PromiseWithCompletionController<T> {
    const result = PromiseWithCompletionControllerUtil.create<T>();
    result.completionController.reject(reason);
    return result;
  }
};
