import type { VoiceInfo } from 'modules/sdk/lib';
import { useSpeedStore } from 'modules/speed/store/speedStore';
import { MAXIMUM_FREE_WPM } from 'modules/speed/utils/constants';
import { useVoiceStore } from 'modules/voices/hooks';

import { useSubscriptionStore } from '../subscriptionStore';
import { getHasHdWords } from './getHasHdWords';
import { getIsPremium } from './getIsPremium';

export const getShouldSwitchToFree = ({
  speed,
  voice
}: {
  speed?: number;
  voice?: VoiceInfo;
} = {}): boolean => {
  speed ??= useSpeedStore.getState().currentSpeedInWPM;
  voice ??= useVoiceStore.getState().lastPlayingVoice;
  const subscriptionLoading = useSubscriptionStore.getState().isLoading;

  if (subscriptionLoading) return false;

  // No need to switch to free if user is not using premium voice or speed
  if (speed <= MAXIMUM_FREE_WPM && (!voice || !voice.isPremium)) {
    return false;
  }

  const isPremiumUser = getIsPremium();

  if (speed > MAXIMUM_FREE_WPM) {
    // If user is using premium speed, switch to free if user is not premium
    return !isPremiumUser;
  }

  const hasHdWords = getHasHdWords();
  if (voice?.isPremium) {
    // If user is using premium voice, switch to free if user does not have HD words
    return !hasHdWords;
  }

  return false;
};
