export enum UserUsageDataPlatform {
  ANDROID = 'android',
  IOS = 'ios',
  WEB_APP = 'webApp',
  CHROME_EXTENSION = 'chromeExtension',
  SAFARI_EXTENSION = 'safariExtension',
  MAC_APP = 'macApp'
}

export interface IUserUsageDataEntry {
  lastVersion: string;
  lastVisitISODate: string;
}

export type UserUsageData = Record<UserUsageDataPlatform, IUserUsageDataEntry | null>;
