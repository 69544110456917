import { useMemo } from 'react';

import { usePlaybackStore } from 'modules/listening/stores/playback/playbackStore';
import type { VoiceInfo } from 'modules/sdk/lib';
import { subscriptionStoreSelectors } from 'modules/subscription/stores/selectors';
import { useSubscriptionStore } from 'modules/subscription/stores/subscriptionStore';

import { VoiceStoreState, useVoiceStore } from '../stores/voicesStore';
import { VoiceItemUIProp } from '../types';
import { mapVoiceInfoToVoiceItemUI } from '../utils/mapVoiceInfoToVoiceItemUI';

export const useVoiceUI = <T extends Array<VoiceInfo>>(selector: (state: VoiceStoreState) => T): VoiceItemUIProp[] => {
  const voices = useVoiceStore(selector);
  const playbackInfo = usePlaybackStore(s => s.currentPlaybackInfo);
  const latestPlayingVoice = useVoiceStore(s => s.lastPlayingVoice);
  const isPreviewPlaying = useVoiceStore(s => s.isPreviewPlaying);
  const isPremiumUser = useSubscriptionStore(subscriptionStoreSelectors.getIsPremium);
  const hasHdWords = useSubscriptionStore(subscriptionStoreSelectors.getHasHdWords);

  return useMemo(() => {
    return voices.map(v => mapVoiceInfoToVoiceItemUI({ voiceInfo: v, playbackInfo, latestPlayingVoice, hasHdWords, isPremiumUser, isPreviewPlaying }));
  }, [voices, playbackInfo, isPremiumUser, hasHdWords, latestPlayingVoice, isPreviewPlaying]);
};
