import * as React from 'react';

function DeleteIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={21} height={20} viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.5 3V1.667a1 1 0 10-2 0V3H4a1 1 0 100 2h.553l.559 10.164A3 3 0 008.107 18h4.786a3 3 0 002.995-2.836L16.447 5H17a1 1 0 100-2h-5.5zm-2 2H6.556l.553 10.055a1 1 0 00.998.945h4.786a1 1 0 00.998-.945L14.444 5H9.5z"
        fill="currentColor"
      />
    </svg>
  );
}

export default DeleteIcon;
