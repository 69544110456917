import { ErrorSource } from 'config/constants/errors';
import { logError } from 'lib/observability';
import { SDKVoiceFacade, VoiceInfo } from 'modules/sdk/lib';

import { useVoiceStore } from '../voicesStore';
import { setVoice } from './setVoice';

export const deletePersonalVoice = async (voiceInfo: VoiceInfo) => {
  try {
    const state = useVoiceStore.getState();
    const history = state.selectionHistory;

    const isPersonalVoiceLastPlaying = state.lastPlayingVoice?.id === voiceInfo.id;

    if (isPersonalVoiceLastPlaying) {
      const fallbackVoice = history.find(voice => voice.id !== voiceInfo.id) ?? SDKVoiceFacade.singleton.getDefaultVoiceInfo();
      setVoice(fallbackVoice);
    }

    const isPersonalVoiceInHistory = history.some(voice => voice.id === voiceInfo.id);
    const newState = {
      ...useVoiceStore.getState(),
      personalVoices: state.personalVoices.filter(voice => voice.id !== voiceInfo.id)
    };

    if (isPersonalVoiceInHistory) {
      newState.selectionHistory = history.filter(voice => voice.id !== voiceInfo.id);
    }

    newState.allVoices = newState.allVoices.filter(voice => voice.id !== voiceInfo.id);

    useVoiceStore.setState(newState);

    await SDKVoiceFacade.singleton.deletePersonalVoice(voiceInfo);
  } catch (e) {
    logError(new Error(`Error deletePersonalVoice: ${e}`), ErrorSource.PERSONAL_VOICES, {
      context: {
        operation: 'delete_personal_voice',
        voiceId: voiceInfo.id
      }
    });
  }
};
