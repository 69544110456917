import { useMemo } from 'react';

import { deleteVoiceFromHistory } from '../stores/actions/deleteVoiceFromHistory';
import { setVoice } from '../stores/actions/setVoice';
import { useVoiceUI } from './useVoiceUI';

export const useRecentVoices = () => {
  const voiceList = useVoiceUI(s => {
    const history = s.selectionHistory;
    const excludingCurrentVoice = s.lastPlayingVoice ? history.filter(v => v.id !== s.lastPlayingVoice?.id) : history;
    return excludingCurrentVoice;
  });

  return useMemo(() => {
    return (voiceList ?? []).map(v => ({
      ...v,
      state: v.state === 'active' ? 'default' : v.state,
      onClick: () => {
        setVoice(v, { shouldPlayPreview: false });
      },
      onDelete:
        // do not allow deletion of the current voice
        voiceList.length === 1
          ? undefined
          : () => {
              deleteVoiceFromHistory(v);
            }
    }));
  }, [voiceList]);
};
