import { ListeningScreenTabs } from 'modules/listening/navStore';
import { usePlaybackStore } from 'modules/listening/stores/playback/playbackStore';
import { subscriptionStoreSelectors } from 'modules/subscription/stores/selectors';

import { AnalyticsEventKey, logAnalyticsEvent } from '../logAnalyticsEvent';

export const logListeningScreenTabNavigationAnalyticsEvent = (activeTab: ListeningScreenTabs | null, prevActiveTab: ListeningScreenTabs | null) => {
  if (activeTab === 'settings') {
    logAnalyticsEvent(AnalyticsEventKey.openedDocumentSettings, {
      state: subscriptionStoreSelectors.getIsPremium() ? 'unlocked' : 'locked'
    });
  } else if (activeTab === 'ask-ai' || prevActiveTab === 'ask-ai') {
    const currentListenableContent = usePlaybackStore.getState().currentListenableContent;
    logAnalyticsEvent(
      AnalyticsEventKey.documentSummaryClicked,
      currentListenableContent!.wordCount
        ? {
            state: activeTab ? 'expanded' : 'collapse',
            fileType: currentListenableContent!.metaType,
            fileWordCount: currentListenableContent!.wordCount
          }
        : {
            state: activeTab ? 'expanded' : 'collapse',
            fileType: currentListenableContent!.metaType
          }
    );
  }

  logAnalyticsEvent(AnalyticsEventKey.topNavItemClicked, {
    tab: activeTab
  });
};
