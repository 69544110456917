// https://github.com/SpeechifyInc/multiplatform-sdk/blob/d588860ed005e25660b1cfd2b7da7407ee1ba1cf/multiplatform-sdk/src/commonMain/kotlin/com/speechify/client/api/services/library/models/LibraryItem.kt#L78
// INFO: if the item is opened and listened just a few words, the status will not be updated.

import { toUpper } from 'lodash';

// Because the listened words count is under the threshold to send the word-decrease to the backend.
export const listenedItemFilterFn = (item: $TSFixMe) => item.listenProgressStatus && item.listenProgressStatus !== 'NOT_STARTED';

export const recentlyListenedSortFn = (a: $TSFixMe, b: $TSFixMe) => {
  if (!a.updatedAt?._seconds || !b.updatedAt?._seconds || a.updatedAt._seconds > b.updatedAt._seconds) return -1;

  return 1;
};

export const sortItemFn = (selectedSort: string) => (a: $TSFixMe, b: $TSFixMe) => {
  if (selectedSort === 'alphabetical') {
    return toUpper(a.title) > toUpper(b.title) ? 1 : -1;
  } else if (selectedSort === 'opened') {
    return recentlyListenedSortFn(a, b);
  } else {
    // ESLint: Unexpected any & Unexpected any
    // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-explicit-any
    if ((a.createdAt as any)?._seconds && (b.createdAt as any)?._seconds) {
      // ESLint: Unexpected any & Unexpected any
      // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-explicit-any
      return (a.createdAt as any)._seconds > (b.createdAt as any)._seconds ? -1 : 1;
    } else {
      return -1;
    }
  }
};
