import * as React from 'react';

function OpenOriginalIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={21} height={20} viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_18776_30721)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.833 4H6.167a.667.667 0 00-.667.667v10.515c0 .321.216.56.472.608a24.66 24.66 0 004.528.422 1 1 0 110 2c-1.632 0-3.262-.152-4.894-.456-1.262-.235-2.106-1.35-2.106-2.574V4.667A2.667 2.667 0 016.167 2h8.666A2.667 2.667 0 0117.5 4.667V9a1 1 0 11-2 0V4.667A.667.667 0 0014.833 4zm.667 9v-1a1 1 0 00-1.707-.707l-2 2a1 1 0 000 1.414l2 2A1 1 0 0015.5 16v-1h4a1 1 0 100-2h-4zM7 7a1 1 0 011-1h5a1 1 0 110 2H8a1 1 0 01-1-1zm1 3a1 1 0 100 2h2a1 1 0 100-2H8z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_18776_30721">
          <path fill="#fff" transform="translate(.5)" d="M0 0H20V20H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default OpenOriginalIcon;
