export const LIFETIME_SUBSCRIPTION_PLAN_NAMES = [
  'speechify.lifetime.apple',
  'speechify.lifetime.paypal',
  'speechify.lifetime.play_store',
  'speechify.lifetime.stripe'
];

export const DOCUMENT_TITLE_MAX_LENGTH = 500;

export const DEFAULT_DOCUMENT_TITLE = "cliff's story — why i built speechify";
