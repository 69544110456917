import { subscriptionStoreSelectors } from 'modules/subscription/stores/selectors';

import { UpsellModalType } from '../types';
import { showUpsellModal } from './showUpsellModal';

export const showWordLimitModal = () => {
  if (subscriptionStoreSelectors.getHasHdWords()) {
    return;
  }
  const isPremium = subscriptionStoreSelectors.getIsPremium();
  showUpsellModal(isPremium ? UpsellModalType.TempWordLimit : UpsellModalType.FreeWordLimit);
};
