import React from 'react';
import { twMerge } from 'tailwind-merge';

export type NavIconProps = {
  active?: boolean;
  icon: JSX.Element;
};

const NavIcon = ({ active = false, icon, className, ...rest }: NavIconProps & JSX.IntrinsicElements['button']) => {
  return (
    <button
      className={twMerge(
        'text-text-primary rounded-150 focus-visible:outline-2 focus-visible:outline focus-visible:outline-[#1D76ED]  hover:bg-surface-primary-hovered active:bg-surface-primary-pressed p-spl-2 ',
        active && 'bg-surface-accent  text-icon-accent hover:bg-surface-accent',
        className
      )}
      {...rest}
    >
      {icon}
    </button>
  );
};

export default NavIcon;
