import * as React from 'react';

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={20} height={18} viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.09 2.09A.833.833 0 0016.91.91l-1.912 1.913a9.952 9.952 0 00-4.997-1.336c-4.66 0-8.574 3.186-9.684 7.498A10.002 10.002 0 003.63 14.19l-1.72 1.72a.833.833 0 101.18 1.178l1.931-1.932a9.953 9.953 0 004.98 1.326c4.658 0 8.572-3.186 9.682-7.498a10.001 10.001 0 00-3.296-5.193l1.702-1.703zm-4.321 1.962A8.336 8.336 0 002.05 8.984a8.34 8.34 0 002.765 4.022l8.954-8.954zM6.25 13.927l8.951-8.95a8.341 8.341 0 012.749 4.008 8.336 8.336 0 01-11.7 4.942z"
        className="fill-icon-secondary"
      />
    </svg>
  );
}

export default SvgComponent;
