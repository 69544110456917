import { AnyAction } from '@reduxjs/toolkit';

export const isThisFulfilledAction = (name: string) => (action: AnyAction) => isThisAction(name)(action) && isFulfilledAction(action);
export const isThisPendingAction = (name: string) => (action: AnyAction) => isThisAction(name)(action) && isPendingAction(action);
export const isThisRejectedAction = (name: string) => (action: AnyAction) => isThisAction(name)(action) && isRejectedAction(action);

const isFulfilledAction = (action: AnyAction) => action.type.endsWith('/fulfilled') && !action.type.includes('/subscribe/');
const isPendingAction = (action: AnyAction) => action.type.endsWith('/pending');
const isRejectedAction = (action: AnyAction) => action.type.endsWith('/rejected');
const isThisAction = (name: string) => (action: AnyAction) => action.type.startsWith(name);

// ESLint: Unexpected any
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const setValue = (name: string) => (state: any, action: AnyAction) => {
  state[name] = action.payload;
};

export const setValues = (state: $TSFixMe, action: AnyAction) => {
  Object.entries(action.payload).forEach(([name, value]) => {
    state[name] = value;
  });
};
