import { useSubscriptionStore } from 'modules/subscription/stores/subscriptionStore';
import { getLocalPreference } from 'utils/preferences';

import { SideBannerType, sideBannerActions } from './sideBannerStore';

const tryQueueMobileUpsellBanner = () => {
  const bannerAlreadyShown = getLocalPreference('shownQrCodeV2') == 'true';
  const isMobileAppInstalled = useSubscriptionStore.getState().mobileAppInstalled;

  if (!bannerAlreadyShown && !isMobileAppInstalled) {
    sideBannerActions.add({ type: SideBannerType.UPSELL });
  }
};

export { tryQueueMobileUpsellBanner };
