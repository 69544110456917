import { AnyAction, Dispatch } from '@reduxjs/toolkit';

import { isAnonymous as isUserAnonymous } from 'lib/speechify';
import { actions as authActions } from 'store/auth';
import { actions as gamificationActions } from 'store/gamification';
import { disableGamification, enableGamification } from 'utils/extension';

import { getGamificationFeatureFlag } from './helpers';

const turnOffGamification = (dispatch: $TSFixMe) => {
  dispatch(gamificationActions.clear());
  dispatch(gamificationActions.unsubscribe());
  disableGamification();
};

const gamificationMiddleware =
  ({ dispatch, getState }: $TSFixMe) =>
  (next: Dispatch<AnyAction>) =>
  async (action: $TSFixMe) => {
    if (action && action.type) {
      if (action.type.startsWith(authActions.setUser.typePrefix)) {
        const user = action.payload || getState().auth?.user;
        if (user && !isUserAnonymous(user)) {
          getGamificationFeatureFlag(user).then(value => {
            if (value === 'enabled') {
              dispatch(gamificationActions.subscribe(user.uid));
              enableGamification();
            } else {
              turnOffGamification(dispatch);
            }
          });
        } else {
          turnOffGamification(dispatch);
        }
      } else if (action.type === authActions.logout.pending.type) {
        turnOffGamification(dispatch);
      }
    }
    next(action);
  };

export default gamificationMiddleware;
