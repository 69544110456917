export type EventEmitter<Events extends Record<string, object>> = {
  // ESLint: Unexpected any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  on: <T extends keyof Events>(event: T, listener: (event: Events[T]) => any) => () => void;
  // ESLint: Unexpected any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  off: <T extends keyof Events>(event: T, listener: (event: Events[T]) => any) => void;
  emit: <T extends keyof Events>(event: T, eventObj: Events[T]) => Promise<void>;
  // ESLint: Unexpected any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  once: <T extends keyof Events>(event: T, listener: (event: Events[T]) => any) => () => void;
  // ESLint: Unexpected any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  addEventListener: <T extends keyof Events>(event: T, listener: (event: Events[T]) => any) => () => void;
  // ESLint: Unexpected any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  removeEventListener: <T extends keyof Events>(event: T, listener: (event: Events[T]) => any) => void;
};

export function createEventEmitter<Events extends Record<string, object>>({ events: validEvents }: { events: (keyof Events)[] }): EventEmitter<Events> {
  // ESLint: Unexpected any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  type EventObj = { [T in keyof Events]: ((event: Events[T]) => any)[] };

  // Type safe even with cast because internal map covers typing
  const events: EventObj = Object.fromEntries(validEvents.map<[keyof Events, []]>(event => [event, []])) as unknown as EventObj;

  // ESLint: Unexpected any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function on<T extends keyof Events>(event: T, listener: (event: Events[T]) => any) {
    if (!validEvents.includes(event)) throw new Error(`Invalid event "${String(event)}" provided`);
    if (!Array.isArray(events[event])) events[event] = [];

    events[event]?.push(listener);

    return () => off(event, listener);
  }

  // ESLint: Unexpected any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function off<T extends keyof Events>(event: T, listener: (event: Events[T]) => any) {
    if (!Array.isArray(events[event])) return;

    const listenerIndex = events[event].indexOf(listener);

    if (listenerIndex > -1) events[event].splice(listenerIndex, 1);
  }

  async function emit<T extends keyof Events>(event: T, eventObj: Events[T]) {
    if (!Array.isArray(events[event])) return;

    const listeners = events[event];
    for (const listener of listeners) {
      await Promise.resolve(listener(eventObj)).catch(console.error);
    }
  }

  // ESLint: Unexpected any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function once<T extends keyof Events>(event: T, listener: (event: Events[T]) => any) {
    return on(event, function listenOnce(eventObj) {
      off(event, listenOnce);
      listener(eventObj);
    });
  }

  return { on, off, emit, once, addEventListener: on, removeEventListener: off };
}
