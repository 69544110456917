import assert from 'assert';

import { AnalyticsEventKey, logAnalyticsEvent } from 'modules/analytics/logAnalyticsEvent';
import { usePlaybackStore } from 'modules/listening/stores/playback/playbackStore';
import { PDFOverlayInfo } from 'modules/sdk/lib';

import { useListeningSettingsStore } from '../settingsStore';

export const MIN_ZOOM_PERCENTAGE = 20;
export const MAX_ZOOM_PERCENTAGE = 1000;
export const ZOOM_STEP = 10;

export const increaseZoom = () => {
  const currentZoom = useListeningSettingsStore.getState().zoomPercentage;
  onZoomChange(currentZoom + ZOOM_STEP, {
    minZoom: MIN_ZOOM_PERCENTAGE,
    maxZoom: MAX_ZOOM_PERCENTAGE
  });
};

export const decreaseZoom = () => {
  const currentZoom = useListeningSettingsStore.getState().zoomPercentage;
  onZoomChange(currentZoom - ZOOM_STEP, {
    minZoom: MIN_ZOOM_PERCENTAGE,
    maxZoom: MAX_ZOOM_PERCENTAGE
  });
};

export const onZoomChange = (
  zoomPercentage: number,
  {
    minZoom = MIN_ZOOM_PERCENTAGE,
    maxZoom = MAX_ZOOM_PERCENTAGE
  }: {
    minZoom?: number;
    maxZoom?: number;
  } = {
    minZoom: MIN_ZOOM_PERCENTAGE,
    maxZoom: MAX_ZOOM_PERCENTAGE
  }
) => {
  zoomPercentage = Math.min(maxZoom, Math.max(minZoom, zoomPercentage));
  zoomPercentage = Math.ceil(Math.round(zoomPercentage));
  useListeningSettingsStore.setState(state => ({ ...state, zoomPercentage }));

  const overlayInfo = usePlaybackStore.getState().currentOverlayInfo;
  assert(overlayInfo, 'overlayInfo is not defined');

  if (overlayInfo instanceof PDFOverlayInfo) {
    const currentPspdfKitFacade = usePlaybackStore.getState().currentPspdfKitFacade;
    assert(currentPspdfKitFacade !== undefined, 'PspdfKitFacade was not initialized');
    currentPspdfKitFacade.setZoom(zoomPercentage);
  }

  overlayInfo.emitZoomEvent(zoomPercentage);

  logAnalyticsEvent(AnalyticsEventKey.listeningSettingsZoomChanged, {
    zoomPercentage
  });
};

export const syncExternalPSPDFKitZoomEvent = (zoomScale: number) => {
  const zoomPercentage = Math.ceil(Math.round(zoomScale * 100));
  useListeningSettingsStore.setState(state => ({ ...state, zoomPercentage }));
  const overlayInfo = usePlaybackStore.getState().currentOverlayInfo;
  overlayInfo?.emitZoomEvent(zoomPercentage);
};
