import { deletePersonalVoice } from './deletePersonalVoice';
import { fetchInitialVoice } from './fetchInitialVoice';
import { initializeVoiceStore } from './initializeVoiceStore';
import { refreshPersonalVoices } from './refreshPersonalVoices';
import { setVoice } from './setVoice';
import { switchToFreeVoice } from './switchToFreeVoice';

export const voiceStoreActions = {
  deletePersonalVoice,
  fetchInitialVoice,
  initializeVoiceStore,
  setVoice,
  switchToFreeVoice,
  refreshPersonalVoices
};
