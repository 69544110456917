import { throttle } from 'lodash';

import { createNonPersistentStore } from 'lib/zustand';
import { DESKTOP_BREAKPOINT } from 'modules/listening/hooks/useIsDesktop';
import { SentenceMark } from 'modules/sdk/lib';
import { ListeningDependencies } from 'modules/sdk/listeningDependencies';

type BaseFields = {
  enabled: boolean;
  cleanUpFunction?: () => void;
};

type NotInitializedState = BaseFields & {
  state: 'not_initialized';
  hoveredSentenceMark: null;
};

type InitializedState = BaseFields & {
  state: 'initialized';
  cleanUpFunction: () => void;
  hoveredSentenceMark: SentenceMark | null;
};

type State = NotInitializedState | InitializedState;

export function sentenceMarkKey(sentenceMark: SentenceMark) {
  return sentenceMark.rects.map(rect => `${rect.left},${rect.top},${rect.width},${rect.height}`).join('|');
}

export const useClickToListenStore = createNonPersistentStore<State>(
  () => ({
    state: 'not_initialized',
    enabled: false,
    hoveredSentenceMark: null
  }),
  {
    isListeningScreenStore: true
  }
);

export const initializeClickToListen = async (listeningDependencies: ListeningDependencies) => {
  const { overlayInfo } = listeningDependencies;

  const isEnabled = () => useClickToListenStore.getState().enabled;

  const handler = throttle(async (event: MouseEvent | TouchEvent) => {
    if (!isEnabled()) return;

    const isTouch = 'touches' in event;
    const { clientX, clientY } = isTouch ? event.touches[0] : event;

    const isDesktop = window.matchMedia(`(min-width: ${DESKTOP_BREAKPOINT}px)`).matches;
    if (!isDesktop) {
      const elements = document.elementsFromPoint(clientX, clientY);
      if (elements.find(element => element.id === 'speechify-web-player')) {
        // If the click is on the player, ignore click to listen handler
        return;
      }
    }

    const sentenceMark = await overlayInfo.getRelevantClickToListenSentenceMark(clientX, clientY);

    updateHoveredSentenceMark(sentenceMark);

    // On touch, we play the audio immediately
    if (sentenceMark && isTouch) {
      sentenceMark.playFromHere(event);
    }
  }, 50);

  document.addEventListener('mousemove', handler);
  document.addEventListener('touchstart', handler);

  const cleanUp = () => {
    document.removeEventListener('mousemove', handler);
    document.removeEventListener('touchstart', handler);
  };

  useClickToListenStore.setState({
    state: 'initialized',
    enabled: false,
    cleanUpFunction: cleanUp,
    hoveredSentenceMark: null
  });
};

const enableClickToListen = () => {
  useClickToListenStore.setState({ enabled: true });
};

const disableClickToListen = () => {
  useClickToListenStore.setState({ enabled: false, hoveredSentenceMark: null });
};

const updateHoveredSentenceMark = (hoveredSentenceMark: SentenceMark | null) => {
  if (!hoveredSentenceMark) {
    return;
  }

  const key = sentenceMarkKey(hoveredSentenceMark);
  const existingSentenceMark = useClickToListenStore.getState().hoveredSentenceMark;
  if (existingSentenceMark && sentenceMarkKey(existingSentenceMark) === key) {
    return;
  }

  useClickToListenStore.setState({ hoveredSentenceMark });
};

// TODO(albertusdev): use the `registerOnResetCleanUp` once https://github.com/SpeechifyInc/web/pull/2862 is merged
const cleanUpClickToListen = () => {
  const { cleanUpFunction } = useClickToListenStore.getState();
  if (cleanUpFunction) {
    cleanUpFunction();
  }
  useClickToListenStore.setState({ state: 'not_initialized' }, true);
};

export const clickToListenStoreActions = {
  initializeClickToListen,
  enableClickToListen,
  disableClickToListen,
  cleanUpClickToListen
};
