import { handleOnSearchIconClick } from '../features/search';
import { useExclusiveHotkeys } from './useExclusiveHotkeys';

// This hook is used to override and handle the search keyboard shortcut, because the default PSPDFKit search shortcut will trigger a distorted UI bug.
export function useSearchKeyboardShortcut() {
  useExclusiveHotkeys(
    [
      {
        ctrlKey: true,
        code: 'KeyF'
      },
      {
        metaKey: true,
        code: 'KeyF'
      }
    ],
    () => {
      handleOnSearchIconClick();
    }
  );
}
