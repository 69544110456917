import React, { useCallback, useEffect, useState } from 'react';

import { useAuthStore } from 'modules/auth/store/authStore';

import { UseListenableContentItemIdState } from './useListenableContentItemId';

export const useFileActionV2 = ({ itemIdState, menuRef }: { itemIdState: UseListenableContentItemIdState; menuRef: React.RefObject<HTMLElement> }) => {
  const [showFileAction, setShowFileAction] = useState(false);

  const isAnonymous = useAuthStore(state => state.user?.isAnonymous || false);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        setShowFileAction(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [menuRef]);

  const onActionClick = () => {
    setTimeout(() => {
      setShowFileAction(false);
    }, 100);
  };

  const onFileNameClick = useCallback(() => {
    if (itemIdState.isLoading) return;

    setShowFileAction(true);
  }, [itemIdState]);

  const onMenuLeave = useCallback(() => {
    if (itemIdState.isLoading) return;
    setShowFileAction(false);
  }, [itemIdState]);

  return {
    onActionClick,
    onFileNameClick,
    onMenuLeave,
    // Do not show file action if the user is anonymous
    showFileAction: showFileAction && !isAnonymous
  };
};
