import { createNonPersistentStore } from 'lib/zustand';
import { AnalyticsEventKey, logAnalyticsEvent } from 'modules/analytics/logAnalyticsEvent';
import { SummaryDialogState } from 'modules/listening/components/ChatAskAiSDKbased';
import { PlaybackInfo, getSDK } from 'modules/sdk/lib';
import { ChatMessage } from 'modules/sdk/lib/facade/askAi';

type AskAiState = {
  autoPlay: boolean;
  currentDocId: string | null;
  currentlyPlayingMessageId: string | null;
  currentlyPlayingMessagePlayButtonInView: boolean | null;
  isLoading: boolean;
  isSummarizing: boolean;
  messages: ChatMessage[];
  playbackInfo: PlaybackInfo | null;
  renderPlayButtonInHeader: boolean;
  userInteracted: boolean;
};

export const useAskAiStore = createNonPersistentStore<AskAiState>(
  () => {
    return {
      autoPlay: true,
      currentDocId: null,
      currentlyPlayingMessageId: null,
      currentlyPlayingMessagePlayButtonInView: null,
      isLoading: false,
      isSummarizing: false,
      messages: [],
      playbackInfo: null,
      renderPlayButtonInHeader: false,
      userInteracted: false
    };
  },
  {
    isListeningScreenStore: true
  }
);

const fetchAskAiChatsForCurrentDocument = async (itemId: string) => {
  const { askAi } = await getSDK();

  const { messages: existingMessages, currentDocId } = useAskAiStore.getState();

  if (currentDocId === itemId) return;

  try {
    if (existingMessages.length === 0) {
      useAskAiStore.setState({ isLoading: true });
    }

    await askAi.initializeChatForDocument(itemId);
    const messages = askAi.getTransformedMessages();

    askAi.setMessagesChangeListener(messages => {
      useAskAiStore.setState({ messages });
    });

    useAskAiStore.setState({ messages: messages.filter(Boolean) as ChatMessage[], currentDocId: itemId });
  } finally {
    useAskAiStore.setState({ isLoading: false });
  }
};

const sendPrompt = async (prompt: string) => {
  const { askAi } = await getSDK();

  setUserInteracted(true);
  logAnalyticsEvent(AnalyticsEventKey.documentAskAi, { action: 'chat', query: prompt });

  await askAi.sendMessage(prompt);
};

const summarizeCurrentPage = async (currentPageIndex: number) => {
  const { askAi } = await getSDK();

  logAnalyticsEvent(AnalyticsEventKey.documentAskAi, {
    action: 'generate_summary',
    options: { mode: 'paragraph', length: 'short', pageIndexes: [currentPageIndex + 1], allPages: false }
  });

  setIsSummarizing(true);
  await askAi.summarizePages([currentPageIndex + 1], 'short', 'paragraph');

  setIsSummarizing(false);
  setUserInteracted(true);
};

const summarizeDocument = async (length: SummaryDialogState['length'], mode: SummaryDialogState['mode']) => {
  const { askAi } = await getSDK();

  logAnalyticsEvent(AnalyticsEventKey.documentAskAi, {
    action: 'generate_summary',
    options: { mode, length, pageIndexes: [], allPages: true }
  });

  setIsSummarizing(true);
  await askAi.summarizeDocument(length, mode);

  setIsSummarizing(false);
  setUserInteracted(true);
};

const summarizePages = async (pageIndexes: number[], length: SummaryDialogState['length'], mode: SummaryDialogState['mode']) => {
  const { askAi } = await getSDK();

  logAnalyticsEvent(AnalyticsEventKey.documentAskAi, {
    action: 'generate_summary',
    options: { mode, length, pageIndexes, allPages: false }
  });

  setIsSummarizing(true);
  await askAi.summarizePages(
    pageIndexes.map(p => p + 1), // sdk expects pageNumbers from 1 base instead of 0
    length,
    mode
  );

  setIsSummarizing(false);
  setUserInteracted(true);
};

const regenerate = async () => {
  const { askAi } = await getSDK();

  logAnalyticsEvent(AnalyticsEventKey.documentAskAi, {
    action: 'summary_regenerate'
  });

  setIsSummarizing(true);
  await askAi.regenerateMessage();

  setIsSummarizing(false);
  setUserInteracted(true);
};

const deleteChat = async () => {
  const { askAi } = await getSDK();
  await askAi.deleteChat();
  useAskAiStore.reset();
  setUserInteracted(true);
};

const setAutoPlay = (autoPlay: boolean) => {
  logAnalyticsEvent(AnalyticsEventKey.documentAskAiAutoplayResponses, {
    state: autoPlay ? 'on' : 'off'
  });
  useAskAiStore.setState({ autoPlay: autoPlay });
};

const setPlaybackInfo = (playbackInfo: PlaybackInfo | null, messageId: string | null) => {
  // reset previous playback info
  const state = useAskAiStore.getState();

  if (state.currentlyPlayingMessageId !== messageId && state.playbackInfo) {
    state.playbackInfo?.controls.sdkPlaybackControls.restart();
    state.playbackInfo?.controls.sdkPlaybackControls.pause();
  }

  useAskAiStore.setState({
    playbackInfo,
    currentlyPlayingMessageId: messageId
  });
};

const toggleAutoPlay = () => {
  useAskAiStore.setState(s => {
    const toggledValue = !s.autoPlay;
    logAnalyticsEvent(AnalyticsEventKey.documentAskAiAutoplayResponses, {
      state: toggledValue ? 'on' : 'off'
    });
    return { autoPlay: toggledValue };
  });
};

const setIsSummarizing = (isSummarizing: boolean) => {
  useAskAiStore.setState({ isSummarizing });
};

const setUserInteracted = (userInteracted: boolean) => {
  useAskAiStore.setState({ userInteracted });
};

const setCurrentlyPlayingMessagePlayButtonInView = (value: boolean | null) => {
  useAskAiStore.setState({ currentlyPlayingMessagePlayButtonInView: value });
};

const cleanUpAskAi = async () => {
  const { askAi } = await getSDK();
  askAi.destroy();
  useAskAiStore.reset();
};

export const askAiStoreActions = {
  fetchChats: fetchAskAiChatsForCurrentDocument,
  sendPrompt,
  summarizeDocument,
  deleteChat,
  cleanUpAskAi,
  regenerate,
  summarizeCurrentPage,
  summarizePages,
  toggleAutoPlay,
  setAutoPlay,
  setCurrentlyPlayingMessagePlayButtonInView,
  setUserInteracted,
  setPlaybackInfo
};
