import { Buffer } from 'buffer';

import { BoundaryRecord } from '@speechifyinc/multiplatform-sdk';
import {
  AbstractFirebaseAuthService,
  AbstractFirebaseFieldValueAdapter,
  AbstractFirebaseFirestoreQuerySnapshot,
  AbstractFirebaseService,
  AbstractFirebaseStorageAdapter,
  AbstractFirebaseTimestampAdapter,
  BoundaryTimestamp,
  DataSource,
  DocumentChange,
  DocumentChangeType,
  DocumentQueryBuilder,
  FirebaseAuthService,
  FirebaseAuthToken,
  FirebaseAuthUser,
  FirebaseFieldValueAdapter,
  FirebaseFirestoreDocumentSnapshot,
  FirebaseFirestoreService,
  FirebaseStorageAdapter,
  FirebaseTimestampAdapter,
  FullMetadata,
  SnapshotRef
} from '@speechifyinc/multiplatform-sdk/api/adapters/firebase';
import {
  BinaryContentReadableRandomly,
  BinaryContentWithMimeTypeFromNativeReadableInChunks,
  File,
  Result,
  SDKError
} from '@speechifyinc/multiplatform-sdk/api/util';
import { BoundaryMap } from '@speechifyinc/multiplatform-sdk/api/util/boundary';
import { FirebaseApp } from 'firebase/app';
import { Auth, getAuth, onAuthStateChanged } from 'firebase/auth';
import {
  DocumentData,
  FieldValue,
  Firestore,
  DocumentChange as FirestoreDocumentChange,
  OrderByDirection,
  Query,
  QueryConstraint,
  Timestamp,
  collection,
  deleteDoc,
  doc,
  endAt,
  endBefore,
  orderBy as firestoreOrderBy,
  query as firestoreQuery,
  getCountFromServer,
  getDoc,
  getDocFromCache,
  getDocFromServer,
  getDocs,
  getDocsFromCache,
  getDocsFromServer,
  getFirestore,
  increment,
  limit,
  onSnapshot,
  setDoc,
  startAfter,
  startAt,
  updateDoc,
  where
} from 'firebase/firestore';
import { FirebaseStorage, getDownloadURL, getMetadata, getStorage, ref as storageRef, uploadBytesResumable } from 'firebase/storage';

import { WebBoundaryMap } from './boundarymap';
import { WebBoundaryRecord } from './boundaryrecord';
import { callbackFromAsync } from './lib/callbackFromAsync';
import { Callback, Destructor } from './lib/typeAliases';
import { promisify } from './promisify';

const debug = false;

function logToConsole(message: string, ...optionalParams: unknown[]) {
  if (debug) {
    console.log(message, ...optionalParams);
  }
}

class WebFirebaseTimestampAdapter extends AbstractFirebaseTimestampAdapter {
  fromMillis(d: BoundaryTimestamp): Timestamp {
    return Timestamp.fromMillis(d.asDouble);
  }

  now(): Timestamp {
    return Timestamp.now();
  }
}

class WebFirebaseFieldValueAdapter extends AbstractFirebaseFieldValueAdapter {
  increment(amount: number): FieldValue {
    return increment(amount);
  }
}

class WebFirebaseStorageAdapter extends AbstractFirebaseStorageAdapter {
  constructor(readonly storage: FirebaseStorage) {
    super();
  }

  // ESLint: '_ref' is defined but never used & '_callback' is defined but never used
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-unused-vars
  delete(_ref: string, _callback: Callback<void>): void {
    throw new Error('Method not implemented.');
  }

  getDownloadUrl = (ref: string, callback: Callback<string>): void =>
    callbackFromAsync(async () => await getDownloadURL(storageRef(this.storage, ref)), callback);

  getMetadata = (ref: string, callback: Callback<FullMetadata>): void =>
    callbackFromAsync(async () => {
      const metadata = await getMetadata(storageRef(this.storage, ref));

      return new FullMetadata(
        metadata.bucket,
        metadata.cacheControl,
        metadata.contentDisposition,
        metadata.contentEncoding,
        metadata.contentLanguage,
        metadata.contentType,
        new WebBoundaryMap(metadata.customMetadata || {}),
        metadata.fullPath,
        metadata.generation,
        metadata.md5Hash,
        metadata.metageneration,
        metadata.name,
        metadata.size,
        metadata.timeCreated,
        metadata.updated
      );
    }, callback);

  putBinaryContent = (
    ref: string,
    binaryContent: BinaryContentWithMimeTypeFromNativeReadableInChunks<BinaryContentReadableRandomly>,
    callback: Callback<void>
  ): (() => void) => {
    const blob = binaryContent.binaryContent.blob;

    const arrayBufferPromise = blob.arrayBuffer();

    const abortController = new AbortController();

    // Note: do not await this as we need to immediately return a function to cancel the task.
    callbackFromAsync(async () => {
      const blob = binaryContent.binaryContent.blob;
      if (abortController.signal.aborted) {
        return;
      }

      const sRef = storageRef(this.storage, ref);
      if (abortController.signal.aborted) {
        return;
      }
      const arrayBuffer = await arrayBufferPromise;

      if (abortController.signal.aborted) {
        return;
      }

      const task = uploadBytesResumable(
        sRef,
        /* data: */ arrayBuffer,
        /* metadata: */ {
          contentType: blob.type
        }
      );
      await new Promise<void>((resolve, reject) => {
        abortController.signal.addEventListener('abort', () => {
          task.cancel();
          resolve();
        });
        task!.on(
          'state_changed',
          snapshot => {
            const progress = snapshot.bytesTransferred / snapshot.totalBytes;
            logToConsole('putBinaryContent progress: ', progress);
          },
          error => {
            if (error.code === 'storage/canceled') {
              resolve();
              return;
            }
            reject(error);
          },
          () => {
            getDownloadURL(task!.snapshot.ref).then(() => resolve());
          }
        );
      });
    }, callback);

    return () => {
      abortController.abort();
    };
  };

  putFile(ref: string, file: File, callback: (p1: Result<void>) => void): () => void {
    const abortController = new AbortController();

    // Note: do not await this as we need to immediately return a function to cancel the task.
    callbackFromAsync(async () => {
      const size = await promisify(file.getSizeInBytes.bind(file))();
      if (abortController.signal.aborted) {
        logToConsole('putFile size is ready but cancelling...');
        return;
      }

      const data = await promisify(file.getBytes.bind(file))(0, size);
      if (abortController.signal.aborted) {
        logToConsole('putFile data is ready but cancelling...');
        return;
      }

      const uploadTask = uploadBytesResumable(storageRef(this.storage, ref), Buffer.from(data), { contentType: file.mimeType.fullString });

      await new Promise<void>((resolve, reject) => {
        abortController.signal.addEventListener('abort', () => {
          uploadTask!.cancel();
          resolve();
        });

        uploadTask!.on(
          'state_changed',
          snapshot => {
            const progress = snapshot.bytesTransferred / snapshot.totalBytes;
            logToConsole('putFile progress: ', progress);
          },
          error => {
            if (error.code === 'storage/canceled') {
              resolve();
              return;
            }
            reject(error);
          },
          () => {
            getDownloadURL(uploadTask!.snapshot.ref).then(url => logToConsole('putFile success: ', url));
            resolve();
          }
        );
      });
    }, callback);

    return () => {
      abortController.abort();
    };
  }
}

// ESLint: Unexpected any & Unexpected any
// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-explicit-any
function transformBoundaryMapToRecord(value: BoundaryMap<any>): Record<string, any> {
  // ESLint: Unexpected any & Unexpected any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-explicit-any
  return value.keys().reduce((acc: Record<string, any>, next: any) => {
    return { ...acc, [next]: transformValueToPrimitive(value.get(next)) };
  }, {});
}

function transformValueToPrimitive(rawValue: $TSFixMe): $TSFixMe {
  if (Array.isArray(rawValue)) {
    return rawValue.map(transformValueToPrimitive);
  } else if (rawValue instanceof Timestamp || rawValue instanceof FieldValue) {
    return rawValue;
  } else if (typeof rawValue === 'object' && rawValue !== null) {
    return transformBoundaryMapToRecord(rawValue);
  }

  return rawValue;
}

function transformToSdkDocChangeType(docChange: FirestoreDocumentChange<DocumentData>): DocumentChangeType {
  let type;
  switch (docChange.type) {
    case 'added':
      type = DocumentChangeType.Added;
      break;
    case 'modified':
      type = DocumentChangeType.Modified;
      break;
    case 'removed':
      type = DocumentChangeType.Removed;
      break;
    default:
      throw new Error('Unknown change type');
  }
  return type;
}

export class WebFirebaseService extends AbstractFirebaseService {
  constructor(private app: FirebaseApp) {
    super();
  }

  getStorage(): FirebaseStorageAdapter {
    return new WebFirebaseStorageAdapter(getStorage(this.app));
  }

  getAuth(): FirebaseAuthService {
    return new WebFirebaseAuthService(getAuth(this.app));
  }

  getFirestore(): FirebaseFirestoreService {
    return new WebFirebaseFirestoreService(getFirestore(this.app));
  }

  getTimestampFactory(): FirebaseTimestampAdapter {
    return new WebFirebaseTimestampAdapter();
  }

  getFieldValueFactory(): FirebaseFieldValueAdapter {
    return new WebFirebaseFieldValueAdapter();
  }
}

export class WebFirebaseFirestoreService extends FirebaseFirestoreService {
  constructor(private firestore: Firestore) {
    super();
  }

  deleteDocument(collectionRef: string, documentRef: string, callback: (p1: Result<void>) => void): void {
    deleteDoc(doc(this.firestore, collectionRef, documentRef))
      // ESLint: '_' is defined but never used
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .then(_ => callback(new Result.Success(undefined)))
      .catch(err => callback(new Result.Failure(new SDKError.OtherMessage(err))));
  }

  observeDocument(collectionRef: string, documentRef: string, callback: Callback<FirebaseFirestoreDocumentSnapshot>): () => void {
    return onSnapshot(
      doc(this.firestore, collectionRef, documentRef),
      snapshot => {
        if (snapshot.exists()) {
          const data = snapshot.data();
          callback(
            new Result.Success(new FirebaseFirestoreDocumentSnapshot.Exists(snapshot.id, firestoreDataToBoundaryRecord(data), new SnapshotRef(snapshot)))
          );
        } else {
          callback(new Result.Success(FirebaseFirestoreDocumentSnapshot.NotExists));
        }
      },
      reason =>
        callback(
          reason.code === 'permission-denied'
            ? (() => {
                return new Result.Failure(new SDKError.NotAuthorized(reason.code, reason.message));
              })()
            : new Result.Failure(new SDKError.OtherMessage(reason.message))
        )
    );
  }

  queryDocuments(collectionRef: string): DocumentQueryBuilder {
    const buildQuery = (query: DocumentQueryBuilder.DocumentQuery): Query<DocumentData> => {
      const { whereClauses } = query;

      const collectionReference = collection(this.firestore, collectionRef);

      const constraints: QueryConstraint[] = [];

      const parseWhereClause = (clause: $TSFixMe) => {
        return {
          property: clause.property,
          operator: clause.operator.op,
          value: clause.value
        };
      };

      for (const clause of whereClauses) {
        const parsedClause = parseWhereClause(clause);
        constraints.push(where(parsedClause.property, parsedClause.operator, parsedClause.value));
      }

      for (const orderBy of query.orderBy) {
        constraints.push(firestoreOrderBy(orderBy.first, orderBy.second.direction as OrderByDirection));
      }

      if (query.limit != null) {
        constraints.push(limit(query.limit));
      }

      for (const bound of query.bounds) {
        const { first: fieldValue, second: boundType } = bound;
        switch (boundType) {
          case DocumentQueryBuilder.BoundType.StartAfter:
            constraints.push(startAfter(fieldValue));
            break;
          case DocumentQueryBuilder.BoundType.StartAt:
            constraints.push(startAt(fieldValue));
            break;
          case DocumentQueryBuilder.BoundType.EndBefore:
            constraints.push(endBefore(fieldValue));
            break;
          case DocumentQueryBuilder.BoundType.EndAt:
            constraints.push(endAt(fieldValue));
            break;
          default:
            throw new Error(`query bounding by ${boundType} not implemented`);
        }
      }

      for (const boundSnapshot of query.snapshotBounds) {
        const { first: snapshot, second: boundType } = boundSnapshot;
        switch (boundType) {
          case DocumentQueryBuilder.BoundType.StartAfter:
            constraints.push(startAfter(snapshot.get));
            break;
          case DocumentQueryBuilder.BoundType.StartAt:
            constraints.push(startAt(snapshot.get));
            break;
          case DocumentQueryBuilder.BoundType.EndBefore:
            constraints.push(endBefore(snapshot.get));
            break;
          case DocumentQueryBuilder.BoundType.EndAt:
            constraints.push(endAt(snapshot.get));
            break;
          default:
            throw new Error(`query bounding by ${boundType} not implemented`);
        }
      }

      return firestoreQuery(collectionReference, ...constraints);
    };
    return new DocumentQueryBuilder(
      (query, dataSource, callback) => {
        let getter;
        switch (this.sdkDataSourceToSource(dataSource)) {
          case 'cache':
            getter = getDocsFromCache;
            break;
          case 'server':
            getter = getDocsFromServer;
            break;
          case 'default':
            getter = getDocs;
            break;
        }

        getter(buildQuery(query))
          .then(querySnapshot => {
            const result = querySnapshot.docs.map(
              doc => new FirebaseFirestoreDocumentSnapshot.Exists(doc.id, firestoreDataToBoundaryRecord(doc.data()), new SnapshotRef(doc))
            );
            callback(new Result.Success(result));
          })
          .catch(e => callback(new Result.Failure(new SDKError.OtherException(e))));
      },
      (query, callback) => {
        return onSnapshot(
          buildQuery(query),
          querySnapshot => {
            class Snapshot extends AbstractFirebaseFirestoreQuerySnapshot {
              docChanges(includeMetadataChanges: boolean | null) {
                return querySnapshot.docChanges({ includeMetadataChanges: !!includeMetadataChanges }).map(docChange => {
                  const type = transformToSdkDocChangeType(docChange);
                  return new DocumentChange(
                    type,
                    new FirebaseFirestoreDocumentSnapshot.Exists(
                      docChange.doc.id,
                      firestoreDataToBoundaryRecord(docChange.doc.data()),
                      new SnapshotRef(docChange.doc)
                    ),
                    docChange.oldIndex,
                    docChange.newIndex
                  );
                });
              }
            }
            const result = new Snapshot();
            callback(new Result.Success(result));
          },
          e => callback(new Result.Failure(new SDKError.OtherMessage(e.message)))
        );
      },
      (query, callback) => {
        const snapshot = getCountFromServer(buildQuery(query));
        snapshot
          .then(querySnapshot => {
            callback(new Result.Success(querySnapshot.data().count));
          })
          .catch(e => callback(new Result.Failure(new SDKError.OtherException(e))));
      }
    );
  }

  // ESLint: Unexpected any & Unexpected any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-explicit-any
  updateDocument(collectionRef: string, documentRef: string, value: BoundaryMap<any>, callback: Callback<any>) {
    updateDoc(doc(this.firestore, collectionRef, documentRef), transformBoundaryMapToRecord(value)).then(
      () => callback(new Result.Success(true)),
      reason => callback(new Result.Failure(new SDKError.OtherException(reason)))
    );
  }

  getCollection(ref: string, dataSource: DataSource, callback: Callback<Array<FirebaseFirestoreDocumentSnapshot>>) {
    let getter;
    switch (this.sdkDataSourceToSource(dataSource)) {
      case 'cache':
        getter = getDocsFromCache;
        break;
      case 'server':
        getter = getDocsFromServer;
        break;
      case 'default':
        getter = getDocs;
        break;
    }

    getter(collection(this.firestore, ref))
      .then(col => {
        const data = col.docs.map(
          doc => new FirebaseFirestoreDocumentSnapshot.Exists(ref + '/' + doc.id, firestoreDataToBoundaryRecord(doc.data()), new SnapshotRef(doc))
        );
        callback(new Result.Success(data));
      })
      .catch(e => {
        if (typeof e.code === 'string' && e.code === 'permission-denied') {
          callback(new Result.Failure(new SDKError.NotAuthorized(e.code, e.message)));
        } else if (typeof e.code === 'string' && e.code === 'unavailable') {
          callback(new Result.Failure(new SDKError.ConnectionError(e.message)));
        } else {
          callback(new Result.Failure(new SDKError.OtherException(e)));
        }
      });
  }

  getDocument(collectionRef: string, documentRef: string, dataSource: DataSource, callback: Callback<FirebaseFirestoreDocumentSnapshot>) {
    let getter;
    switch (this.sdkDataSourceToSource(dataSource)) {
      case 'cache':
        getter = getDocFromCache;
        break;
      case 'server':
        getter = getDocFromServer;
        break;
      case 'default':
        getter = getDoc;
        break;
    }

    getter(doc(this.firestore, collectionRef, documentRef))
      .then(doc => {
        const document = doc.data();
        if (document) {
          callback(
            new Result.Success(new FirebaseFirestoreDocumentSnapshot.Exists(documentRef, firestoreDataToBoundaryRecord(doc.data()), new SnapshotRef(doc)))
          );
        } else {
          callback(new Result.Success(FirebaseFirestoreDocumentSnapshot.NotExists));
        }
      })
      .catch(e => {
        if (typeof e.code === 'string' && e.code === 'permission-denied') {
          callback(new Result.Failure(new SDKError.NotAuthorized(e.code, e.message)));
        } else if (typeof e.code === 'string' && e.code === 'unavailable') {
          callback(new Result.Failure(new SDKError.ConnectionError(e.message)));
        } else {
          callback(new Result.Failure(new SDKError.OtherException(e)));
        }
      });
  }

  // ESLint: Unexpected any & Unexpected any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-explicit-any
  setDocument(collectionRef: string, documentRef: string, value: BoundaryMap<any>, merge: boolean, callback: Callback<any>) {
    setDoc(doc(this.firestore, collectionRef, documentRef), transformBoundaryMapToRecord(value), { merge: merge }).then(
      () => callback(new Result.Success(true)),
      e => {
        if (typeof e.code === 'string' && e.code === 'unavailable') {
          callback(new Result.Failure(new SDKError.ConnectionError(e.message)));
        } else {
          callback(new Result.Failure(new SDKError.OtherException(e)));
        }
      }
    );
  }

  sdkDataSourceToSource(dataSource: DataSource): 'cache' | 'server' | 'default' {
    switch (dataSource) {
      case DataSource.CACHE:
        return 'cache';
      case DataSource.SERVER:
        return 'server';
      case DataSource.DEFAULT:
        return 'default';
      default:
        throw new Error(`data source ${dataSource} not implemented`);
    }
  }
}

function firestoreDataToBoundaryRecord(data: DocumentData | undefined): BoundaryRecord {
  return new WebBoundaryRecord(data!);
}

export class WebFirebaseAuthService extends AbstractFirebaseAuthService {
  constructor(private auth: Auth) {
    super();
  }

  getCurrentUser = (callback: Callback<FirebaseAuthUser | null>): void =>
    callbackFromAsync(async () => {
      await this.auth.authStateReady;
      const user = this.auth.currentUser;
      return !user ? null : new FirebaseAuthUser(user.uid, user.isAnonymous, user.displayName, user.email);
    }, callback);

  observeCurrentUser(callback: Callback<FirebaseAuthUser | null>): Destructor {
    return onAuthStateChanged(this.auth, () => {
      this.getCurrentUser(callback);
    });
  }

  getCurrentUserIdentityToken(callback: Callback<FirebaseAuthToken | null>) {
    const currentUser = this.auth.currentUser;
    if (currentUser) {
      currentUser
        .getIdTokenResult()
        .then(tokenResult => callback(new Result.Success(new FirebaseAuthToken(tokenResult.token, parseInt(tokenResult.expirationTime)))))
        .catch(e => callback(new Result.Failure(new SDKError.OtherException(e))));
    } else {
      callback(new Result.Success(null));
    }
  }
}
