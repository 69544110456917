import React, { useEffect } from 'react';

export type NonPDFPageControlsProps = {
  isPdf: false;
};

export type PDFPageControlsProps = {
  isPdf: true;
  currentPageIndex: number;
  pageCount: number;
  onPageIndexChange: (newPageIndex: number) => void;
};

export type PageControlsProps = NonPDFPageControlsProps | PDFPageControlsProps;
export const PageControls = (props: PageControlsProps) => {
  if (!props.isPdf) {
    return null;
  }
  return <PDFPageControls {...props} />;
};

const PDFPageControls = ({ currentPageIndex, pageCount, onPageIndexChange }: PDFPageControlsProps) => {
  const inputRef = React.useRef<HTMLInputElement>(null);
  const onInputFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    event.target.select();
  };

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.value = currentPageIndex.toString();
    }
  }, [currentPageIndex]);

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value === '') {
      // Allow empty input to be able to clear the input
      event.target.value = '';
      return;
    }

    const value = parseInt(event.target.value, 10);
    const newPage = isNaN(value) ? 1 : Math.min(Math.max(1, value), pageCount);
    onPageIndexChange(newPage);
    event.target.value = newPage.toString();
  };

  const onBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    event.target.value = event.target.value || currentPageIndex.toString() || '1';
  };

  return (
    <div className="flex gap-2 items-center mr-4 ml-spl-4 text-text-secondary text-sm">
      <input
        data-should-disable-autoscroll-temporarily
        ref={inputRef}
        type="text"
        className="w-10 h-8 border border-border-primary text-body-6 text-center rounded-200 text-text-primary min-w-10 px-1 bg-transparent font-normal"
        onChange={onChange}
        onBlur={onBlur}
        onFocus={onInputFocus}
        defaultValue={currentPageIndex}
      />
      <span>/</span>
      <span>{pageCount}</span>
    </div>
  );
};
