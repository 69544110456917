import * as React from 'react';

function BackIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.207 19.708a1 1 0 01-1.414 0l-7-7a1 1 0 010-1.415l7-7a1 1 0 011.414 1.414L9.914 12l6.293 6.293a1 1 0 010 1.415z"
        fill="currentColor"
      />
    </svg>
  );
}

export default BackIcon;
