import { highlightingStoreActions } from 'modules/listening/features/highlighting/highlightingStore';

import { IndividualSettingToggleImplementation } from '../../settings';

const toggleTextHighlightingSetting: IndividualSettingToggleImplementation<'textHighlighting'> = value => {
  if (value) {
    highlightingStoreActions.enableHighlighting();
  } else {
    highlightingStoreActions.disableHighlighting();
  }
};

export default toggleTextHighlightingSetting;
