export function nestedJsonParse(jsonString: string): ReturnType<typeof JSON.parse> {
  try {
    const parsed = JSON.parse(jsonString);

    if (Array.isArray(parsed)) {
      return parsed.map(value => {
        if (typeof value === 'string') {
          return nestedJsonParse(value);
        }
        return value;
      });
    }

    if (typeof parsed === 'object' && parsed !== null) {
      const result: Record<string, unknown> = {};
      Object.entries(parsed).forEach(([key, value]) => {
        if (typeof value === 'string') {
          result[key] = nestedJsonParse(value);
        } else {
          result[key] = value;
        }
      });
      return result;
    }

    return parsed;
  } catch (e) {
    return jsonString;
  }
}
