import * as React from 'react';

function LockIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_19615_2368)">
        <rect width={16} height={16} rx={8} fill="#E4E7EC" />
        <circle cx={8} cy={8} r={6.02545} fill="#587393" />
        <path d="M7.2 5.5v.7a.8.8 0 101.6 0v-.7a.8.8 0 10-1.6 0z" fill="#fff" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8 16A8 8 0 108 0a8 8 0 000 16zM5.7 7a.3.3 0 00.3-.3V5.5a2 2 0 114 0v1.2a.3.3 0 00.3.3 1.2 1.2 0 011.2 1.2v2.537a1.2 1.2 0 01-1.2 1.2H5.7a1.2 1.2 0 01-1.2-1.2V8.2A1.2 1.2 0 015.7 7z"
          fill="#fff"
        />
      </g>
      <defs>
        <clipPath id="clip0_19615_2368">
          <rect width={16} height={16} rx={8} fill="#fff" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default LockIcon;
