import * as React from 'react';

function AutoSkipContentIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M2.33334 8.99984C2.33334 5.31794 5.31811 2.33317 9 2.33317C12.6819 2.33317 15.6667 5.31794 15.6667 8.99984C15.6667 9.46007 16.0398 9.83317 16.5 9.83317C16.9602 9.83317 17.3333 9.46007 17.3333 8.99984C17.3333 4.39746 13.6024 0.666504 9 0.666504C4.39763 0.666504 0.666672 4.39746 0.666672 8.99984C0.666672 13.6022 4.39763 17.3332 9 17.3332C9.46024 17.3332 9.83334 16.9601 9.83334 16.4998C9.83334 16.0396 9.46024 15.6665 9 15.6665C5.31811 15.6665 2.33334 12.6817 2.33334 8.99984Z"
        fill="#AFB9C8"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.8751 17.5977C11.7052 18.0871 12.0686 18.5979 12.5867 18.5979C12.9179 18.5979 13.2102 18.3816 13.307 18.0649L13.6895 16.8135H16.2865L16.6673 18.063C16.7641 18.3808 17.0573 18.5979 17.3895 18.5979C17.909 18.5979 18.2734 18.0855 18.1027 17.5948L16.1258 11.9122C15.9859 11.5102 15.6069 11.2408 15.1813 11.2408H14.7936C14.3677 11.2408 13.9886 11.5105 13.8489 11.9128L11.8751 17.5977ZM15.0356 12.7091H14.9439L14.0416 15.6612H15.9353L15.0356 12.7091Z"
        fill="#AFB9C8"
      />
      <path
        d="M13.841 8.58135C14.1428 8.7787 14.1428 9.22096 13.841 9.41831L10.6822 11.4837C10.3496 11.7012 9.90855 11.4626 9.90855 11.0652V6.93442C9.90855 6.53709 10.3496 6.2985 10.6822 6.51594L13.841 8.58135Z"
        fill="#AFB9C8"
      />
      <path
        d="M9.56017 9.41831C9.862 9.22096 9.862 8.7787 9.56017 8.58135L6.40135 6.51594C6.0688 6.2985 5.62772 6.53709 5.62772 6.93442L5.62772 11.0652C5.62772 11.4626 6.0688 11.7012 6.40135 11.4837L9.56017 9.41831Z"
        fill="#AFB9C8"
      />
    </svg>
  );
}

export default AutoSkipContentIcon;
