import { createNonPersistentStore } from 'lib/zustand';

import { UpsellModalType } from './types';

export type UpsellStoreState = {
  activeModal: UpsellModalType | null;
};

export const useUpsellStore = createNonPersistentStore<UpsellStoreState>(
  () => ({
    activeModal: null
  }),
  {
    isListeningScreenStore: false
  }
);
