import { Blob } from '@web-std/file';

import { asyncIterableToArray } from '../collections/asyncIterableToArray';

export async function createBlobFromAsyncIterableOfUint8Arrays(asyncIterable: AsyncIterable<Uint8Array>, mimeType?: string): Promise<Blob> {
  return new Blob(
    /* `TypedArray`s do work as arguments to `Blob` ctor as per [this](https://developer.mozilla.org/en-US/docs/Web/API/Blob/Blob#parameters) */
    /* blobParts: */ await asyncIterableToArray(asyncIterable),
    /* options: */ { type: mimeType }
  );
}

export async function createBlobFromAsyncIterableOfInt8Arrays(asyncIterable: AsyncIterable<Int8Array>, mimeType?: string): Promise<Blob> {
  return new Blob(
    /* `TypedArray`s do work as arguments to `Blob` ctor as per [this](https://developer.mozilla.org/en-US/docs/Web/API/Blob/Blob#parameters) */
    /* blobParts: */ await asyncIterableToArray(asyncIterable),
    /* options: */ { type: mimeType }
  );
}
