import React from 'react';

export default function DropboxIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M12.4763 3.19336L1.6665 10.0858L9.19009 16.0422L19.9998 9.40503L12.4763 3.19336ZM1.6665 21.8284L12.4763 28.8059L19.9998 22.5942L9.19009 16.0422L1.6665 21.8284ZM19.9998 22.5942L27.6099 28.8059L38.3332 21.9135L30.8961 16.0422L19.9998 22.5942ZM38.3332 10.0858L27.6099 3.19336L19.9998 9.40503L30.8961 16.0422L38.3332 10.0858ZM20.0863 23.9557L12.4763 30.1673L9.27657 28.0401V30.4226L20.0863 36.8045L30.8961 30.4226V28.0401L27.6099 30.1673L20.0863 23.9557Z"
        fill="#007EE5"
      />
    </svg>
  );
}
