import { store } from 'store';

import { AnalyticsEventKey, logAnalyticsEvent } from 'modules/analytics/logAnalyticsEvent';
import { WordsListenedEvent } from 'modules/sdk/lib';
import type { EmbeddedListeningDependencies, ListeningDependencies } from 'modules/sdk/listeningDependencies';
import { tryQueueCsatBanner } from 'modules/sideBanner/stores/csatActions';

import { subscriptionStoreSelectors } from '../selectors';
import { logHdWordsListened } from './logHdWordsListened';
import { switchToFree } from './switchToFree';

let cleanUpPreviousWordTracker: () => void = () => {};

const getCurrentCount = () => Number.parseInt(localStorage.getItem('currentWordCount') ?? '0', 10);

const updateCurrentCount = (count: number) => {
  localStorage.setItem('currentWordCount', count.toString());
};

const handleWordsListened = (event: WordsListenedEvent, lastCount: number, currentCount: number) => {
  if (!event) return { lastCount, currentCount };

  const { count, voice, speedInRelativeFactor, speedInWPM } = event;
  const wordsListenedDiff = Math.abs(count - lastCount);

  if (currentCount > 1_000) {
    currentCount = 0;
    updateCurrentCount(0);
  }

  if (wordsListenedDiff >= 20) {
    lastCount = count;
    currentCount += 20;
    updateCurrentCount(currentCount);
  }

  // Log analytics
  if (currentCount !== 0 && (currentCount + wordsListenedDiff) % 500 === 0) {
    logAnalyticsEvent(AnalyticsEventKey.usageWordsListened, {
      is_highlighting_enabled: true,
      isEmbedded: false,
      isHD: voice.isPremium,
      voice_id: voice.id,
      speed: speedInRelativeFactor,
      isClonedVoice: Boolean(voice.isCustomVoice)
    });
  }

  if (currentCount !== 0 && (currentCount + wordsListenedDiff) % 100 === 0) {
    logAnalyticsEvent(AnalyticsEventKey.usageWordsListenedGamification, {
      is_highlighting_enabled: true,
      isHD: voice.isPremium,
      voice_id: voice.id,
      threshold: 100,
      wpm: speedInWPM,
      isGamificationEnabled: store.getState().gamification?.isEnabled ? true : false,
      isClonedVoice: Boolean(voice.isCustomVoice)
    });
  }

  if (voice.isPremium && wordsListenedDiff === 20) {
    logHdWordsListened(20);
  }

  tryQueueCsatBanner(currentCount);

  const hasHdWordsLeft = subscriptionStoreSelectors.getHasHdWords();
  if (hasHdWordsLeft !== undefined && !hasHdWordsLeft && voice.isPremium) {
    switchToFree();
  }

  return { lastCount, currentCount };
};

export const startWordTracker = (listeningDependencies: ListeningDependencies) => {
  cleanUpPreviousWordTracker();
  let lastCount = 0;
  let currentCount = getCurrentCount();

  cleanUpPreviousWordTracker = listeningDependencies.playbackInfo.addWordsListenedListener(event => {
    const result = handleWordsListened(event, lastCount, currentCount);
    lastCount = result.lastCount;
    currentCount = result.currentCount;
  });
};

// This is part of closure to ensure lastCount and currentCount persisting between
// invokations in multiple places.
const createAlsoTrackWordsFor = () => {
  if (typeof window === 'undefined') return () => {};

  let lastCount = 0;
  let currentCount = getCurrentCount();
  let cleanupFunctions: (() => void)[] = [];

  return (listeningDependencies: ListeningDependencies | EmbeddedListeningDependencies) => {
    const cleanup = listeningDependencies.playbackInfo.addWordsListenedListener(event => {
      const result = handleWordsListened(event, lastCount, currentCount);
      lastCount = result.lastCount;
      currentCount = result.currentCount;
    });

    cleanupFunctions.push(cleanup);

    return () => {
      cleanup();
      cleanupFunctions = cleanupFunctions.filter(fn => fn !== cleanup);
    };
  };
};

export const alsoTrackWordsFor = createAlsoTrackWordsFor();
