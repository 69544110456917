import React from 'react';

export default function OneDriveIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M15.4516 14.3196L15.4519 14.3183L23.4991 19.1385L28.2943 17.1206C29.2687 16.6993 30.3194 16.4833 31.3809 16.4844C31.5578 16.4844 31.7326 16.4924 31.9065 16.504C31.33 14.256 30.113 12.2239 28.4033 10.6545C26.6937 9.08514 24.565 8.04614 22.276 7.66375C19.9869 7.28136 17.6361 7.57205 15.5092 8.5005C13.3823 9.42894 11.5708 10.9552 10.2949 12.8937C10.3359 12.8932 10.3763 12.8906 10.4174 12.8906C12.1959 12.8882 13.9396 13.3832 15.4516 14.3196Z"
        fill="#0364B8"
      />
      <path
        d="M15.4519 14.3183L15.4515 14.3197C13.9395 13.3832 12.1958 12.8882 10.4173 12.8906C10.3762 12.8906 10.3358 12.8932 10.2949 12.8937C8.55416 12.9153 6.85225 13.4107 5.372 14.3269C3.89176 15.2431 2.68914 16.5453 1.89339 18.0936C1.09764 19.6419 0.738834 21.3777 0.855539 23.1147C0.972244 24.8516 1.56004 26.5238 2.55577 27.9518L9.65223 24.9655L12.8068 23.6379L19.8309 20.6821L23.499 19.1385L15.4519 14.3183Z"
        fill="#0078D4"
      />
      <path
        d="M31.9055 16.504C31.7315 16.4924 31.5567 16.4844 31.3798 16.4844C30.3183 16.4833 29.2678 16.7002 28.2934 17.1214L23.498 19.1385L24.8886 19.9714L29.4465 22.7016L31.4352 23.8927L38.235 27.9657C38.8528 26.8188 39.173 25.5351 39.1662 24.2323C39.1594 22.9295 38.8258 21.6492 38.196 20.5087C37.5662 19.3683 36.6603 18.404 35.5613 17.7044C34.4623 17.0047 33.2053 16.592 31.9055 16.504Z"
        fill="#1490DF"
      />
      <path
        d="M31.4361 23.8924L29.4474 22.7012L24.8894 19.9711L23.4989 19.1382L19.8308 20.6818L12.8067 23.6376L9.65212 24.9651L2.55566 27.9514C3.43753 29.2193 4.61314 30.255 5.98213 30.9699C7.35111 31.6848 8.87278 32.0578 10.4172 32.057H31.3807C32.7863 32.0574 34.1658 31.6772 35.3727 30.9569C36.5797 30.2365 37.569 29.2028 38.2359 27.9654L31.4361 23.8924Z"
        fill="#28A8EA"
      />
    </svg>
  );
}
