import { AbstractBoundaryMap, BoundaryPair } from '@speechifyinc/multiplatform-sdk';

export class WebBoundaryMap<T> extends AbstractBoundaryMap<T> {
  constructor(private obj: Record<string | number, T>) {
    super();
  }
  entries(): BoundaryPair<string, T>[] {
    return Object.entries(this.obj).map(([key, value]) => new BoundaryPair(key, value));
  }

  get(key: string): T | null {
    const v = this.obj[key];

    if (v === undefined) {
      return null;
    }

    return v;
  }

  hasKey(key: string): boolean {
    return !!this.obj[key];
  }

  keys(): string[] {
    return Object.keys(this.obj);
  }

  set(key: string, value: T): void {
    this.obj[key] = value;
  }

  values(): T[] {
    return Object.values(this.obj);
  }
}
