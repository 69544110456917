import { clickToListenStoreActions } from 'modules/listening/features/clickToListen/clickToListenStore';

import { IndividualSettingToggleImplementation } from '../../settings';

const toggleClickToListenSetting: IndividualSettingToggleImplementation<'clickToListen'> = value => {
  if (value) {
    clickToListenStoreActions.enableClickToListen();
  } else {
    clickToListenStoreActions.disableClickToListen();
  }
};

export default toggleClickToListenSetting;
