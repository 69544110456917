/**
 * Need this separately from Kotlin Destructible because that one also has 'readonly __doNotUseIt: __doNotImplementIt;' property.
 */
export type Destructible = {
  destroy(): void;
};

export abstract class BaseDestructible implements Destructible {
  abstract destroy(): void;
}
