import * as React from 'react';

function MoveIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={21} height={20} viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_18776_30717)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.5 17a2 2 0 01-2-2V4a2 2 0 012-2h3.305a2 2 0 011.615.82l.859 1.176H17.5a2 2 0 012 2V10a1 1 0 11-2 0V5.996H8.77a1 1 0 01-.807-.41L6.805 4H3.5v11h6a1 1 0 110 2h-6zm13 0v1a1 1 0 001.707.707l2-2a1 1 0 000-1.414l-2-2A1 1 0 0016.5 14v1h-3a1 1 0 100 2h3z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_18776_30717">
          <path fill="#fff" transform="translate(.5)" d="M0 0H20V20H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default MoveIcon;
