import * as React from 'react';

function TOCIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M4.5 7.5a1.5 1.5 0 100-3 1.5 1.5 0 000 3zM8 6a1 1 0 011-1h11a1 1 0 110 2H9a1 1 0 01-1-1zM9 11a1 1 0 100 2h11a1 1 0 100-2H9zM9 17a1 1 0 100 2h11a1 1 0 100-2H9zM6 12a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0zM4.5 19.5a1.5 1.5 0 100-3 1.5 1.5 0 000 3z"
        fill="currentColor"
      />
    </svg>
  );
}

export default TOCIcon;
