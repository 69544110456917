// @generated by protobuf-ts 2.9.0 with parameter long_type_string
// @generated from protobuf file "get.proto" (syntax proto3)
// tslint:disable
/* eslint-disable */
import type { BinaryWriteOptions } from '@protobuf-ts/runtime';
import type { IBinaryWriter } from '@protobuf-ts/runtime';
import { WireType } from '@protobuf-ts/runtime';
import type { BinaryReadOptions } from '@protobuf-ts/runtime';
import type { IBinaryReader } from '@protobuf-ts/runtime';
import { UnknownFieldHandler } from '@protobuf-ts/runtime';
import type { PartialMessage } from '@protobuf-ts/runtime';
import { reflectionMergePartial } from '@protobuf-ts/runtime';
import { MESSAGE_TYPE } from '@protobuf-ts/runtime';
import { MessageType } from '@protobuf-ts/runtime';
/**
 * @generated from protobuf message Response
 */
export interface Response {
  /**
   * @generated from protobuf field: bytes audioData = 1;
   */
  audioData: Uint8Array;
  /**
   * @generated from protobuf field: string audioFormat = 2;
   */
  audioFormat: string;
  /**
   * @generated from protobuf field: Response.NestedChunk speechMarks = 3;
   */
  speechMarks?: Response_NestedChunk;
}
/**
 * @generated from protobuf message Response.NestedChunk
 */
export interface Response_NestedChunk {
  /**
   * @generated from protobuf field: string type = 1;
   */
  type: string;
  /**
   * @generated from protobuf field: double startTime = 2;
   */
  startTime: number;
  /**
   * @generated from protobuf field: double endTime = 3;
   */
  endTime: number;
  /**
   * @generated from protobuf field: int32 start = 4;
   */
  start: number;
  /**
   * @generated from protobuf field: int32 end = 5;
   */
  end: number;
  /**
   * @generated from protobuf field: string value = 6;
   */
  value: string;
  /**
   * @generated from protobuf field: repeated Response.Chunk chunks = 7;
   */
  chunks: Response_Chunk[];
}
/**
 * @generated from protobuf message Response.Chunk
 */
export interface Response_Chunk {
  /**
   * @generated from protobuf field: string type = 1;
   */
  type: string;
  /**
   * @generated from protobuf field: double startTime = 2;
   */
  startTime: number;
  /**
   * @generated from protobuf field: double endTime = 3;
   */
  endTime: number;
  /**
   * @generated from protobuf field: int32 start = 4;
   */
  start: number;
  /**
   * @generated from protobuf field: int32 end = 5;
   */
  end: number;
  /**
   * @generated from protobuf field: string value = 6;
   */
  value: string;
}
// @generated message type with reflection information, may provide speed optimized methods
class Response$Type extends MessageType<Response> {
  constructor() {
    super('Response', [
      { no: 1, name: 'audioData', kind: 'scalar', T: 12 /*ScalarType.BYTES*/ },
      { no: 2, name: 'audioFormat', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 3, name: 'speechMarks', kind: 'message', T: () => Response_NestedChunk }
    ]);
  }
  create(value?: PartialMessage<Response>): Response {
    const message = { audioData: new Uint8Array(0), audioFormat: '' };
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
    if (value !== undefined) reflectionMergePartial<Response>(this, message, value);
    return message;
  }
  internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Response): Response {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* bytes audioData */ 1:
          message.audioData = reader.bytes();
          break;
        case /* string audioFormat */ 2:
          message.audioFormat = reader.string();
          break;
        case /* Response.NestedChunk speechMarks */ 3:
          message.speechMarks = Response_NestedChunk.internalBinaryRead(reader, reader.uint32(), options, message.speechMarks);
          break;
        default:
          let u = options.readUnknownField;
          if (u === 'throw') throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
          let d = reader.skip(wireType);
          if (u !== false) (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
      }
    }
    return message;
  }
  internalBinaryWrite(message: Response, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
    /* bytes audioData = 1; */
    if (message.audioData.length) writer.tag(1, WireType.LengthDelimited).bytes(message.audioData);
    /* string audioFormat = 2; */
    if (message.audioFormat !== '') writer.tag(2, WireType.LengthDelimited).string(message.audioFormat);
    /* Response.NestedChunk speechMarks = 3; */
    if (message.speechMarks) Response_NestedChunk.internalBinaryWrite(message.speechMarks, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
    let u = options.writeUnknownFields;
    if (u !== false) (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message Response
 */
export const Response = new Response$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Response_NestedChunk$Type extends MessageType<Response_NestedChunk> {
  constructor() {
    super('Response.NestedChunk', [
      { no: 1, name: 'type', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'startTime', kind: 'scalar', T: 1 /*ScalarType.DOUBLE*/ },
      { no: 3, name: 'endTime', kind: 'scalar', T: 1 /*ScalarType.DOUBLE*/ },
      { no: 4, name: 'start', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      { no: 5, name: 'end', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      { no: 6, name: 'value', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 7, name: 'chunks', kind: 'message', repeat: 1 /*RepeatType.PACKED*/, T: () => Response_Chunk }
    ]);
  }
  create(value?: PartialMessage<Response_NestedChunk>): Response_NestedChunk {
    const message = { type: '', startTime: 0, endTime: 0, start: 0, end: 0, value: '', chunks: [] };
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
    if (value !== undefined) reflectionMergePartial<Response_NestedChunk>(this, message, value);
    return message;
  }
  internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Response_NestedChunk): Response_NestedChunk {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* string type */ 1:
          message.type = reader.string();
          break;
        case /* double startTime */ 2:
          message.startTime = reader.double();
          break;
        case /* double endTime */ 3:
          message.endTime = reader.double();
          break;
        case /* int32 start */ 4:
          message.start = reader.int32();
          break;
        case /* int32 end */ 5:
          message.end = reader.int32();
          break;
        case /* string value */ 6:
          message.value = reader.string();
          break;
        case /* repeated Response.Chunk chunks */ 7:
          message.chunks.push(Response_Chunk.internalBinaryRead(reader, reader.uint32(), options));
          break;
        default:
          let u = options.readUnknownField;
          if (u === 'throw') throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
          let d = reader.skip(wireType);
          if (u !== false) (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
      }
    }
    return message;
  }
  internalBinaryWrite(message: Response_NestedChunk, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
    /* string type = 1; */
    if (message.type !== '') writer.tag(1, WireType.LengthDelimited).string(message.type);
    /* double startTime = 2; */
    if (message.startTime !== 0) writer.tag(2, WireType.Bit64).double(message.startTime);
    /* double endTime = 3; */
    if (message.endTime !== 0) writer.tag(3, WireType.Bit64).double(message.endTime);
    /* int32 start = 4; */
    if (message.start !== 0) writer.tag(4, WireType.Varint).int32(message.start);
    /* int32 end = 5; */
    if (message.end !== 0) writer.tag(5, WireType.Varint).int32(message.end);
    /* string value = 6; */
    if (message.value !== '') writer.tag(6, WireType.LengthDelimited).string(message.value);
    /* repeated Response.Chunk chunks = 7; */
    for (let i = 0; i < message.chunks.length; i++)
      Response_Chunk.internalBinaryWrite(message.chunks[i], writer.tag(7, WireType.LengthDelimited).fork(), options).join();
    let u = options.writeUnknownFields;
    if (u !== false) (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message Response.NestedChunk
 */
export const Response_NestedChunk = new Response_NestedChunk$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Response_Chunk$Type extends MessageType<Response_Chunk> {
  constructor() {
    super('Response.Chunk', [
      { no: 1, name: 'type', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'startTime', kind: 'scalar', T: 1 /*ScalarType.DOUBLE*/ },
      { no: 3, name: 'endTime', kind: 'scalar', T: 1 /*ScalarType.DOUBLE*/ },
      { no: 4, name: 'start', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      { no: 5, name: 'end', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      { no: 6, name: 'value', kind: 'scalar', T: 9 /*ScalarType.STRING*/ }
    ]);
  }
  create(value?: PartialMessage<Response_Chunk>): Response_Chunk {
    const message = { type: '', startTime: 0, endTime: 0, start: 0, end: 0, value: '' };
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
    if (value !== undefined) reflectionMergePartial<Response_Chunk>(this, message, value);
    return message;
  }
  internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Response_Chunk): Response_Chunk {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* string type */ 1:
          message.type = reader.string();
          break;
        case /* double startTime */ 2:
          message.startTime = reader.double();
          break;
        case /* double endTime */ 3:
          message.endTime = reader.double();
          break;
        case /* int32 start */ 4:
          message.start = reader.int32();
          break;
        case /* int32 end */ 5:
          message.end = reader.int32();
          break;
        case /* string value */ 6:
          message.value = reader.string();
          break;
        default:
          let u = options.readUnknownField;
          if (u === 'throw') throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
          let d = reader.skip(wireType);
          if (u !== false) (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
      }
    }
    return message;
  }
  internalBinaryWrite(message: Response_Chunk, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
    /* string type = 1; */
    if (message.type !== '') writer.tag(1, WireType.LengthDelimited).string(message.type);
    /* double startTime = 2; */
    if (message.startTime !== 0) writer.tag(2, WireType.Bit64).double(message.startTime);
    /* double endTime = 3; */
    if (message.endTime !== 0) writer.tag(3, WireType.Bit64).double(message.endTime);
    /* int32 start = 4; */
    if (message.start !== 0) writer.tag(4, WireType.Varint).int32(message.start);
    /* int32 end = 5; */
    if (message.end !== 0) writer.tag(5, WireType.Varint).int32(message.end);
    /* string value = 6; */
    if (message.value !== '') writer.tag(6, WireType.LengthDelimited).string(message.value);
    let u = options.writeUnknownFields;
    if (u !== false) (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message Response.Chunk
 */
export const Response_Chunk = new Response_Chunk$Type();
