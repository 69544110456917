import { SDKVoiceFacade } from 'modules/sdk/lib';

import { getLatestPlayingVoiceLanguageCode } from '../selectors/getLatestPlayingVoiceLanguageCode';
import { setVoice } from './setVoice';

export const switchToFreeVoice = () => {
  const freeVoiceInfo = SDKVoiceFacade.singleton.getFallbackFreeVoiceInfo(getLatestPlayingVoiceLanguageCode());
  setVoice(freeVoiceInfo, {
    shouldPlayPreview: false
  });
};
