export const AiIcon: React.FC<React.SVGProps<SVGSVGElement>> = props => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M7.00006 1C5.34318 1 4.00003 2.34318 4.00006 4.00006L4.00054 27.0312C4.00056 28.3922 4.92364 29.6153 6.29132 29.9194C12.7721 31.3602 19.2285 31.3602 25.7092 29.9194C27.0769 29.6153 28 28.3921 28 27.031L27.9996 9.61862C27.9995 8.81747 27.6791 8.04961 27.1096 7.48613L21.4309 1.86744C20.8692 1.31171 20.111 1 19.3208 1H7.00006Z"
      fill="#6876FF"
    ></path>
    <path
      d="M7.00006 1C5.34318 1 4.00003 2.34318 4.00006 4.00006L4.00054 27.0312C4.00056 28.3922 4.92364 29.6153 6.29132 29.9194C12.7721 31.3602 19.2285 31.3602 25.7092 29.9194C27.0769 29.6153 28 28.3921 28 27.031L27.9996 9.61862C27.9995 8.81747 27.6791 8.04961 27.1096 7.48613L21.4309 1.86744C20.8692 1.31171 20.111 1 19.3208 1H7.00006Z"
      fill="url(#paint0_radial_1050_789)"
    ></path>
    <path
      d="M7.00006 1C5.34318 1 4.00003 2.34318 4.00006 4.00006L4.00054 27.0312C4.00056 28.3922 4.92364 29.6153 6.29132 29.9194C12.7721 31.3602 19.2285 31.3602 25.7092 29.9194C27.0769 29.6153 28 28.3921 28 27.031L27.9996 9.61862C27.9995 8.81747 27.6791 8.04961 27.1096 7.48613L21.4309 1.86744C20.8692 1.31171 20.111 1 19.3208 1H7.00006Z"
      fill="url(#paint1_radial_1050_789)"
    ></path>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.95606 23.8549C8.7013 24.6113 9.26398 25.3945 10.0621 25.3945C10.584 25.3945 11.0424 25.0482 11.1849 24.5462L11.53 23.3307H14.5964L14.9414 24.5462C15.084 25.0482 15.5424 25.3945 16.0642 25.3945C16.8623 25.3945 17.425 24.6113 17.1703 23.8549L14.9913 17.3851C14.7125 16.5574 13.9365 16 13.0632 16C12.1898 16 11.4138 16.5574 11.1351 17.3851L8.95606 23.8549ZM12.0248 21.612L13.0404 18.0443H13.0859L14.1016 21.612H12.0248ZM19.1045 24.1999C19.1045 24.8597 19.6394 25.3945 20.2992 25.3945C20.959 25.3945 21.4938 24.8597 21.4938 24.1999V17.1947C21.4938 16.5349 20.959 16 20.2992 16C19.6394 16 19.1045 16.5349 19.1045 17.1947V24.1999Z"
      fill="white"
    ></path>
    <path
      d="M28 9.49999V9.33711C28 8.49982 27.6501 7.70062 27.0348 7.1327L21.2529 1.7955C20.7681 1.34807 20.1516 1.07469 19.5 1.01318V6.08194C19.5 7.96968 21.0035 9.49999 22.8581 9.49999H28Z"
      fill="#FF6AFF"
    ></path>
    <defs>
      <radialGradient
        id="paint0_radial_1050_789"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(8.00998 3.76272) rotate(-136.204) scale(15.813 17.4254)"
      >
        <stop stopColor="#F044FF"></stop>
        <stop offset="1" stopColor="#F044FF" stopOpacity="0"></stop>
      </radialGradient>
      <radialGradient
        id="paint1_radial_1050_789"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(23.549 27.5529) rotate(146.676) scale(14.2011 13.3866)"
      >
        <stop stopColor="#5AA6FF"></stop>
        <stop offset="1" stopColor="#5AA6FF" stopOpacity="0"></stop>
      </radialGradient>
    </defs>
  </svg>
);

export const AiDarkIcon: React.FC<React.SVGProps<SVGSVGElement>> = props => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M7.00006 1C5.34318 1 4.00003 2.34318 4.00006 4.00006L4.00054 27.0312C4.00056 28.3922 4.92364 29.6153 6.29132 29.9194C12.7721 31.3602 19.2285 31.3602 25.7092 29.9194C27.0769 29.6153 28 28.3921 28 27.031L27.9996 9.61862C27.9995 8.81747 27.6791 8.04961 27.1096 7.48613L21.4309 1.86744C20.8692 1.31171 20.111 1 19.3208 1H7.00006Z"
      fill="#7E85FF"
    ></path>
    <path
      d="M7.00006 1C5.34318 1 4.00003 2.34318 4.00006 4.00006L4.00054 27.0312C4.00056 28.3922 4.92364 29.6153 6.29132 29.9194C12.7721 31.3602 19.2285 31.3602 25.7092 29.9194C27.0769 29.6153 28 28.3921 28 27.031L27.9996 9.61862C27.9995 8.81747 27.6791 8.04961 27.1096 7.48613L21.4309 1.86744C20.8692 1.31171 20.111 1 19.3208 1H7.00006Z"
      fill="url(#paint0_radial_1053_163)"
    ></path>
    <path
      d="M7.00006 1C5.34318 1 4.00003 2.34318 4.00006 4.00006L4.00054 27.0312C4.00056 28.3922 4.92364 29.6153 6.29132 29.9194C12.7721 31.3602 19.2285 31.3602 25.7092 29.9194C27.0769 29.6153 28 28.3921 28 27.031L27.9996 9.61862C27.9995 8.81747 27.6791 8.04961 27.1096 7.48613L21.4309 1.86744C20.8692 1.31171 20.111 1 19.3208 1H7.00006Z"
      fill="url(#paint1_radial_1053_163)"
    ></path>
    <path
      d="M20.2992 25.3945C19.6394 25.3945 19.1045 24.8597 19.1045 24.1999V17.1947C19.1045 16.5349 19.6394 16 20.2992 16C20.9589 16 21.4938 16.5349 21.4938 17.1947V24.1999C21.4938 24.8597 20.9589 25.3945 20.2992 25.3945Z"
      fill="white"
    ></path>
    <path
      d="M10.0621 25.3945C9.26396 25.3945 8.70129 24.6113 8.95605 23.8549L11.1351 17.3851C11.4138 16.5574 12.1898 16 13.0632 16C13.9365 16 14.7125 16.5574 14.9913 17.3851L17.1703 23.8549C17.425 24.6113 16.8623 25.3945 16.0642 25.3945C15.5424 25.3945 15.0839 25.0482 14.9414 24.5462L14.5964 23.3307H11.5299L11.1849 24.5462C11.0424 25.0482 10.5839 25.3945 10.0621 25.3945ZM13.0404 18.0443L12.0247 21.612H14.1016L13.0859 18.0443H13.0404Z"
      fill="white"
    ></path>
    <path
      d="M28 9.49999V9.33711C28 8.49982 27.6501 7.70062 27.0348 7.1327L21.2529 1.7955C20.7681 1.34807 20.1516 1.07469 19.5 1.01318V6.08194C19.5 7.96968 21.0035 9.49999 22.8581 9.49999H28Z"
      fill="#FF69FF"
    ></path>
    <defs>
      <radialGradient
        id="paint0_radial_1053_163"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(8.00998 3.76272) rotate(-136.204) scale(15.813 17.4254)"
      >
        <stop stopColor="#FF50FF"></stop>
        <stop offset="1" stopColor="#FF50FF" stopOpacity="0"></stop>
      </radialGradient>
      <radialGradient
        id="paint1_radial_1053_163"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(23.549 27.5529) rotate(146.676) scale(14.2011 13.3866)"
      >
        <stop stopColor="#67B4FF"></stop>
        <stop offset="1" stopColor="#67B4FF" stopOpacity="0"></stop>
      </radialGradient>
    </defs>
  </svg>
);

export const BookIcon: React.FC<React.SVGProps<SVGSVGElement>> = props => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect x="6" y="2" width="21" height="7" fill="#FFDFB2"></rect>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.5 3C6.67157 3 6 3.67157 6 4.5C6 5.32843 6.67157 6 7.5 6H27C27.5523 6 28 6.44772 28 7C28 7.55228 27.5523 8 27 8H7.5C5.567 8 4 6.433 4 4.5C4 2.567 5.567 1 7.5 1H27C27.5523 1 28 1.44772 28 2C28 2.55228 27.5523 3 27 3H7.5Z"
      fill="#FF9500"
    ></path>
    <path
      d="M6 5C6 4.44772 5.55228 4 5 4C4.44772 4 4 4.44772 4 5V26.3333C4 28.3584 5.64162 30 7.66667 30H27C27.5523 30 28 29.5523 28 29V7C28 6.44772 27.5523 6 27 6H6V5Z"
      fill="#FF9500"
    ></path>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23 13C23 13.5523 22.5523 14 22 14H10C9.44772 14 9 13.5523 9 13C9 12.4477 9.44772 12 10 12H22C22.5523 12 23 12.4477 23 13Z"
      fill="white"
    ></path>
  </svg>
);

export const BookDarkIcon: React.FC<React.SVGProps<SVGSVGElement>> = props => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect x="6" y="2" width="21" height="7" fill="#E6BB7F"></rect>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.5 3C6.67157 3 6 3.67157 6 4.5C6 5.32843 6.67157 6 7.5 6H27C27.5523 6 28 6.44772 28 7C28 7.55228 27.5523 8 27 8H7.5C5.567 8 4 6.433 4 4.5C4 2.567 5.567 1 7.5 1H27C27.5523 1 28 1.44772 28 2C28 2.55228 27.5523 3 27 3H7.5Z"
      fill="#E68600"
    ></path>
    <path d="M5 5V7H27V29H7.66667C6.19391 29 5 27.8061 5 26.3333V5Z" fill="#E68600"></path>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 4C5.55228 4 6 4.44772 6 5V6H27C27.5523 6 28 6.44772 28 7V29C28 29.5523 27.5523 30 27 30H7.66667C5.64162 30 4 28.3584 4 26.3333V5C4 4.44772 4.44772 4 5 4ZM6 8V26.3333C6 27.2538 6.74619 28 7.66667 28H26V8H6Z"
      fill="#E68600"
    ></path>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23 13C23 13.5523 22.5523 14 22 14H10C9.44772 14 9 13.5523 9 13C9 12.4477 9.44772 12 10 12H22C22.5523 12 23 12.4477 23 13Z"
      fill="white"
    ></path>
  </svg>
);

export const DocIcon: React.FC<React.SVGProps<SVGSVGElement>> = props => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M7.00006 1C5.34318 1 4.00003 2.34318 4.00006 4.00006L4.00054 27.0312C4.00056 28.3922 4.92364 29.6153 6.29132 29.9194C12.7721 31.3602 19.2285 31.3602 25.7092 29.9194C27.0769 29.6153 28 28.3921 28 27.031L27.9996 9.5484C27.9995 8.74132 27.6743 7.96829 27.0974 7.40392L21.4253 1.85545C20.8647 1.30707 20.1117 1 19.3275 1H7.00006Z"
      fill="#2B73FF"
    ></path>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.2623 24.1159C14.176 24.1159 12.8708 22.7488 12.8708 20.5578V20.5482C12.8708 18.3619 14.1855 16.9949 16.2623 16.9949C18.3533 16.9949 19.6584 18.3619 19.6584 20.5482V20.5578C19.6584 22.7488 18.3581 24.1159 16.2623 24.1159ZM16.2623 22.6679C17.2625 22.6679 17.8817 21.8486 17.8817 20.5578V20.5482C17.8817 19.2479 17.2482 18.4429 16.2623 18.4429C15.2858 18.4429 14.6523 19.2431 14.6523 20.5482V20.5578C14.6523 21.8629 15.281 22.6679 16.2623 22.6679ZM23.762 24.1159C21.6995 24.1159 20.4182 22.7965 20.4182 20.553V20.5482C20.4182 18.3048 21.709 16.9949 23.762 16.9949C25.0315 16.9949 26.284 17.6924 26.71 18.8549C26.8803 19.3198 26.4615 19.7337 25.9663 19.7337C25.517 19.7337 25.1655 19.373 24.9037 19.0078C24.6532 18.6583 24.2559 18.4429 23.762 18.4429C22.8046 18.4429 22.1997 19.2288 22.1997 20.5435V20.5482C22.1997 21.8772 22.8093 22.6679 23.7667 22.6679C24.2931 22.6679 24.687 22.4292 24.9326 22.0518C25.1623 21.6988 25.4971 21.377 25.9182 21.377C26.4306 21.377 26.8451 21.8275 26.6523 22.3022C26.1929 23.4334 25.1032 24.1159 23.762 24.1159ZM6.02911 22.6586C6.02911 23.395 6.62607 23.992 7.36245 23.992H8.82035C10.8923 23.992 12.1165 22.7107 12.1165 20.5148V20.5053C12.1165 18.319 10.8923 17.1187 8.82035 17.1187H7.36245C6.62607 17.1187 6.02911 17.7156 6.02911 18.452V22.6586ZM8.59172 22.582H7.77721V18.5286H8.59172C9.69202 18.5286 10.3398 19.224 10.3398 20.5101V20.5196C10.3398 21.8914 9.73012 22.582 8.59172 22.582Z"
      fill="white"
    ></path>
    <path
      d="M28 9.49999V9.33711C28 8.49982 27.6501 7.70062 27.0348 7.1327L21.2529 1.7955C20.7681 1.34807 20.1516 1.07469 19.5 1.01318V6.08194C19.5 7.96968 21.0035 9.49999 22.8581 9.49999H28Z"
      fill="#5199FF"
    ></path>
  </svg>
);

export const DocDarkIcon: React.FC<React.SVGProps<SVGSVGElement>> = props => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M7.00006 1C5.34318 1 4.00003 2.34318 4.00006 4.00006L4.00054 27.0312C4.00056 28.3922 4.92364 29.6153 6.29132 29.9194C12.7721 31.3602 19.2285 31.3602 25.7092 29.9194C27.0769 29.6153 28 28.3921 28 27.031L27.9996 9.5484C27.9995 8.74132 27.6743 7.96829 27.0974 7.40392L21.4253 1.85545C20.8647 1.30707 20.1117 1 19.3275 1H7.00006Z"
      fill="#3D72E9"
    ></path>
    <path
      d="M23.762 24.1159C21.6995 24.1159 20.4182 22.7965 20.4182 20.553V20.5482C20.4182 18.3048 21.709 16.9949 23.762 16.9949C25.0315 16.9949 26.284 17.6924 26.71 18.8549C26.8803 19.3198 26.4615 19.7337 25.9663 19.7337C25.517 19.7337 25.1655 19.373 24.9037 19.0078C24.6532 18.6583 24.2559 18.4429 23.762 18.4429C22.8046 18.4429 22.1997 19.2288 22.1997 20.5435V20.5482C22.1997 21.8772 22.8093 22.6679 23.7667 22.6679C24.2931 22.6679 24.687 22.4292 24.9326 22.0518C25.1623 21.6988 25.4971 21.377 25.9182 21.377C26.4306 21.377 26.8451 21.8275 26.6523 22.3022C26.1929 23.4334 25.1032 24.1159 23.762 24.1159Z"
      fill="white"
    ></path>
    <path
      d="M16.2623 24.1159C14.176 24.1159 12.8708 22.7488 12.8708 20.5578V20.5482C12.8708 18.3619 14.1855 16.9949 16.2623 16.9949C18.3533 16.9949 19.6584 18.3619 19.6584 20.5482V20.5578C19.6584 22.7488 18.3581 24.1159 16.2623 24.1159ZM16.2623 22.6679C17.2625 22.6679 17.8817 21.8486 17.8817 20.5578V20.5482C17.8817 19.2479 17.2482 18.4429 16.2623 18.4429C15.2858 18.4429 14.6523 19.2431 14.6523 20.5482V20.5578C14.6523 21.8629 15.281 22.6679 16.2623 22.6679Z"
      fill="white"
    ></path>
    <path
      d="M7.36245 23.992C6.62607 23.992 6.02911 23.395 6.02911 22.6586V18.452C6.02911 17.7156 6.62607 17.1187 7.36245 17.1187H8.82035C10.8923 17.1187 12.1165 18.319 12.1165 20.5053V20.5148C12.1165 22.7107 10.8923 23.992 8.82035 23.992H7.36245ZM7.77721 22.582H8.59172C9.73012 22.582 10.3398 21.8914 10.3398 20.5196V20.5101C10.3398 19.224 9.69202 18.5286 8.59172 18.5286H7.77721V22.582Z"
      fill="white"
    ></path>
    <path
      d="M28 9.49999V9.33711C28 8.49982 27.6501 7.70062 27.0348 7.1327L21.2529 1.7955C20.7681 1.34807 20.1516 1.07469 19.5 1.01318V6.08194C19.5 7.96968 21.0035 9.49999 22.8581 9.49999H28Z"
      fill="#66A9FF"
    ></path>
  </svg>
);

export const EpubIcon: React.FC<React.SVGProps<SVGSVGElement>> = props => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M7.00006 1C5.34318 1 4.00003 2.34318 4.00006 4.00006L4.00054 27.0312C4.00056 28.3922 4.92364 29.6153 6.29132 29.9194C12.7721 31.3602 19.2285 31.3602 25.7092 29.9194C27.0769 29.6153 28 28.3921 28 27.031L27.9996 9.53976C27.9995 8.74033 27.6804 7.97396 27.1131 7.41075L21 2C20.438 1.44214 20.2108 1 19.4189 1H7.00006Z"
      fill="#FF9500"
    ></path>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.7084 20.9385C15.7084 22.1914 16.6597 23.015 18.1136 23.015C19.5637 23.015 20.5149 22.1914 20.5149 20.9385V17.992C20.5149 17.5202 20.2559 17.2534 19.8034 17.2534C19.3549 17.2534 19.0958 17.5202 19.0958 17.992V20.7877C19.0958 21.4374 18.7439 21.824 18.1136 21.824C17.4795 21.824 17.1276 21.4374 17.1276 20.7877V17.992C17.1276 17.5202 16.8685 17.2534 16.4199 17.2534C15.9714 17.2534 15.7084 17.5202 15.7084 17.992V20.9385ZM21.3608 22.1758C21.3608 22.6476 21.6237 22.9183 22.0723 22.9183H24.025C25.2199 22.9183 25.9739 22.288 25.9739 21.3096C25.9739 20.5672 25.3784 20.0065 24.6244 19.9717V19.9408C25.2431 19.8557 25.7149 19.3569 25.7149 18.7304C25.7149 17.8681 25.0536 17.3384 23.9554 17.3384H22.0723C21.6237 17.3384 21.3608 17.6091 21.3608 18.0808V22.1758ZM23.5108 19.6121H22.7799V18.3438H23.5804C24.056 18.3438 24.3344 18.5758 24.3344 18.9663C24.3344 19.3685 24.025 19.6121 23.5108 19.6121ZM23.6229 21.9129H22.7799V20.4744H23.6152C24.1991 20.4744 24.5316 20.7296 24.5316 21.1859C24.5316 21.6577 24.2068 21.9129 23.6229 21.9129ZM11.3619 23.0033C10.9133 23.0033 10.6504 22.7365 10.6504 22.2648V18.0808C10.6504 17.6091 10.9133 17.3384 11.3619 17.3384H13.0672C14.2659 17.3384 15.0779 18.1156 15.0779 19.3143C15.0779 20.5053 14.2234 21.2826 12.9821 21.2826H12.0695V22.2648C12.0695 22.7365 11.8104 23.0033 11.3619 23.0033ZM12.0695 20.2153H12.6844C13.2876 20.2153 13.6433 19.9021 13.6433 19.3182C13.6433 18.7382 13.2876 18.425 12.6921 18.425H12.0695V20.2153ZM6.02606 22.1758C6.02606 22.6476 6.28901 22.9183 6.73756 22.9183H9.29356C9.67638 22.9183 9.92386 22.7094 9.92386 22.346C9.92386 21.9825 9.66864 21.7737 9.29356 21.7737H7.4452V20.6368H9.20849C9.56037 20.6368 9.78078 20.4435 9.78078 20.1148C9.78078 19.7861 9.55264 19.5928 9.20849 19.5928H7.4452V18.483H9.29356C9.66864 18.483 9.92386 18.2742 9.92386 17.9107C9.92386 17.5472 9.67638 17.3384 9.29356 17.3384H6.73756C6.28901 17.3384 6.02606 17.6091 6.02606 18.0808V22.1758Z"
      fill="white"
    ></path>
    <path
      d="M28 9.49999V9.33711C28 8.49982 27.6501 7.70062 27.0348 7.1327L21.2529 1.7955C20.7681 1.34807 20.1516 1.07469 19.5 1.01318V6.08194C19.5 7.96968 21.0035 9.49999 22.8581 9.49999H28Z"
      fill="#FFDFB2"
    ></path>
  </svg>
);

export const EpubDarkIcon: React.FC<React.SVGProps<SVGSVGElement>> = props => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M7.00006 1C5.34318 1 4.00003 2.34318 4.00006 4.00006L4.00054 27.0312C4.00056 28.3922 4.92364 29.6153 6.29132 29.9194C12.7721 31.3602 19.2285 31.3602 25.7092 29.9194C27.0769 29.6153 28 28.3921 28 27.031L27.9996 9.53976C27.9995 8.74033 27.6804 7.97396 27.1131 7.41075L21.5324 1.87091C20.9705 1.31306 20.2108 1 19.4189 1H7.00006Z"
      fill="#E68600"
    ></path>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.7084 20.9385C15.7084 22.1914 16.6597 23.015 18.1136 23.015C19.5637 23.015 20.5149 22.1914 20.5149 20.9385V17.992C20.5149 17.5202 20.2559 17.2534 19.8034 17.2534C19.3549 17.2534 19.0958 17.5202 19.0958 17.992V20.7877C19.0958 21.4374 18.7439 21.824 18.1136 21.824C17.4795 21.824 17.1276 21.4374 17.1276 20.7877V17.992C17.1276 17.5202 16.8685 17.2534 16.4199 17.2534C15.9714 17.2534 15.7084 17.5202 15.7084 17.992V20.9385ZM21.3608 22.1758C21.3608 22.6476 21.6237 22.9183 22.0723 22.9183H24.025C25.2199 22.9183 25.9739 22.288 25.9739 21.3096C25.9739 20.5672 25.3784 20.0065 24.6244 19.9717V19.9408C25.2431 19.8557 25.7149 19.3569 25.7149 18.7304C25.7149 17.8681 25.0536 17.3384 23.9554 17.3384H22.0723C21.6237 17.3384 21.3608 17.6091 21.3608 18.0808V22.1758ZM23.5108 19.6121H22.7799V18.3438H23.5804C24.056 18.3438 24.3344 18.5758 24.3344 18.9663C24.3344 19.3685 24.025 19.6121 23.5108 19.6121ZM23.6229 21.9129H22.7799V20.4744H23.6152C24.1991 20.4744 24.5316 20.7296 24.5316 21.1859C24.5316 21.6577 24.2068 21.9129 23.6229 21.9129ZM11.3619 23.0033C10.9133 23.0033 10.6504 22.7365 10.6504 22.2648V18.0808C10.6504 17.6091 10.9133 17.3384 11.3619 17.3384H13.0672C14.2659 17.3384 15.0779 18.1156 15.0779 19.3143C15.0779 20.5053 14.2234 21.2826 12.9821 21.2826H12.0695V22.2648C12.0695 22.7365 11.8104 23.0033 11.3619 23.0033ZM12.0695 20.2153H12.6844C13.2876 20.2153 13.6433 19.9021 13.6433 19.3182C13.6433 18.7382 13.2876 18.425 12.6921 18.425H12.0695V20.2153ZM6.02606 22.1758C6.02606 22.6476 6.28901 22.9183 6.73756 22.9183H9.29356C9.67638 22.9183 9.92386 22.7094 9.92386 22.346C9.92386 21.9825 9.66864 21.7737 9.29356 21.7737H7.4452V20.6368H9.20849C9.56037 20.6368 9.78078 20.4435 9.78078 20.1148C9.78078 19.7861 9.55264 19.5928 9.20849 19.5928H7.4452V18.483H9.29356C9.66864 18.483 9.92386 18.2742 9.92386 17.9107C9.92386 17.5472 9.67638 17.3384 9.29356 17.3384H6.73756C6.28901 17.3384 6.02606 17.6091 6.02606 18.0808V22.1758Z"
      fill="white"
    ></path>
    <path
      d="M28 9.49999V9.33711C28 8.49982 27.6501 7.70062 27.0348 7.1327L21.2529 1.7955C20.7681 1.34807 20.1516 1.07469 19.5 1.01318V6.08194C19.5 7.96968 21.0035 9.49999 22.8581 9.49999H28Z"
      fill="#E6BB7F"
    ></path>
  </svg>
);

export const FolderIcon: React.FC<React.SVGProps<SVGSVGElement>> = props => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M2 25.0073C2 26.3299 2.87917 27.5371 4.23559 27.8323C6.32929 28.2879 10.4829 29 16 29C21.5171 29 25.6707 28.2879 27.7644 27.8323C29.1208 27.5371 30 26.3299 30 25.0073V10C30 8.34314 28.6569 7 27 7L14.8923 7C14.1884 7 13.5364 6.63005 13.1754 6.02586L12.2405 4.46121C11.699 3.55493 10.7209 3 9.66521 3H5C3.34315 3 2 4.34315 2 6V25.0073Z"
      fill="#3CC0FD"
    ></path>
    <path
      d="M30 9.5409C30 9.5409 25.5278 11 16 11C6.47222 11 2 9.5409 2 9.5409V5.78014C2 4.24471 3.21878 3 4.72222 3H9.99097C10.9424 3 11.8248 3.50731 12.3183 4.33805L13.3016 5.99312C13.662 6.59978 14.3154 6.97163 15.021 6.97163L27.2778 6.97163C28.7812 6.97163 30 8.21634 30 9.75177V9.5409Z"
      fill="#2B73FF"
    ></path>
    <path
      d="M4 10.0371C4 9.16901 4.70375 8.46526 5.57187 8.46527L26.4281 8.46533C27.2963 8.46533 28 9.16908 28 10.0372C28 10.0372 23.5096 10.9998 15.9999 10.9998C8.49029 10.9998 4 10.0371 4 10.0371Z"
      fill="white"
    ></path>
  </svg>
);

export const FolderDarkIcon: React.FC<React.SVGProps<SVGSVGElement>> = props => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M2 25.0073C2 26.3299 2.87917 27.5371 4.23559 27.8323C6.32929 28.2879 10.4829 29 16 29C21.5171 29 25.6707 28.2879 27.7644 27.8323C29.1208 27.5371 30 26.3299 30 25.0073V10C30 8.34314 28.6569 7 27 7L14.8923 7C14.1884 7 13.5364 6.63005 13.1754 6.02586L12.2405 4.46121C11.699 3.55493 10.7209 3 9.66521 3H5C3.34315 3 2 4.34315 2 6V25.0073Z"
      fill="#3CA9F0"
    ></path>
    <path
      d="M30 9.5409C30 9.5409 25.5278 11 16 11C6.47222 11 2 9.5409 2 9.5409V5.78014C2 4.24471 3.21878 3 4.72222 3H9.99097C10.9424 3 11.8248 3.50731 12.3183 4.33805L13.3016 5.99312C13.662 6.59978 14.3154 6.97163 15.021 6.97163L27.2778 6.97163C28.7812 6.97163 30 8.21634 30 9.75177V9.5409Z"
      fill="#3D72E9"
    ></path>
    <path
      d="M4.00003 10.0371C4.00003 9.16901 4.70378 8.46526 5.5719 8.46527L26.4282 8.46533C27.2963 8.46533 28 9.16908 28 10.0372C28 10.0372 23.5096 10.9998 16 10.9998C8.49032 10.9998 4.00003 10.0371 4.00003 10.0371Z"
      fill="white"
    ></path>
  </svg>
);

export const ImageIcon: React.FC<React.SVGProps<SVGSVGElement>> = props => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M16 3C20.6278 3 24.02 3.40542 26.1767 3.78329C27.8794 4.08162 29 5.59057 29 7.31921V24.6808C29 26.4094 27.8794 27.9183 26.1767 28.2167C24.02 28.5946 20.6278 29 16 29C11.3722 29 7.97999 28.5946 5.82333 28.2167C4.12063 27.9184 3 26.4094 3 24.6808V7.31922C3 5.5906 4.12061 4.08166 5.8233 3.78332C7.97996 3.40544 11.3722 3 16 3Z"
      fill="#66CFFF"
    ></path>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.66699 12.6667C11.3238 12.6667 12.667 11.3236 12.667 9.66675C12.667 8.00989 11.3238 6.66675 9.66699 6.66675C8.01014 6.66675 6.66699 8.00989 6.66699 9.66675C6.66699 11.3236 8.01014 12.6667 9.66699 12.6667ZM29 24.8348V16.1645C26.957 15.3736 22.4109 14.3389 14.699 16.1535C10.016 17.2553 6.03346 19.758 2.99869 22.2764V24.8348C2.99869 26.5634 4.11931 28.0724 5.82201 28.3707C7.97877 28.7486 11.3712 29.1541 15.9993 29.1541C20.6275 29.1541 24.0199 28.7486 26.1767 28.3707C27.8794 28.0724 29 26.5634 29 24.8348Z"
      fill="#2B73FF"
    ></path>
  </svg>
);

export const ImageDarkIcon: React.FC<React.SVGProps<SVGSVGElement>> = props => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M16 3C20.6278 3 24.02 3.40542 26.1767 3.78329C27.8794 4.08162 29 5.59057 29 7.31921V24.6808C29 26.4094 27.8794 27.9183 26.1767 28.2167C24.02 28.5946 20.6278 29 16 29C11.3722 29 7.97999 28.5946 5.82333 28.2167C4.12063 27.9184 3 26.4094 3 24.6808V7.31922C3 5.5906 4.12061 4.08166 5.8233 3.78332C7.97996 3.40544 11.3722 3 16 3Z"
      fill="#56C4FF"
    ></path>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.66699 12.6667C11.3238 12.6667 12.667 11.3236 12.667 9.66675C12.667 8.00989 11.3238 6.66675 9.66699 6.66675C8.01014 6.66675 6.66699 8.00989 6.66699 9.66675C6.66699 11.3236 8.01014 12.6667 9.66699 12.6667ZM29 24.8348V16.1645C26.957 15.3736 22.4109 14.3389 14.699 16.1535C10.016 17.2553 6.03346 19.758 2.99869 22.2764V24.8348C2.99869 26.5634 4.11931 28.0724 5.82201 28.3707C7.97877 28.7486 11.3712 29.1541 15.9993 29.1541C20.6275 29.1541 24.0199 28.7486 26.1767 28.3707C27.8794 28.0724 29 26.5634 29 24.8348Z"
      fill="#3D72E9"
    ></path>
  </svg>
);

export const MobiIcon: React.FC<React.SVGProps<SVGSVGElement>> = props => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M7 1C5.34312 1 3.99997 2.34318 4 4.00006L4.00048 27.0312C4.0005 28.3922 4.92358 29.6153 6.29126 29.9194C12.772 31.3602 19.2284 31.3602 25.7092 29.9194C27.0769 29.6153 28 28.3922 28 27.0311V9.3372C28 8.49991 27.65 7.70071 27.0348 7.13279L21.2528 1.79559C20.6987 1.28405 19.9722 1 19.218 1H7Z"
      fill="#FF4B40"
    ></path>
    <path
      d="M28 9.49999V9.33711C28 8.49982 27.6501 7.70062 27.0348 7.1327L21.2529 1.7955C20.7681 1.34807 20.1516 1.07469 19.5 1.01318V6.08194C19.5 7.96968 21.0035 9.49999 22.8581 9.49999H28Z"
      fill="#FF8B80"
    ></path>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.6437 22.9964C13.8845 22.9964 12.78 21.8477 12.78 20.0002C12.78 18.1527 13.8885 17 15.6437 17C17.3988 17 18.5033 18.1527 18.5033 20.0002C18.5033 21.8477 17.3988 22.9964 15.6437 22.9964ZM15.6437 21.7995C16.467 21.7995 17.0052 21.1007 17.0052 20.0002C17.0052 18.8957 16.467 18.1969 15.6437 18.1969C14.8203 18.1969 14.2821 18.8957 14.2821 20.0002C14.2821 21.1007 14.8163 21.7995 15.6437 21.7995ZM24.6373 22.2171C24.6373 22.7071 24.9104 22.9843 25.3763 22.9843C25.8422 22.9843 26.1113 22.7071 26.1113 22.2171V17.7791C26.1113 17.2891 25.8422 17.012 25.3763 17.012C24.9104 17.012 24.6373 17.2891 24.6373 17.7791V22.2171ZM19.2222 22.1248C19.2222 22.6148 19.4953 22.8959 19.9612 22.8959H21.9895C23.2305 22.8959 24.0137 22.2413 24.0137 21.2251C24.0137 20.454 23.3952 19.8716 22.612 19.8355V19.8033C23.2546 19.715 23.7446 19.1969 23.7446 18.5462C23.7446 17.6506 23.0578 17.1003 21.9172 17.1003H19.9612C19.4953 17.1003 19.2222 17.3815 19.2222 17.8715V22.1248ZM21.4553 19.4619H20.6962V18.1446H21.5276C22.0216 18.1446 22.3108 18.3856 22.3108 18.7912C22.3108 19.2089 21.9895 19.4619 21.4553 19.4619ZM21.5718 21.8517H20.6962V20.3576H21.5638C22.1702 20.3576 22.5156 20.6227 22.5156 21.0966C22.5156 21.5866 22.1783 21.8517 21.5718 21.8517ZM5.70465 22.3017C5.70465 22.7355 5.94161 22.9845 6.3553 22.9845C6.76496 22.9845 7.00193 22.7355 7.00193 22.3017V19.2774H7.03406L8.31929 22.4664C8.42371 22.7395 8.60846 22.864 8.88961 22.864C9.17075 22.864 9.34345 22.7395 9.45189 22.4664L10.7411 19.2774H10.7693V22.3017C10.7693 22.7355 11.0062 22.9845 11.4159 22.9845C11.8296 22.9845 12.0665 22.7355 12.0665 22.3017V17.9681C12.0665 17.3576 11.7211 17.0122 11.1147 17.0122C10.5966 17.0122 10.3194 17.2211 10.1066 17.7633L8.90166 20.8237H8.86953L7.66061 17.7633C7.45176 17.2211 7.17463 17.0122 6.66054 17.0122C6.05006 17.0122 5.70465 17.3616 5.70465 17.9681V22.3017Z"
      fill="white"
    ></path>
  </svg>
);

export const MobiDarkIcon: React.FC<React.SVGProps<SVGSVGElement>> = props => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M6.99994 1C5.34306 1 3.9999 2.34318 3.99994 4.00006L4.00041 27.0312C4.00044 28.3922 4.92352 29.6153 6.2912 29.9194C12.772 31.3602 19.2284 31.3602 25.7092 29.9194C27.0769 29.6153 28 28.3922 28 27.031L27.9996 10.6178C27.9995 9.81738 27.6796 9.05014 27.111 8.48677L20.4315 1.86887C19.8697 1.31227 19.1108 1 18.32 1H6.99994Z"
      fill="#EB3830"
    ></path>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.6437 22.9964C13.8845 22.9964 12.78 21.8477 12.78 20.0002C12.78 18.1527 13.8885 17 15.6437 17C17.3988 17 18.5033 18.1527 18.5033 20.0002C18.5033 21.8477 17.3988 22.9964 15.6437 22.9964ZM15.6437 21.7995C16.467 21.7995 17.0052 21.1007 17.0052 20.0002C17.0052 18.8957 16.467 18.1969 15.6437 18.1969C14.8203 18.1969 14.2821 18.8957 14.2821 20.0002C14.2821 21.1007 14.8163 21.7995 15.6437 21.7995ZM24.6373 22.2171C24.6373 22.7071 24.9104 22.9843 25.3763 22.9843C25.8422 22.9843 26.1113 22.7071 26.1113 22.2171V17.7791C26.1113 17.2891 25.8422 17.012 25.3763 17.012C24.9104 17.012 24.6373 17.2891 24.6373 17.7791V22.2171ZM19.2222 22.1248C19.2222 22.6148 19.4953 22.8959 19.9612 22.8959H21.9895C23.2305 22.8959 24.0137 22.2413 24.0137 21.2251C24.0137 20.454 23.3952 19.8716 22.612 19.8355V19.8033C23.2546 19.715 23.7446 19.1969 23.7446 18.5462C23.7446 17.6506 23.0578 17.1003 21.9172 17.1003H19.9612C19.4953 17.1003 19.2222 17.3815 19.2222 17.8715V22.1248ZM21.4553 19.4619H20.6962V18.1446H21.5276C22.0216 18.1446 22.3108 18.3856 22.3108 18.7912C22.3108 19.2089 21.9895 19.4619 21.4553 19.4619ZM21.5718 21.8517H20.6962V20.3576H21.5638C22.1702 20.3576 22.5156 20.6227 22.5156 21.0966C22.5156 21.5866 22.1783 21.8517 21.5718 21.8517ZM5.70465 22.3017C5.70465 22.7355 5.94161 22.9845 6.3553 22.9845C6.76496 22.9845 7.00193 22.7355 7.00193 22.3017V19.2774H7.03406L8.31929 22.4664C8.42371 22.7395 8.60846 22.864 8.88961 22.864C9.17075 22.864 9.34345 22.7395 9.45189 22.4664L10.7411 19.2774H10.7693V22.3017C10.7693 22.7355 11.0062 22.9845 11.4159 22.9845C11.8296 22.9845 12.0665 22.7355 12.0665 22.3017V17.9681C12.0665 17.3576 11.7211 17.0122 11.1147 17.0122C10.5966 17.0122 10.3194 17.2211 10.1066 17.7633L8.90166 20.8237H8.86953L7.66061 17.7633C7.45176 17.2211 7.17463 17.0122 6.66054 17.0122C6.05006 17.0122 5.70465 17.3616 5.70465 17.9681V22.3017Z"
      fill="white"
    ></path>
    <path
      d="M28 9.49999V9.33711C28 8.49982 27.6501 7.70062 27.0348 7.1327L21.2529 1.7955C20.7681 1.34807 20.1516 1.07469 19.5 1.01318V6.08194C19.5 7.96968 21.0035 9.49999 22.8581 9.49999H28Z"
      fill="#FF6F69"
    ></path>
  </svg>
);

export const OtherIcon: React.FC<React.SVGProps<SVGSVGElement>> = props => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M7.00006 1C5.34318 1 4.00003 2.34318 4.00006 4.00006L4.00054 27.0312C4.00056 28.3922 4.92364 29.6153 6.29132 29.9194C12.7721 31.3602 19.2285 31.3602 25.7092 29.9194C27.0769 29.6153 28 28.3921 28 27.031L27.9996 9.53976C27.9995 8.74033 27.6804 7.97396 27.1131 7.41075L21 2C20.438 1.44214 20.2108 1 19.4189 1H7.00006Z"
      fill="#3CC0FD"
    ></path>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.3333 20.1711C12.3333 21.0915 11.5871 21.8377 10.6667 21.8377C9.74619 21.8377 9 21.0915 9 20.1711C9 19.2506 9.74619 18.5044 10.6667 18.5044C11.5871 18.5044 12.3333 19.2506 12.3333 20.1711ZM17.6663 20.1711C17.6663 21.0915 16.9201 21.8377 15.9997 21.8377C15.0792 21.8377 14.333 21.0915 14.333 20.1711C14.333 19.2506 15.0792 18.5044 15.9997 18.5044C16.9201 18.5044 17.6663 19.2506 17.6663 20.1711ZM21.3337 21.8377C22.2541 21.8377 23.0003 21.0915 23.0003 20.1711C23.0003 19.2506 22.2541 18.5044 21.3337 18.5044C20.4132 18.5044 19.667 19.2506 19.667 20.1711C19.667 21.0915 20.4132 21.8377 21.3337 21.8377Z"
      fill="white"
    ></path>
    <path
      d="M28 9.49999V9.33711C28 8.49982 27.6501 7.70062 27.0348 7.1327L21.2529 1.7955C20.7681 1.34807 20.1516 1.07469 19.5 1.01318V6.08194C19.5 7.96968 21.0035 9.49999 22.8581 9.49999H28Z"
      fill="#62E6FF"
    ></path>
  </svg>
);

export const OtherDarkIcon: React.FC<React.SVGProps<SVGSVGElement>> = props => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M7.00006 1C5.34318 1 4.00003 2.34318 4.00006 4.00006L4.00054 27.0312C4.00056 28.3922 4.92364 29.6153 6.29132 29.9194C12.7721 31.3602 19.2285 31.3602 25.7092 29.9194C27.0769 29.6153 28 28.3921 28 27.031L27.9996 9.53976C27.9995 8.74033 27.6804 7.97396 27.1131 7.41075L21.5324 1.87091C20.9705 1.31306 20.2108 1 19.4189 1H7.00006Z"
      fill="#3CA9F0"
    ></path>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.3333 20.1711C12.3333 21.0915 11.5871 21.8377 10.6667 21.8377C9.74619 21.8377 9 21.0915 9 20.1711C9 19.2506 9.74619 18.5044 10.6667 18.5044C11.5871 18.5044 12.3333 19.2506 12.3333 20.1711ZM17.6663 20.1711C17.6663 21.0915 16.9201 21.8377 15.9997 21.8377C15.0792 21.8377 14.333 21.0915 14.333 20.1711C14.333 19.2506 15.0792 18.5044 15.9997 18.5044C16.9201 18.5044 17.6663 19.2506 17.6663 20.1711ZM21.3337 21.8377C22.2541 21.8377 23.0003 21.0915 23.0003 20.1711C23.0003 19.2506 22.2541 18.5044 21.3337 18.5044C20.4132 18.5044 19.667 19.2506 19.667 20.1711C19.667 21.0915 20.4132 21.8377 21.3337 21.8377Z"
      fill="white"
    ></path>
    <path
      d="M28 9.49999V9.33711C28 8.49982 27.6501 7.70062 27.0348 7.1327L21.2529 1.7955C20.7681 1.34807 20.1516 1.07469 19.5 1.01318V6.08194C19.5 7.96968 21.0035 9.49999 22.8581 9.49999H28Z"
      fill="#73CEFF"
    ></path>
  </svg>
);

export const PdfIcon: React.FC<React.SVGProps<SVGSVGElement>> = props => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M7.00003 1C5.34315 1 4 2.34318 4.00003 4.00006L4.00051 27.0312C4.00053 28.3922 4.92361 29.6153 6.29129 29.9194C12.772 31.3602 19.2284 31.3602 25.7092 29.9194C27.0769 29.6153 28 28.3922 28 27.0311V9.3372C28 8.49991 27.6501 7.70071 27.0348 7.13279L21.2529 1.79559C20.6987 1.28405 19.9722 1 19.218 1H7.00003Z"
      fill="#FF4B40"
    ></path>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.95247 21.8079V23.0501C7.95247 23.578 7.52458 24.0059 6.99674 24.0059C6.46891 24.0059 6.04102 23.578 6.04102 23.0501V17.446C6.04102 16.9181 6.46891 16.4902 6.99674 16.4902H7.3962H7.95247H9.2806C9.83963 16.4902 10.3257 16.6013 10.7389 16.8236C11.1521 17.0423 11.4716 17.3513 11.6973 17.7507C11.9264 18.1465 12.041 18.6135 12.041 19.1517V19.1621C12.041 19.6968 11.9264 20.1638 11.6973 20.5632C11.4716 20.959 11.1521 21.2663 10.7389 21.485C10.3257 21.7003 9.83963 21.8079 9.2806 21.8079H7.95247ZM7.95247 20.36H8.81706C9.22331 20.36 9.53928 20.2576 9.76497 20.0527C9.99414 19.8479 10.1087 19.551 10.1087 19.1621V19.1517C10.1087 18.7593 9.99414 18.4625 9.76497 18.2611C9.53928 18.0597 9.22331 17.959 8.81706 17.959H7.95247V20.36ZM14.7201 24.0059C13.9837 24.0059 13.3867 23.4089 13.3867 22.6725V17.8236C13.3867 17.0872 13.9837 16.4902 14.7201 16.4902H16.543C17.293 16.4902 17.9284 16.6343 18.4492 16.9225C18.9735 17.2072 19.3728 17.6239 19.6471 18.1725C19.9214 18.7211 20.0586 19.3861 20.0586 20.1673V20.1777C20.0586 20.9972 19.9232 21.6934 19.6523 22.2663C19.3815 22.8357 18.9839 23.268 18.4596 23.5632C17.9388 23.8583 17.2999 24.0059 16.543 24.0059H14.7201ZM15.2982 22.4694H16.1992C16.6124 22.4694 16.9596 22.3843 17.2409 22.2142C17.5256 22.0441 17.7409 21.7923 17.8867 21.459C18.036 21.1222 18.1107 20.7072 18.1107 20.2142V20.2038C18.1107 19.7385 18.0343 19.3444 17.8815 19.0215C17.7322 18.6951 17.5135 18.4486 17.2253 18.2819C16.9405 18.1118 16.5985 18.0267 16.1992 18.0267H15.2982V22.4694ZM21.9975 24.0059C21.4697 24.0059 21.0418 23.578 21.0418 23.0501V17.8236C21.0418 17.0872 21.6388 16.4902 22.3751 16.4902H25.3882C25.8124 16.4902 26.1564 16.8342 26.1564 17.2585C26.1564 17.6827 25.8124 18.0267 25.3882 18.0267H22.9533V19.7402H25.133C25.5371 19.7402 25.8647 20.0679 25.8647 20.472C25.8647 20.8762 25.5371 21.2038 25.133 21.2038H22.9533V23.0501C22.9533 23.578 22.5254 24.0059 21.9975 24.0059Z"
      fill="white"
    ></path>
    <path
      d="M28 9.49999V9.33711C28 8.49982 27.6501 7.70062 27.0348 7.1327L21.2529 1.7955C20.7681 1.34807 20.1516 1.07469 19.5 1.01318V6.08194C19.5 7.96968 21.0035 9.49999 22.8581 9.49999H28Z"
      fill="#FF8B80"
    ></path>
  </svg>
);

export const PdfDarkIcon: React.FC<React.SVGProps<SVGSVGElement>> = props => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M7.00003 1C5.34315 1 4 2.34318 4.00003 4.00006L4.00051 27.0312C4.00053 28.3922 4.92361 29.6153 6.29129 29.9194C12.772 31.3602 19.2284 31.3602 25.7092 29.9194C27.0769 29.6153 28 28.3922 28 27.0311V9.3372C28 8.49991 27.6501 7.70071 27.0348 7.13279L21.2529 1.79559C20.6987 1.28405 19.9722 1 19.218 1H7.00003Z"
      fill="#EB3830"
    ></path>
    <path
      d="M21.9975 24.0059C21.4697 24.0059 21.0418 23.578 21.0418 23.0501V17.8236C21.0418 17.0872 21.6388 16.4902 22.3751 16.4902H25.3882C25.8124 16.4902 26.1564 16.8342 26.1564 17.2585C26.1564 17.6827 25.8124 18.0267 25.3882 18.0267H22.9533V19.7402H25.133C25.5371 19.7402 25.8647 20.0679 25.8647 20.472C25.8647 20.8762 25.5371 21.2038 25.133 21.2038H22.9533V23.0501C22.9533 23.578 22.5254 24.0059 21.9975 24.0059Z"
      fill="white"
    ></path>
    <path
      d="M14.7201 24.0059C13.9837 24.0059 13.3867 23.4089 13.3867 22.6725V17.8236C13.3867 17.0872 13.9837 16.4902 14.7201 16.4902H16.543C17.293 16.4902 17.9284 16.6343 18.4492 16.9225C18.9735 17.2072 19.3728 17.6239 19.6471 18.1725C19.9214 18.7211 20.0586 19.3861 20.0586 20.1673V20.1777C20.0586 20.9972 19.9232 21.6934 19.6523 22.2663C19.3815 22.8357 18.9839 23.268 18.4596 23.5632C17.9388 23.8583 17.2999 24.0059 16.543 24.0059H14.7201ZM15.2982 22.4694H16.1992C16.6124 22.4694 16.9596 22.3843 17.2409 22.2142C17.5256 22.0441 17.7409 21.7923 17.8867 21.459C18.036 21.1222 18.1107 20.7072 18.1107 20.2142V20.2038C18.1107 19.7385 18.0343 19.3444 17.8815 19.0215C17.7322 18.6951 17.5135 18.4486 17.2253 18.2819C16.9405 18.1118 16.5985 18.0267 16.1992 18.0267H15.2982V22.4694Z"
      fill="white"
    ></path>
    <path
      d="M6.99935 21.8079V20.36H8.81706C9.22331 20.36 9.53928 20.2576 9.76497 20.0527C9.99414 19.8479 10.1087 19.551 10.1087 19.1621V19.1517C10.1087 18.7593 9.99414 18.4625 9.76497 18.2611C9.53928 18.0597 9.22331 17.959 8.81706 17.959H7.3962V16.4902H9.2806C9.83963 16.4902 10.3257 16.6013 10.7389 16.8236C11.1521 17.0423 11.4716 17.3513 11.6973 17.7507C11.9264 18.1465 12.041 18.6135 12.041 19.1517V19.1621C12.041 19.6968 11.9264 20.1638 11.6973 20.5632C11.4716 20.959 11.1521 21.2663 10.7389 21.485C10.3257 21.7003 9.83963 21.8079 9.2806 21.8079H6.99935ZM6.99674 24.0059C6.46891 24.0059 6.04102 23.578 6.04102 23.0501V17.446C6.04102 16.9181 6.46891 16.4902 6.99674 16.4902H7.95247V23.0501C7.95247 23.578 7.52458 24.0059 6.99674 24.0059Z"
      fill="white"
    ></path>
    <path
      d="M28 9.49999V9.33711C28 8.49982 27.6501 7.70062 27.0348 7.1327L21.2529 1.7955C20.7681 1.34807 20.1516 1.07469 19.5 1.01318V6.08194C19.5 7.96968 21.0035 9.49999 22.8581 9.49999H28Z"
      fill="#FF6F69"
    ></path>
  </svg>
);

export const ScanIcon: React.FC<React.SVGProps<SVGSVGElement>> = props => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M7.00006 1C5.34318 1 4.00003 2.34318 4.00006 4.00006L4.00054 27.0312C4.00056 28.3922 4.92364 29.6153 6.29132 29.9194C12.7721 31.3602 19.2285 31.3602 25.7092 29.9194C27.0769 29.6153 28 28.3921 28 27.031L27.9996 9.61862C27.9995 8.81747 27.6791 8.04961 27.1096 7.48613L21.4309 1.86744C20.8692 1.31171 20.111 1 19.3208 1H7.00006Z"
      fill="#3CC0FD"
    ></path>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.3334 14C10.1493 14 10.0001 14.1492 10.0001 14.3333L10 16.4751C9.99998 17.0273 9.55224 17.475 8.99996 17.475C8.44767 17.475 7.99998 17.0273 8 16.475L8.00009 14.3332C8.00014 13.0446 9.0448 12 10.3334 12H12.3951C12.9474 12 13.3951 12.4477 13.3951 13C13.3951 13.5523 12.9474 14 12.3951 14H10.3334ZM18.5052 13C18.5052 12.4477 18.9529 12 19.5052 12H21.6664C22.9551 12 23.9998 13.0447 23.9997 14.3334L23.9996 16.4751C23.9996 17.0273 23.5519 17.475 22.9996 17.475C22.4473 17.475 21.9996 17.0273 21.9996 16.475L21.9997 14.3333C21.9997 14.1492 21.8505 14 21.6664 14H19.5052C18.9529 14 18.5052 13.5523 18.5052 13ZM8.9999 21.4783C9.55218 21.4783 9.99994 21.9259 10 22.4782L10.0002 24.6667C10.0002 24.8508 10.1495 25 10.3336 25H12.3953C12.9476 25 13.3953 25.4477 13.3953 26C13.3953 26.5523 12.9476 27 12.3953 27H10.3336C9.04498 27 8.00035 25.9555 8.00022 24.6669L8 22.4784C7.99994 21.9261 8.44762 21.4784 8.9999 21.4783ZM22.9995 21.4783C23.5518 21.4783 23.9996 21.9259 23.9996 22.4782L23.9999 24.6664C24 25.9552 22.9553 27 21.6665 27H19.5054C18.9531 27 18.5054 26.5523 18.5054 26C18.5054 25.4477 18.9531 25 19.5054 25H21.6665C21.8506 25 21.9999 24.8507 21.9999 24.6666L21.9996 22.4784C21.9996 21.9261 22.4473 21.4784 22.9995 21.4783Z"
      fill="white"
    ></path>
    <path
      d="M28 9.49999V9.33711C28 8.49982 27.6501 7.70062 27.0348 7.1327L21.2529 1.7955C20.7681 1.34807 20.1516 1.07469 19.5 1.01318V6.08194C19.5 7.96968 21.0035 9.49999 22.8581 9.49999H28Z"
      fill="#62E6FF"
    ></path>
  </svg>
);

export const ScanDarkIcon: React.FC<React.SVGProps<SVGSVGElement>> = props => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M7.00006 1C5.34318 1 4.00003 2.34318 4.00006 4.00006L4.00054 27.0312C4.00056 28.3922 4.92364 29.6153 6.29132 29.9194C12.7721 31.3602 19.2285 31.3602 25.7092 29.9194C27.0769 29.6153 28 28.3921 28 27.031L27.9996 9.61862C27.9995 8.81747 27.6791 8.04961 27.1096 7.48613L21.4309 1.86744C20.8692 1.31171 20.111 1 19.3208 1H7.00006Z"
      fill="#3CA9F0"
    ></path>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.3334 14C10.1493 14 10.0001 14.1492 10.0001 14.3333L10 16.4751C9.99998 17.0273 9.55224 17.475 8.99996 17.475C8.44767 17.475 7.99998 17.0273 8 16.475L8.00009 14.3332C8.00014 13.0446 9.0448 12 10.3334 12H12.3951C12.9474 12 13.3951 12.4477 13.3951 13C13.3951 13.5523 12.9474 14 12.3951 14H10.3334ZM18.5052 13C18.5052 12.4477 18.9529 12 19.5052 12H21.6664C22.9551 12 23.9998 13.0447 23.9997 14.3334L23.9996 16.4751C23.9996 17.0273 23.5519 17.475 22.9996 17.475C22.4473 17.475 21.9996 17.0273 21.9996 16.475L21.9997 14.3333C21.9997 14.1492 21.8505 14 21.6664 14H19.5052C18.9529 14 18.5052 13.5523 18.5052 13ZM8.9999 21.4783C9.55218 21.4783 9.99994 21.9259 10 22.4782L10.0002 24.6667C10.0002 24.8508 10.1495 25 10.3336 25H12.3953C12.9476 25 13.3953 25.4477 13.3953 26C13.3953 26.5523 12.9476 27 12.3953 27H10.3336C9.04498 27 8.00035 25.9555 8.00022 24.6669L8 22.4784C7.99994 21.9261 8.44762 21.4784 8.9999 21.4783ZM22.9995 21.4783C23.5518 21.4783 23.9996 21.9259 23.9996 22.4782L23.9999 24.6664C24 25.9552 22.9553 27 21.6665 27H19.5054C18.9531 27 18.5054 26.5523 18.5054 26C18.5054 25.4477 18.9531 25 19.5054 25H21.6665C21.8506 25 21.9999 24.8507 21.9999 24.6666L21.9996 22.4784C21.9996 21.9261 22.4473 21.4784 22.9995 21.4783Z"
      fill="white"
    ></path>
    <path
      d="M28 9.49999V9.33711C28 8.49982 27.6501 7.70062 27.0348 7.1327L21.2529 1.7955C20.7681 1.34807 20.1516 1.07469 19.5 1.01318V6.08194C19.5 7.96968 21.0035 9.49999 22.8581 9.49999H28Z"
      fill="#73CEFF"
    ></path>
  </svg>
);

export const Shared_folderIcon: React.FC<React.SVGProps<SVGSVGElement>> = props => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M2 25.0073C2 26.3299 2.87917 27.5371 4.23559 27.8323C6.32929 28.2879 10.4829 29 16 29C21.5171 29 25.6707 28.2879 27.7644 27.8323C29.1208 27.5371 30 26.3299 30 25.0073V10C30 8.34314 28.6569 7 27 7L14.8923 7C14.1884 7 13.5364 6.63005 13.1754 6.02586L12.2405 4.46121C11.699 3.55493 10.7209 3 9.66521 3H5C3.34315 3 2 4.34315 2 6V25.0073Z"
      fill="#3CC0FD"
    ></path>
    <path
      d="M30 9.5409C30 9.5409 25.5278 11 16 11C6.47222 11 2 9.5409 2 9.5409V5.78014C2 4.24471 3.21878 3 4.72222 3H9.99097C10.9424 3 11.8248 3.50731 12.3183 4.33805L13.3016 5.99312C13.662 6.59978 14.3154 6.97163 15.021 6.97163L27.2778 6.97163C28.7812 6.97163 30 8.21634 30 9.75177V9.5409Z"
      fill="#2B73FF"
    ></path>
    <path
      d="M12.8756 14C11.771 14 10.8756 14.8954 10.8756 16C10.8756 17.1046 11.771 18 12.8756 18C13.9801 18 14.8756 17.1046 14.8756 16C14.8756 14.8954 13.9801 14 12.8756 14Z"
      fill="white"
    ></path>
    <path
      d="M19.3756 16C18.5471 16 17.8756 16.6716 17.8756 17.5C17.8756 18.3284 18.5471 19 19.3756 19C20.204 19 20.8756 18.3284 20.8756 17.5C20.8756 16.6716 20.204 16 19.3756 16Z"
      fill="white"
    ></path>
    <path
      d="M12.8756 19.5C10.1389 19.5 8.52569 20.9965 8.0358 22.5172C7.86645 23.0428 8.32313 23.5 8.87542 23.5H16.8756C17.4278 23.5 17.8847 23.0428 17.7153 22.5172C17.2255 20.9965 15.6122 19.5 12.8756 19.5Z"
      fill="white"
    ></path>
    <path
      d="M18.7887 23.5H22.8755C23.4278 23.5 23.8836 23.0354 23.6557 22.5323C23.177 21.4759 21.917 20.5 19.8756 20.5C19.2242 20.5 18.6523 20.5994 18.16 20.769C18.4688 21.2019 18.7027 21.6686 18.8575 22.1492C19.0126 22.6304 18.9665 23.1011 18.7887 23.5Z"
      fill="white"
    ></path>
    <path
      d="M4 10.0371C4 9.16901 4.70375 8.46526 5.57187 8.46527L26.4281 8.46533C27.2963 8.46533 28 9.16908 28 10.0372C28 10.0372 23.5096 10.9998 15.9999 10.9998C8.49029 10.9998 4 10.0371 4 10.0371Z"
      fill="white"
    ></path>
  </svg>
);

export const Shared_folderDarkIcon: React.FC<React.SVGProps<SVGSVGElement>> = props => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M2 25.0073C2 26.3299 2.87917 27.5371 4.23559 27.8323C6.32929 28.2879 10.4829 29 16 29C21.5171 29 25.6707 28.2879 27.7644 27.8323C29.1208 27.5371 30 26.3299 30 25.0073V10C30 8.34314 28.6569 7 27 7L14.8923 7C14.1884 7 13.5364 6.63005 13.1754 6.02586L12.2405 4.46121C11.699 3.55493 10.7209 3 9.66521 3H5C3.34315 3 2 4.34315 2 6V25.0073Z"
      fill="#3CA9F0"
    ></path>
    <path
      d="M30 9.5409C30 9.5409 25.5278 11 16 11C6.47222 11 2 9.5409 2 9.5409V5.78014C2 4.24471 3.21878 3 4.72222 3H9.99097C10.9424 3 11.8248 3.50731 12.3183 4.33805L13.3016 5.99312C13.662 6.59978 14.3154 6.97163 15.021 6.97163L27.2778 6.97163C28.7812 6.97163 30 8.21634 30 9.75177V9.5409Z"
      fill="#3D72E9"
    ></path>
    <path
      d="M12.8756 14C11.771 14 10.8756 14.8954 10.8756 16C10.8756 17.1046 11.771 18 12.8756 18C13.9801 18 14.8756 17.1046 14.8756 16C14.8756 14.8954 13.9801 14 12.8756 14Z"
      fill="white"
    ></path>
    <path
      d="M19.3756 16C18.5471 16 17.8756 16.6716 17.8756 17.5C17.8756 18.3284 18.5471 19 19.3756 19C20.204 19 20.8756 18.3284 20.8756 17.5C20.8756 16.6716 20.204 16 19.3756 16Z"
      fill="white"
    ></path>
    <path
      d="M12.8756 19.5C10.1389 19.5 8.52569 20.9965 8.0358 22.5172C7.86645 23.0428 8.32313 23.5 8.87542 23.5H16.8756C17.4278 23.5 17.8847 23.0428 17.7153 22.5172C17.2255 20.9965 15.6122 19.5 12.8756 19.5Z"
      fill="white"
    ></path>
    <path
      d="M18.7887 23.5H22.8755C23.4278 23.5 23.8836 23.0354 23.6557 22.5323C23.177 21.4759 21.917 20.5 19.8756 20.5C19.2242 20.5 18.6523 20.5994 18.16 20.769C18.4688 21.2019 18.7027 21.6686 18.8575 22.1492C19.0126 22.6304 18.9665 23.1011 18.7887 23.5Z"
      fill="white"
    ></path>
    <path
      d="M4 10.0371C4 9.16901 4.70375 8.46526 5.57187 8.46527L26.4281 8.46533C27.2963 8.46533 28 9.16908 28 10.0372C28 10.0372 23.5096 10.9998 15.9999 10.9998C8.49029 10.9998 4 10.0371 4 10.0371Z"
      fill="white"
    ></path>
  </svg>
);

export const SlidesIcon: React.FC<React.SVGProps<SVGSVGElement>> = props => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M7.00006 1C5.34318 1 4.00003 2.34318 4.00006 4.00006L4.00054 27.0312C4.00056 28.3922 4.92364 29.6153 6.29132 29.9194C12.7721 31.3602 19.2285 31.3602 25.7092 29.9194C27.0769 29.6153 28 28.3921 28 27.031L27.9996 9.61862C27.9995 8.81747 27.6791 8.04961 27.1096 7.48613L21 2C20.4383 1.44427 20.111 1 19.3208 1H7.00006Z"
      fill="#FFC044"
    ></path>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21 15H11L11 24H21V15ZM11 13C9.89543 13 9 13.8954 9 15V24C9 25.1046 9.89543 26 11 26H21C22.1046 26 23 25.1046 23 24V15C23 13.8954 22.1046 13 21 13H11Z"
      fill="white"
    ></path>
    <path
      d="M28 9.49999V9.33711C28 8.49982 27.6501 7.70062 27.0348 7.1327L21.2529 1.7955C20.7681 1.34807 20.1516 1.07469 19.5 1.01318V6.08194C19.5 7.96968 21.0035 9.49999 22.8581 9.49999H28Z"
      fill="#FFE66A"
    ></path>
  </svg>
);

export const SlidesDarkIcon: React.FC<React.SVGProps<SVGSVGElement>> = props => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M7.00006 1C5.34318 1 4.00003 2.34318 4.00006 4.00006L4.00054 27.0312C4.00056 28.3922 4.92364 29.6153 6.29132 29.9194C12.7721 31.3602 19.2285 31.3602 25.7092 29.9194C27.0769 29.6153 28 28.3921 28 27.031L27.9996 9.61862C27.9995 8.81747 27.6791 8.04961 27.1096 7.48613L21.4309 1.86744C20.8692 1.31171 20.111 1 19.3208 1H7.00006Z"
      fill="#F0BA4A"
    ></path>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21 15H11L11 24H21V15ZM11 13C9.89543 13 9 13.8954 9 15V24C9 25.1046 9.89543 26 11 26H21C22.1046 26 23 25.1046 23 24V15C23 13.8954 22.1046 13 21 13H11Z"
      fill="white"
    ></path>
    <path
      d="M28 9.49999V9.33711C28 8.49982 27.6501 7.70062 27.0348 7.1327L21.2529 1.7955C20.7681 1.34807 20.1516 1.07469 19.5 1.01318V6.08194C19.5 7.96968 21.0035 9.49999 22.8581 9.49999H28Z"
      fill="#FFE5A4"
    ></path>
  </svg>
);

export const TxtIcon: React.FC<React.SVGProps<SVGSVGElement>> = props => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M7.00006 1C5.34318 1 4.00003 2.34318 4.00006 4.00006L4.00054 27.0312C4.00056 28.3922 4.92364 29.6153 6.29132 29.9194C12.7721 31.3602 19.2285 31.3602 25.7092 29.9194C27.0769 29.6153 28 28.3921 28 27.031L27.9996 9.5484C27.9995 8.74132 27.6743 7.96829 27.0974 7.40392L21.4253 1.85545C20.8647 1.30707 20.1117 1 19.3275 1H7.00006Z"
      fill="#6876FF"
    ></path>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.88406 23.0477C7.88406 23.5737 8.31046 24.0001 8.83645 24.0001C9.36244 24.0001 9.78884 23.5737 9.78884 23.0477V18.0255H11.0925C11.5165 18.0255 11.8602 17.6818 11.8602 17.2579C11.8602 16.8339 11.5165 16.4902 11.0925 16.4902H6.58038C6.15643 16.4902 5.81274 16.8339 5.81274 17.2579C5.81274 17.6818 6.15643 18.0255 6.58038 18.0255H7.88406V23.0477ZM13.9624 24.0001C13.2878 24.0001 12.8729 23.2621 13.2235 22.6857L14.7191 20.2269V20.1957L13.3083 17.9128C12.9244 17.2916 13.3712 16.4902 14.1015 16.4902C14.4436 16.4902 14.7582 16.6776 14.9212 16.9784L16.0358 19.0351H16.0931L17.2147 16.9497C17.3669 16.6667 17.6622 16.4902 17.9835 16.4902C18.6736 16.4902 19.0908 17.2533 18.7183 17.8343L17.1912 20.2165V20.2425L18.688 22.6178C19.0664 23.2181 18.6349 24.0001 17.9252 24.0001C17.6086 24.0001 17.3152 23.834 17.1523 23.5626L15.9525 21.5644H15.9057L14.7053 23.5781C14.5493 23.8398 14.2671 24.0001 13.9624 24.0001ZM23.164 24.0001C22.638 24.0001 22.2116 23.5737 22.2116 23.0477V18.0255H20.9079C20.4839 18.0255 20.1403 17.6818 20.1403 17.2579C20.1403 16.8339 20.4839 16.4902 20.9079 16.4902H25.42C25.844 16.4902 26.1877 16.8339 26.1877 17.2579C26.1877 17.6818 25.844 18.0255 25.42 18.0255H24.1164V23.0477C24.1164 23.5737 23.69 24.0001 23.164 24.0001Z"
      fill="white"
    ></path>
    <path
      d="M28 9.49999V9.33711C28 8.49982 27.6501 7.70062 27.0348 7.1327L21.2529 1.7955C20.7681 1.34807 20.1516 1.07469 19.5 1.01318V6.08194C19.5 7.96968 21.0035 9.49999 22.8581 9.49999H28Z"
      fill="#98A1FF"
    ></path>
  </svg>
);

export const TxtDarkIcon: React.FC<React.SVGProps<SVGSVGElement>> = props => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M7.00006 1C5.34318 1 4.00003 2.34318 4.00006 4.00006L4.00054 27.0312C4.00056 28.3922 4.92364 29.6153 6.29132 29.9194C12.7721 31.3602 19.2285 31.3602 25.7092 29.9194C27.0769 29.6153 28 28.3921 28 27.031L27.9996 9.5484C27.9995 8.74132 27.6743 7.96829 27.0974 7.40392L21.4253 1.85545C20.8647 1.30707 20.1117 1 19.3275 1H7.00006Z"
      fill="#7E85FF"
    ></path>
    <path
      d="M23.1639 24.0001C22.6379 24.0001 22.2115 23.5737 22.2115 23.0477V18.0255H20.9079C20.4839 18.0255 20.1402 17.6818 20.1402 17.2579C20.1402 16.8339 20.4839 16.4902 20.9079 16.4902H25.42C25.844 16.4902 26.1876 16.8339 26.1876 17.2579C26.1876 17.6818 25.844 18.0255 25.42 18.0255H24.1163V23.0477C24.1163 23.5737 23.6899 24.0001 23.1639 24.0001Z"
      fill="white"
    ></path>
    <path
      d="M13.9624 24.0001C13.2878 24.0001 12.8729 23.2621 13.2235 22.6857L14.7191 20.2269V20.1957L13.3083 17.9128C12.9244 17.2916 13.3712 16.4902 14.1014 16.4902C14.4435 16.4902 14.7582 16.6776 14.9212 16.9784L16.0358 19.0351H16.093L17.2147 16.9497C17.3668 16.6667 17.6621 16.4902 17.9834 16.4902C18.6736 16.4902 19.0908 17.2533 18.7183 17.8343L17.1911 20.2165V20.2425L18.688 22.6178C19.0663 23.2181 18.6349 24.0001 17.9252 24.0001C17.6086 24.0001 17.3152 23.834 17.1522 23.5626L15.9525 21.5644H15.9057L14.7052 23.5781C14.5492 23.8398 14.267 24.0001 13.9624 24.0001Z"
      fill="white"
    ></path>
    <path
      d="M8.83642 24.0001C8.31043 24.0001 7.88403 23.5737 7.88403 23.0477V18.0255H6.58035C6.1564 18.0255 5.81271 17.6818 5.81271 17.2579C5.81271 16.8339 6.1564 16.4902 6.58035 16.4902H11.0925C11.5164 16.4902 11.8601 16.8339 11.8601 17.2579C11.8601 17.6818 11.5164 18.0255 11.0925 18.0255H9.78881V23.0477C9.78881 23.5737 9.36241 24.0001 8.83642 24.0001Z"
      fill="white"
    ></path>
    <path
      d="M28 9.49999V9.33711C28 8.49982 27.6501 7.70062 27.0348 7.1327L21.2529 1.7955C20.7681 1.34807 20.1516 1.07469 19.5 1.01318V6.08194C19.5 7.96968 21.0035 9.49999 22.8581 9.49999H28Z"
      fill="#B1AFFF"
    ></path>
  </svg>
);

export const VideoIcon: React.FC<React.SVGProps<SVGSVGElement>> = props => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M16 3C20.6674 3 24.078 3.43409 26.2318 3.83472C27.9088 4.14667 29 5.64119 29 7.347V24.653C29 26.3588 27.9088 27.8533 26.2318 28.1652C24.078 28.5659 20.6674 29 16 29C11.3326 29 7.92201 28.5659 5.76822 28.1653C4.09118 27.8533 3 26.3588 3 24.653V7.34702C3 5.64123 4.09116 4.14671 5.76819 3.83475C7.92197 3.43411 11.3326 3 16 3Z"
      fill="#6876FF"
    ></path>
    <rect x="6" y="7" width="3" height="3" rx="0.8" fill="white"></rect>
    <rect x="23" y="7" width="3" height="3" rx="0.8" fill="white"></rect>
    <rect x="6" y="12" width="3" height="3" rx="0.8" fill="white"></rect>
    <rect x="23" y="12" width="3" height="3" rx="0.8" fill="white"></rect>
    <rect x="6" y="17" width="3" height="3" rx="0.8" fill="white"></rect>
    <rect x="23" y="17" width="3" height="3" rx="0.8" fill="white"></rect>
    <rect x="6" y="22" width="3" height="3" rx="0.8" fill="white"></rect>
    <rect x="23" y="22" width="3" height="3" rx="0.8" fill="white"></rect>
  </svg>
);

export const VideoDarkIcon: React.FC<React.SVGProps<SVGSVGElement>> = props => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 3C20.6674 3 24.078 3.43409 26.2318 3.83472C27.9088 4.14667 29 5.64119 29 7.347V24.653C29 26.3588 27.9088 27.8533 26.2318 28.1652C24.078 28.5659 20.6674 29 16 29C11.3326 29 7.92201 28.5659 5.76822 28.1653C4.09118 27.8533 3 26.3588 3 24.653V7.34702C3 5.64123 4.09116 4.14671 5.76819 3.83475C7.92197 3.43411 11.3326 3 16 3ZM6 7.8C6 7.35817 6.35817 7 6.8 7H8.2C8.64183 7 9 7.35817 9 7.8V9.2C9 9.64183 8.64183 10 8.2 10H6.8C6.35817 10 6 9.64183 6 9.2V7.8ZM23.8 7C23.3582 7 23 7.35817 23 7.8V9.2C23 9.64183 23.3582 10 23.8 10H25.2C25.6418 10 26 9.64183 26 9.2V7.8C26 7.35817 25.6418 7 25.2 7H23.8ZM6 12.8C6 12.3582 6.35817 12 6.8 12H8.2C8.64183 12 9 12.3582 9 12.8V14.2C9 14.6418 8.64183 15 8.2 15H6.8C6.35817 15 6 14.6418 6 14.2V12.8ZM23.8 12C23.3582 12 23 12.3582 23 12.8V14.2C23 14.6418 23.3582 15 23.8 15H25.2C25.6418 15 26 14.6418 26 14.2V12.8C26 12.3582 25.6418 12 25.2 12H23.8ZM6 17.8C6 17.3582 6.35817 17 6.8 17H8.2C8.64183 17 9 17.3582 9 17.8V19.2C9 19.6418 8.64183 20 8.2 20H6.8C6.35817 20 6 19.6418 6 19.2V17.8ZM23.8 17C23.3582 17 23 17.3582 23 17.8V19.2C23 19.6418 23.3582 20 23.8 20H25.2C25.6418 20 26 19.6418 26 19.2V17.8C26 17.3582 25.6418 17 25.2 17H23.8ZM6 22.8C6 22.3582 6.35817 22 6.8 22H8.2C8.64183 22 9 22.3582 9 22.8V24.2C9 24.6418 8.64183 25 8.2 25H6.8C6.35817 25 6 24.6418 6 24.2V22.8ZM23.8 22C23.3582 22 23 22.3582 23 22.8V24.2C23 24.6418 23.3582 25 23.8 25H25.2C25.6418 25 26 24.6418 26 24.2V22.8C26 22.3582 25.6418 22 25.2 22H23.8Z"
      fill="#7E85FF"
    ></path>
  </svg>
);

export const WebIcon: React.FC<React.SVGProps<SVGSVGElement>> = props => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M7.00006 1C5.34318 1 4.00003 2.34318 4.00006 4.00006L4.00054 27.0312C4.00056 28.3922 4.92364 29.6153 6.29132 29.9194C12.7721 31.3602 19.2285 31.3602 25.7092 29.9194C27.0769 29.6153 28 28.3921 28 27.031L27.9996 9.5484C27.9995 8.74132 27.6743 7.96829 27.0974 7.40392L21.4253 1.85545C20.8647 1.30707 20.1117 1 19.3275 1H7.00006Z"
      fill="#3CC0FD"
    ></path>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.07089 18H12.0062C12.0556 16.0266 12.3958 14.2112 12.9502 12.8253C12.9716 12.7719 12.9934 12.7189 13.0156 12.6663C10.9302 13.6506 9.40924 15.6346 9.07089 18ZM9.07089 20C9.44601 22.6225 11.2747 24.7761 13.7147 25.6185C13.4159 25.1978 13.1624 24.7053 12.9502 24.1747C12.4909 23.0264 12.1786 21.5835 12.0569 20H9.07089ZM18.2853 25.6185C20.7253 24.7761 22.554 22.6225 22.9291 20H19.9431C19.8214 21.5835 19.5091 23.0264 19.0498 24.1747C18.8376 24.7053 18.5841 25.1978 18.2853 25.6185ZM22.9291 18C22.5908 15.6346 21.0698 13.6506 18.9844 12.6663C19.0067 12.7189 19.0284 12.7719 19.0498 12.8253C19.6042 14.2112 19.9444 16.0266 19.9938 18H22.9291ZM16 12C15.9494 12 15.7999 12.0215 15.5588 12.2765C15.3157 12.5336 15.0507 12.9592 14.8072 13.5681C14.3636 14.677 14.0561 16.2303 14.0069 18H17.9931C17.9439 16.2303 17.6364 14.677 17.1928 13.5681C16.9493 12.9592 16.6843 12.5336 16.4412 12.2765C16.2001 12.0215 16.0507 12 16 12ZM17.1928 23.4319C17.5507 22.5373 17.82 21.3534 17.9365 20H14.0635C14.18 21.3534 14.4493 22.5373 14.8072 23.4319C15.0507 24.0408 15.3157 24.4664 15.5588 24.7235C15.7999 24.9785 15.9494 25 16 25C16.0507 25 16.2001 24.9785 16.4412 24.7235C16.6843 24.4664 16.9493 24.0408 17.1928 23.4319Z"
      fill="white"
    ></path>
    <path
      d="M28 9.49999V9.33711C28 8.49982 27.6501 7.70062 27.0348 7.1327L21.2529 1.7955C20.7681 1.34807 20.1516 1.07469 19.5 1.01318V6.08194C19.5 7.96968 21.0035 9.49999 22.8581 9.49999H28Z"
      fill="#62E6FF"
    ></path>
  </svg>
);

export const WebDarkIcon: React.FC<React.SVGProps<SVGSVGElement>> = props => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M7.00006 1C5.34318 1 4.00003 2.34318 4.00006 4.00006L4.00054 27.0312C4.00056 28.3922 4.92364 29.6153 6.29132 29.9194C12.7721 31.3602 19.2285 31.3602 25.7092 29.9194C27.0769 29.6153 28 28.3921 28 27.031L27.9996 9.5484C27.9995 8.74132 27.6743 7.96829 27.0974 7.40392L21.4253 1.85545C20.8647 1.30707 20.1117 1 19.3275 1H7.00006Z"
      fill="#3CA9F0"
    ></path>
    <path
      d="M9.07089 18H12.0062C12.0556 16.0266 12.3958 14.2112 12.9502 12.8253C12.9716 12.7719 12.9934 12.7189 13.0156 12.6663C10.9302 13.6506 9.40924 15.6346 9.07089 18Z"
      fill="white"
    ></path>
    <path
      d="M9.07089 20C9.44601 22.6225 11.2747 24.7761 13.7147 25.6185C13.4159 25.1978 13.1624 24.7053 12.9502 24.1747C12.4909 23.0264 12.1786 21.5835 12.0569 20H9.07089Z"
      fill="white"
    ></path>
    <path
      d="M18.2853 25.6185C20.7253 24.7761 22.554 22.6225 22.9291 20H19.9431C19.8214 21.5835 19.5091 23.0264 19.0498 24.1747C18.8376 24.7053 18.5841 25.1978 18.2853 25.6185Z"
      fill="white"
    ></path>
    <path
      d="M22.9291 18C22.5908 15.6346 21.0698 13.6506 18.9844 12.6663C19.0067 12.7189 19.0284 12.7719 19.0498 12.8253C19.6042 14.2112 19.9444 16.0266 19.9938 18H22.9291Z"
      fill="white"
    ></path>
    <path
      d="M16 12C15.9494 12 15.7999 12.0215 15.5588 12.2765C15.3157 12.5336 15.0507 12.9592 14.8072 13.5681C14.3636 14.677 14.0561 16.2303 14.0069 18H17.9931C17.9439 16.2303 17.6364 14.677 17.1928 13.5681C16.9493 12.9592 16.6843 12.5336 16.4412 12.2765C16.2001 12.0215 16.0507 12 16 12Z"
      fill="white"
    ></path>
    <path
      d="M17.1928 23.4319C17.5507 22.5373 17.82 21.3534 17.9365 20H14.0635C14.18 21.3534 14.4493 22.5373 14.8072 23.4319C15.0507 24.0408 15.3157 24.4664 15.5588 24.7235C15.7999 24.9785 15.9494 25 16 25C16.0507 25 16.2001 24.9785 16.4412 24.7235C16.6843 24.4664 16.9493 24.0408 17.1928 23.4319Z"
      fill="white"
    ></path>
    <path
      d="M28 9.49999V9.33711C28 8.49982 27.6501 7.70062 27.0348 7.1327L21.2529 1.7955C20.7681 1.34807 20.1516 1.07469 19.5 1.01318V6.08194C19.5 7.96968 21.0035 9.49999 22.8581 9.49999H28Z"
      fill="#73CEFF"
    ></path>
  </svg>
);

export const XlsIcon: React.FC<React.SVGProps<SVGSVGElement>> = props => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M7.00006 1C5.34318 1 4.00003 2.34318 4.00006 4.00006L4.00054 27.0312C4.00056 28.3922 4.92364 29.6153 6.29132 29.9194C12.7721 31.3602 19.2285 31.3602 25.7092 29.9194C27.0769 29.6153 28 28.3921 28 27.031L27.9996 9.5484C27.9995 8.74132 27.6743 7.96829 27.0974 7.40392L21.4253 1.85545C20.8647 1.30707 20.1117 1 19.3275 1H7.00006Z"
      fill="#26E2A8"
    ></path>
    <path
      d="M28 9.49999V9.33711C28 8.49982 27.6501 7.70062 27.0348 7.1327L21.2529 1.7955C20.7681 1.34807 20.1516 1.07469 19.5 1.01318V6.08194C19.5 7.96968 21.0035 9.49999 22.8581 9.49999H28Z"
      fill="#BAF5E4"
    ></path>
    <path d="M17 26H21.5C22.3284 26 23 25.3284 23 24.5V23H17V26Z" fill="white"></path>
    <path d="M23 21V18H17V21H23Z" fill="white"></path>
    <path d="M23 16V14.5C23 13.6716 22.3284 13 21.5 13H17V16H23Z" fill="white"></path>
    <path d="M15 13H10.5C9.67157 13 9 13.6716 9 14.5V16H15V13Z" fill="white"></path>
    <path d="M9 18V21H15V18H9Z" fill="white"></path>
    <path d="M9 23H15V26H10.5C9.67157 26 9 25.3284 9 24.5V23Z" fill="white"></path>
  </svg>
);

export const XlsDarkIcon: React.FC<React.SVGProps<SVGSVGElement>> = props => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M7.00006 1C5.34318 1 4.00003 2.34318 4.00006 4.00006L4.00054 27.0312C4.00056 28.3922 4.92364 29.6153 6.29132 29.9194C12.7721 31.3602 19.2285 31.3602 25.7092 29.9194C27.0769 29.6153 28 28.3921 28 27.031L27.9996 9.5484C27.9995 8.74132 27.6743 7.96829 27.0974 7.40392L21.4253 1.85545C20.8647 1.30707 20.1117 1 19.3275 1H7.00006Z"
      fill="#3AC6A7"
    ></path>
    <path
      d="M28 9.49999V9.33711C28 8.49982 27.6501 7.70062 27.0348 7.1327L21.2529 1.7955C20.7681 1.34807 20.1516 1.07469 19.5 1.01318V6.08194C19.5 7.96968 21.0035 9.49999 22.8581 9.49999H28Z"
      fill="#5BEBCE"
    ></path>
    <path d="M17 26H21.5C22.3284 26 23 25.3284 23 24.5V23H17V26Z" fill="white"></path>
    <path d="M23 21V18H17V21H23Z" fill="white"></path>
    <path d="M23 16V14.5C23 13.6716 22.3284 13 21.5 13H17V16H23Z" fill="white"></path>
    <path d="M15 13H10.5C9.67157 13 9 13.6716 9 14.5V16H15V13Z" fill="white"></path>
    <path d="M9 18V21H15V18H9Z" fill="white"></path>
    <path d="M9 23H15V26H10.5C9.67157 26 9 25.3284 9 24.5V23Z" fill="white"></path>
  </svg>
);

export const ZipIcon: React.FC<React.SVGProps<SVGSVGElement>> = props => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M7.00006 1C5.34318 1 4.00003 2.34318 4.00006 4.00006L4.00054 27.0312C4.00056 28.3922 4.92364 29.6153 6.29132 29.9194C12.7721 31.3602 19.2285 31.3602 25.7092 29.9194C27.0769 29.6153 28 28.3922 28 27.0311V9.3372C28 8.49991 27.6501 7.70071 27.0349 7.13279L21.2529 1.79559C20.6987 1.28405 19.9722 1 19.2181 1H7.00006Z"
      fill="#FFC044"
    ></path>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.19373 23.0797C8.19373 23.5803 8.59955 23.9861 9.10017 23.9861H13.3442C13.7674 23.9861 14.1105 23.6431 14.1105 23.2199C14.1105 22.7968 13.7674 22.4537 13.3442 22.4537H10.443V22.4174L13.8136 17.9429C13.9321 17.7857 13.9962 17.5941 13.9962 17.3972C13.9962 16.8963 13.5901 16.4902 13.0892 16.4902H9.01708C8.59391 16.4902 8.25087 16.8333 8.25087 17.2564C8.25087 17.6796 8.59391 18.0227 9.01708 18.0227H11.7573V18.059L8.37693 22.5333C8.25805 22.6907 8.19373 22.8825 8.19373 23.0797ZM16.5074 23.9861C15.9063 23.9861 15.419 23.4989 15.419 22.8978V17.5786C15.419 16.9775 15.9063 16.4902 16.5074 16.4902C17.1085 16.4902 17.5957 16.9775 17.5957 17.5786V22.8978C17.5957 23.4989 17.1085 23.9861 16.5074 23.9861ZM19.8592 23.9861C19.3328 23.9861 18.906 23.5594 18.906 23.0329V17.8236C18.906 17.0872 19.503 16.4902 20.2393 16.4902H22.1007C23.7734 16.4902 24.8539 17.5396 24.8539 19.1447V19.1551C24.8539 20.7603 23.7734 21.7888 22.1007 21.7888H20.8125V23.0329C20.8125 23.5594 20.3857 23.9861 19.8592 23.9861ZM21.6436 17.9499H20.8125V20.3551H21.6436C22.4488 20.3551 22.9267 19.9343 22.9267 19.1551V19.1447C22.9267 18.3655 22.4488 17.9499 21.6436 17.9499Z"
      fill="white"
    ></path>
    <path
      d="M28 9.49999V9.33711C28 8.49982 27.6501 7.70062 27.0348 7.1327L21.2529 1.7955C20.7681 1.34807 20.1516 1.07469 19.5 1.01318V6.08194C19.5 7.96968 21.0035 9.49999 22.8581 9.49999H28Z"
      fill="#FFE66A"
    ></path>
  </svg>
);

export const ZipDarkIcon: React.FC<React.SVGProps<SVGSVGElement>> = props => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M7.00006 1C5.34318 1 4.00003 2.34318 4.00006 4.00006L4.00054 27.0312C4.00056 28.3922 4.92364 29.6153 6.29132 29.9194C12.7721 31.3602 19.2285 31.3602 25.7092 29.9194C27.0769 29.6153 28 28.3922 28 27.0311V9.3372C28 8.49991 27.6501 7.70071 27.0349 7.13279L21.2529 1.79559C20.6987 1.28405 19.9722 1 19.2181 1H7.00006Z"
      fill="#F0BA4A"
    ></path>
    <path
      d="M19.8592 23.9861C19.3328 23.9861 18.906 23.5594 18.906 23.0329V17.8236C18.906 17.0872 19.5029 16.4902 20.2393 16.4902H22.1007C23.7734 16.4902 24.8539 17.5396 24.8539 19.1447V19.1551C24.8539 20.7603 23.7734 21.7888 22.1007 21.7888H20.8124V23.0329C20.8124 23.5594 20.3857 23.9861 19.8592 23.9861ZM21.6436 17.9499H20.8124V20.3551H21.6436C22.4488 20.3551 22.9267 19.9343 22.9267 19.1551V19.1447C22.9267 18.3655 22.4488 17.9499 21.6436 17.9499Z"
      fill="white"
    ></path>
    <path
      d="M16.5074 23.9861C15.9063 23.9861 15.419 23.4989 15.419 22.8978V17.5786C15.419 16.9775 15.9063 16.4902 16.5074 16.4902C17.1085 16.4902 17.5957 16.9775 17.5957 17.5786V22.8978C17.5957 23.4989 17.1085 23.9861 16.5074 23.9861Z"
      fill="white"
    ></path>
    <path
      d="M9.10015 23.9861C8.59954 23.9861 8.19371 23.5803 8.19371 23.0797C8.19371 22.8825 8.25803 22.6907 8.37691 22.5333L11.7573 18.059V18.0227H9.01707C8.5939 18.0227 8.25085 17.6796 8.25085 17.2564C8.25085 16.8333 8.5939 16.4902 9.01707 16.4902H13.0892C13.5901 16.4902 13.9962 16.8963 13.9962 17.3972C13.9962 17.5941 13.9321 17.7857 13.8136 17.9429L10.443 22.4174V22.4537H13.3442C13.7674 22.4537 14.1104 22.7968 14.1104 23.2199C14.1104 23.6431 13.7674 23.9861 13.3442 23.9861H9.10015Z"
      fill="white"
    ></path>
    <path
      d="M28 9.49999V9.33711C28 8.49982 27.6501 7.70062 27.0348 7.1327L21.2529 1.7955C20.7681 1.34807 20.1516 1.07469 19.5 1.01318V6.08194C19.5 7.96968 21.0035 9.49999 22.8581 9.49999H28Z"
      fill="#FFE5A4"
    ></path>
  </svg>
);
