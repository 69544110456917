import { autoScrollStoreActions } from 'modules/listening/features/autoScroll/autoScrollStore';

import { IndividualSettingToggleImplementation } from '../../settings';

const toggleAutoScrollSetting: IndividualSettingToggleImplementation<'autoScroll'> = value => {
  if (value) {
    autoScrollStoreActions.enableAutoScroll();
  } else {
    autoScrollStoreActions.disableAutoScroll();
  }
};

export default toggleAutoScrollSetting;
