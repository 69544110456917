import * as React from 'react';

function SearchIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.762 17.176a8.5 8.5 0 111.414-1.414l4.031 4.03a1 1 0 01-1.414 1.414l-4.03-4.03zM17 10.5a6.5 6.5 0 11-13 0 6.5 6.5 0 0113 0z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SearchIcon;
