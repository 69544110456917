import { createNonPersistentStore } from 'lib/zustand';
import { ClassicReaderInfo } from 'modules/sdk/lib/facade/reader/classic/ClassicReaderInfo';

export type ClassicStoreState = {
  classicReaderInfo?: ClassicReaderInfo;
};

export const useClassicStore = createNonPersistentStore<ClassicStoreState>(
  () => {
    return {
      classicReaderInfo: undefined
    };
  },
  {
    isListeningScreenStore: true
  }
);

const updateClassicReaderInfo = (classicReaderInfo: ClassicReaderInfo) => {
  useClassicStore.setState({ classicReaderInfo }, /* replace */ true);
};

export const classicStoreActions = {
  updateClassicReaderInfo
};
