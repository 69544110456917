import { createNonPersistentStore } from 'lib/zustand';
import { ListeningDependencies } from 'modules/sdk/listeningDependencies';

type BaseFields = {
  state: string;
  enabled: boolean;
};

type NotInitializedState = BaseFields & {
  state: 'not_initialized';
};

type InitializedState = BaseFields & {
  cleanUpFunction: () => void;
};

type InactiveState = InitializedState & {
  state: 'inactive';
};

type ActiveState = InitializedState & {
  state: 'active';
  wordRects: DOMRect[];
  sentencesRects: DOMRect[];
};

type State = NotInitializedState | InactiveState | ActiveState;

export const useHighlightingStore = createNonPersistentStore<State>(
  () => {
    return {
      state: 'not_initialized',
      enabled: false
    };
  },
  {
    isListeningScreenStore: true
  }
);

export const initializeHighlighting = async (listeningDependencies: ListeningDependencies) => {
  const { overlayInfo } = listeningDependencies;

  const cleanUp = overlayInfo.listenToCurrentWordAndSentenceHighlighting(async highlightingInfo => {
    const isEnabled = useHighlightingStore.getState().enabled;
    if (!isEnabled) return;

    const { word, sentence } = highlightingInfo;
    updateHighlighting(word.rects, sentence.rects);
  });

  useHighlightingStore.setState(prevState => {
    if (prevState.state === 'active') {
      return {
        ...prevState,
        state: 'active',
        cleanUpFunction: cleanUp
      };
    }
    return {
      state: 'inactive',
      cleanUpFunction: cleanUp
    };
  });
};

export const enableHighlighting = () => {
  useHighlightingStore.setState(prevState => ({
    ...prevState,
    enabled: true
  }));
};

export const disableHighlighting = () => {
  useHighlightingStore.setState(prevState => ({
    ...prevState,
    enabled: false
  }));
};

const updateHighlighting = (wordRects: DOMRect[], sentencesRects: DOMRect[]) => {
  useHighlightingStore.setState(prevState => ({
    ...prevState,
    state: 'active',
    wordRects,
    sentencesRects
  }));
};

const clearHighlighting = () => {
  useHighlightingStore.setState(prevState => ({
    ...prevState,
    state: 'inactive',
    wordRects: [],
    sentencesRects: []
  }));
};

export const highlightingStoreActions = {
  initializeHighlighting,
  enableHighlighting,
  disableHighlighting,
  updateHighlighting,
  clearHighlighting
};
