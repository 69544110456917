import { BinaryContentReadableRandomly, BinaryContentReadableSequentially, FileFromString } from '@speechifyinc/multiplatform-sdk';
import {
  HttpClientAdapter,
  HttpMethod,
  HttpRequestBodyData,
  HttpResponseWithBodyAsBinaryContentReadableRandomly,
  HttpResponseWithBodyAsBinaryContentReadableSequentially
} from '@speechifyinc/multiplatform-sdk/api/adapters/http';
import { BoundaryMap } from '@speechifyinc/multiplatform-sdk/api/util/boundary';

import { WebBoundaryMap } from './boundarymap';
import { isCorsAllowed, wrapWithCorsProxy } from './cors';
import { callbackFromAsync } from './lib/callbackFromAsync';
import { Callback } from './lib/typeAliases';
import { asyncIterableFromReadableStream } from './utils/collections/asyncIterableFromReadableStream';

type ResponseInterface<Response> = {
  transformResponseToTyped: (data: globalThis.Response) => Promise<Response>;
};

const transformHeaders = (headers: Headers): Record<string, string> => {
  const resultHeaders: Record<string, string> = {};
  headers.forEach((value: string, key: string) => {
    resultHeaders[key] = value;
  });
  return resultHeaders;
};

/**
 * To avoid duplicating code in `WebHttpClientAdapter`'s each method, to transform the response to the correct type.
 */
const responseInterfaces: Record<
  string,
  // ESLint: Unexpected any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ResponseInterface<any> /* use of `never` as per [this way to avoid `any`](https://github.com/typescript-eslint/typescript-eslint/issues/642#issuecomment-526360912) */
> = {
  blob: {
    transformResponseToTyped: async (res: globalThis.Response): Promise<HttpResponseWithBodyAsBinaryContentReadableRandomly> => {
      const { status } = res;
      const headers = new WebBoundaryMap(transformHeaders(res.headers));
      const body = res.body ? new BinaryContentReadableRandomly(await res.blob()) : null;
      const response = new HttpResponseWithBodyAsBinaryContentReadableRandomly(/* status: */ status, /* headers: */ headers, /* body: */ body);
      return response;
    }
  } as ResponseInterface<HttpResponseWithBodyAsBinaryContentReadableRandomly>,

  stream: {
    transformResponseToTyped: async (res: globalThis.Response): Promise<HttpResponseWithBodyAsBinaryContentReadableSequentially> => {
      const { status } = res;
      const headers = new WebBoundaryMap(transformHeaders(res.headers));
      const body = res.body ? new BinaryContentReadableSequentially(asyncIterableFromReadableStream(res.body)) : null;
      const response = new HttpResponseWithBodyAsBinaryContentReadableSequentially(/* status: */ status, /* headers: */ headers, /* body: */ body);
      return response;
    }
  } as ResponseInterface<HttpResponseWithBodyAsBinaryContentReadableSequentially>
};

export class WebHttpClientAdapter extends HttpClientAdapter {
  protected override requestBinaryContentReadableRandomly = (
    method: HttpMethod,
    url: string,
    headers: BoundaryMap<string>,
    parameters: BoundaryMap<string>,
    httpRequestBodyData: HttpRequestBodyData | null,
    callback: Callback<HttpResponseWithBodyAsBinaryContentReadableRandomly>
  ) => callbackFromAsync(() => this.requestAsync(method, url, responseInterfaces.blob, headers, parameters, httpRequestBodyData), callback);

  protected override requestBinaryContentReadableSequentially = (
    method: HttpMethod,
    url: string,
    headers: BoundaryMap<string>,
    parameters: BoundaryMap<string>,
    httpRequestBodyData: HttpRequestBodyData | null,
    callback: Callback<HttpResponseWithBodyAsBinaryContentReadableSequentially | HttpResponseWithBodyAsBinaryContentReadableSequentially>
  ) => callbackFromAsync(() => this.requestAsync(method, url, responseInterfaces.stream, headers, parameters, httpRequestBodyData), callback);

  private async requestAsync<Response>(
    method: HttpMethod,
    url: string,
    responseInterface: ResponseInterface<Response>,
    headers: BoundaryMap<string>,
    parameters: BoundaryMap<string>,
    httpRequestBodyData: HttpRequestBodyData | null
  ): Promise<Response> {
    let body: FormData | Int8Array | null = null;
    if (httpRequestBodyData instanceof HttpRequestBodyData.BodyBytes) {
      body = httpRequestBodyData?.body ?? null;
    } else if (httpRequestBodyData instanceof HttpRequestBodyData.MultipartFormData) {
      if (httpRequestBodyData.entries) {
        body = new FormData();
        for (const entry of httpRequestBodyData.entries.entries()) {
          const key = entry.first;
          const item = entry.second;
          if (item.binaryContent instanceof BinaryContentReadableRandomly) {
            body.append(key, item.binaryContent.blob);
          } else if (item instanceof FileFromString) {
            const encoding = item.mimeType.parameters.charset || 'utf-8';
            const textDecoder = new TextDecoder(encoding);
            // Decode the bytes to a string
            const decodedString = textDecoder.decode(item.bytes);
            body.append(key, decodedString);
          } else {
            throw new Error(`WebHttpClientAdapter.requestAsync - Type of item not supported! key: ${key}, item: ${item}`);
          }
        }
      }
    }

    const requestParameters: { [k: string]: string } = {};
    if (parameters) {
      for (const key of parameters.keys()) {
        requestParameters[key] = parameters.get(key) as string;
      }
    }
    const requestHeaders: { [k: string]: string } = {};
    if (headers) {
      for (const key of headers.keys()) {
        requestHeaders[key] = headers.get(key) as string;
      }
    }

    const fetchUrl = isCorsAllowed(url) ? url : wrapWithCorsProxy(url);
    const params = new URLSearchParams(requestParameters);
    const _url = params?.toString() ? `${fetchUrl}?${params.toString()}` : fetchUrl;

    const res = await fetch(_url, {
      method: method.toString(),
      headers: requestHeaders,
      body
    });

    return responseInterface.transformResponseToTyped(res);
  }
}
