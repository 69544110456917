import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'store';

import { ImportSource } from 'interfaces/import';

type ImportState = 'in-progress' | 'failed' | 'completed';
interface ImportingFile {
  file: File;
  itemId?: string | null;
  state: ImportState;
}

const name = 'import';
const initialState: {
  isModalOpen: boolean;
  source: ImportSource | null;
  files: Record<string, ImportingFile>;
  isUpsellModalOpen?: boolean;
} = {
  isModalOpen: false,
  source: null,
  files: {},
  isUpsellModalOpen: false
};

const { actions: generatedActions, reducer } = createSlice({
  name,
  initialState,
  reducers: {
    openImportDialog: (state, { payload }: PayloadAction<{ source?: ImportSource | null } | undefined>) => ({
      ...state,
      isModalOpen: true,
      source: payload?.source ?? null
    }),
    closeImportDialog: state => ({ ...state, isModalOpen: false, source: null }),
    setImportSource: (state, { payload }: PayloadAction<ImportSource | null>) => ({ ...state, source: payload }),
    importFile: (state, { payload: { id, file } }: PayloadAction<{ id: string; file: File }>) => {
      const { files } = state;
      return { ...state, files: { ...files, [id]: { state: 'in-progress', file } } };
    },
    setImportFileCompleted: (state, { payload: { id, itemId } }: PayloadAction<{ id: string; itemId: string }>) => {
      const { files } = state;
      const fileState = files[id];

      if (!fileState) return state;

      return { ...state, files: { ...files, [id]: { ...fileState, state: 'completed', itemId } } };
    },
    setImportFileFailed: (state, { payload }: PayloadAction<string>) => {
      const { files } = state;
      const fileState = files[payload];

      if (!fileState) return state;

      return { ...state, files: { ...files, [payload]: { ...fileState, state: 'failed' } } };
    },
    resetImportingFiles: state => ({ ...state, files: {} }),
    setUpsellModalOpen: (state, { payload }: PayloadAction<boolean>) => {
      return { ...state, isUpsellModalOpen: payload };
    }
  }
});

const getImportState = (state: RootState): typeof initialState => {
  return state.import || initialState;
};

const actions = {
  ...generatedActions
};

const selectors = {
  getImportState
};

export { actions, selectors };

export default reducer;
