import { BoundaryMap, EventsTrackerAdapter } from '@speechifyinc/multiplatform-sdk';
import { Nullable } from '@speechifyinc/multiplatform-sdk/multiplatform-sdk-multiplatform-sdk';

import { FOR_SDK_USE_ONLY_logSegmentEvent } from 'utils/analytics';

const boundaryMapToRecord = (boundaryMap: BoundaryMap<Nullable<unknown>>): Record<string, unknown> => {
  return Object.fromEntries(
    boundaryMap.entries().map(boundaryPair => {
      const key = boundaryPair.first;
      const value = boundaryPair.second;
      return [key, value];
    })
  );
};

export class WebAnalyticsEventsTrackerAdapter extends EventsTrackerAdapter {
  track(eventName: string, properties: BoundaryMap<Nullable<unknown>>): void {
    FOR_SDK_USE_ONLY_logSegmentEvent(eventName, boundaryMapToRecord(properties));
  }
}
