/**
 * Polyfill needed as [some browsers still don't have `ReadableStream`s implement `AsyncIterable`](https://developer.mozilla.org/en-US/docs/Web/API/ReadableStream#browser_compatibility)
 */
// ESLint: Unexpected any
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function asyncIterableFromReadableStream<T>(stream: ReadableStream<T>): any {
  // Source https://jakearchibald.com/2017/async-iterators-and-generators/#a-shorter-implementation
  // Use the non-generator based implementation, not https://github.com/SpeechifyInc/speechify-chrome-plugin/blob/develop/extension/shared/adapters/helpers/asyncIterableFromReadableStream.ts
  // because the web app ReadableStreamDefaultReader.read is causing an error. Issue might be due to transpilation.

  // Get a lock on the stream:
  const reader = stream.getReader();

  return {
    next() {
      // Stream reads already resolve with {done, value}, so
      // we can just call read:
      return reader.read();
    },
    return() {
      // Release the lock if the iterator terminates.
      reader.releaseLock();
      return {};
    },
    // for-await calls this on whatever it's passed, so
    // iterators tend to return themselves.
    [Symbol.asyncIterator]() {
      return this;
    }
  };
}
