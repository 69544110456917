import { AccountSettings } from '@speechifyinc/multiplatform-sdk';

import { logError } from 'lib/observability';
import { promisify } from 'lib/speechify/adaptors/promisify';

import { accountSettingsService } from '../lib/speechify';

let settings: AccountSettings | null = null;

export const DAILY_QUESTION_LIMIT_KEY = 'dailyQuestionLimit';
export const LAST_BASIC_TRIAL_REFRESH = 'lastBasicTrialRefresh';
export const TRIAL_EXTENDED = 'trialExtended';
export const FIRST_PDF_DOCUMENT = 'firstPdfDocument';

export const refreshAccountSettings = async () => {
  settings = await promisify(accountSettingsService.getSettings.bind(accountSettingsService))();
};

export const getAccountSettings = async () => {
  if (!settings) {
    await refreshAccountSettings();
  }

  try {
    // @ts-expect-error TS(2531): Object is possibly 'null'.
    return settings.copy();
  } catch (e) {
    logError(e as Error);
    return Promise.reject('Unable to retrieve account settings: ' + e);
  }
};

export const setAccountSettings = async (accountSettings: AccountSettings) => {
  settings = await promisify(accountSettingsService.setSettings.bind(accountSettingsService))(accountSettings.copy());
  return settings.copy();
};

export const getCustomAccountSetting = async (key: string): Promise<string | null> => {
  // @ts-expect-error TS(2322): Type 'Nullable<string>' is not assignable to type ... Remove this comment to see the full error message
  return (await getAccountSettings()).customSettings.get(key);
};

export const setCustomAccountSetting = async (key: string, value: string) => {
  const settings = await getAccountSettings();
  settings.customSettings.set(key, value);
  return setAccountSettings(settings);
};
