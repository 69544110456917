import { IRecord } from 'interfaces';
import { v4 as uuidv4 } from 'uuid';

import { importSharedItemForUser } from 'lib/speechify';
import { createNonPersistentStore } from 'lib/zustand';
import { useAuthStore } from 'modules/auth/store/authStore';

export type GuestListeningSessionStoreState = {
  sharedItemImportPromise: Promise<string> | null;
};

export const useGuestListeningSessionStore = createNonPersistentStore<GuestListeningSessionStoreState>(
  () => {
    return {
      sharedItemImportPromise: null
    };
  },
  {
    isListeningScreenStore: true
  }
);

const importSharedItem = (item: IRecord) => {
  if (useGuestListeningSessionStore.getState().sharedItemImportPromise) return useGuestListeningSessionStore.getState().sharedItemImportPromise!;
  const user = useAuthStore.getState().user;

  if (!user || !user.uid) return Promise.resolve();

  const promise = importSharedItemForUser(item.originalCopy || item.id, user.uid, uuidv4());

  promise.then(() => {
    guestListeningSessionStoreActions.setSharedItemImportPromise(null);
  });

  return promise;
};

export const guestListeningSessionStoreActions = {
  importSharedItem,
  setSharedItemImportPromise: (promise: Promise<string> | null) => {
    useGuestListeningSessionStore.setState({ sharedItemImportPromise: promise });
  }
};
