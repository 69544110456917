import { transformDayNumberToEnglish } from 'utils/dates';

import { SubscriptionStoreState, useSubscriptionStore } from '../subscriptionStore';

export const getNextHdWordsGrantReadableDate = (state?: SubscriptionStoreState) => {
  state ??= useSubscriptionStore.getState();
  const date = state.entitlements?.nextHdWordsGrandDate;

  if (!date) {
    return 'next month';
  }

  const day = date?.getDay() ?? 1;
  const month = date?.getMonth() ?? 1;

  const currentDate = new Date();
  const relativeMonth = currentDate.getMonth() === month ? 'this month' : 'next month';

  const ordinal = transformDayNumberToEnglish(date ?? day);

  return `on the ${ordinal} of ${relativeMonth}`;
};
