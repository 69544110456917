import * as React from 'react';

function SettingsIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.713 3.007h-1.688a.461.461 0 00-.447.349 4.461 4.461 0 01-5.55 3.204.461.461 0 00-.525.213l-.844 1.461a.461.461 0 00.078.562 4.461 4.461 0 010 6.408.461.461 0 00-.078.562l.844 1.462c.105.183.322.27.526.213a4.461 4.461 0 015.549 3.204.461.461 0 00.447.348h1.688a.461.461 0 00.447-.348 4.461 4.461 0 015.55-3.204c.203.058.42-.03.526-.213l.844-1.462a.461.461 0 00-.079-.562 4.461 4.461 0 010-6.408.461.461 0 00.079-.562l-.844-1.461a.461.461 0 00-.526-.213 4.461 4.461 0 01-5.55-3.204.461.461 0 00-.447-.35zm-7.136 1.63A2.461 2.461 0 002.77 5.772l-.844 1.461a2.461 2.461 0 00.419 2.999 2.461 2.461 0 010 3.535 2.461 2.461 0 00-.42 2.998l.845 1.462a2.461 2.461 0 002.806 1.136 2.461 2.461 0 013.061 1.768 2.461 2.461 0 002.387 1.861h1.688a2.461 2.461 0 002.387-1.861 2.461 2.461 0 013.062-1.768 2.461 2.461 0 002.806-1.136l.844-1.462a2.461 2.461 0 00-.42-2.998 2.461 2.461 0 010-3.535c.811-.786.984-2.021.42-2.999l-.844-1.461a2.461 2.461 0 00-2.806-1.137A2.461 2.461 0 0115.1 2.87a2.461 2.461 0 00-2.387-1.862h-1.688a2.461 2.461 0 00-2.387 1.862 2.461 2.461 0 01-3.061 1.767z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.869 14.056a2.056 2.056 0 100-4.112 2.056 2.056 0 000 4.112zm0 2a4.056 4.056 0 100-8.112 4.056 4.056 0 000 8.112z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SettingsIcon;
