import { createAsyncThunk } from '@reduxjs/toolkit';
import { authorize, fetchSuggestedItems, getAuths, logout } from 'components/library/import/api';
import { RootState } from 'store';

import { IntegrationService, IntegrationsActionType } from 'interfaces/integrations';

export const fetchAuths = createAsyncThunk(IntegrationsActionType.fetchAuths, () => getAuths());

export const fetchSuggestions = createAsyncThunk(IntegrationsActionType.fetchSuggestions, (_, { getState }) => {
  const state = getState() as RootState;
  const services = Object.entries(state.integration.auths)
    .map(([service, { authorized }]) => (authorized ? (service as IntegrationService) : null))
    .filter<IntegrationService>((service): service is IntegrationService => !!service && !state.integration.suggestionDisabled?.[service]);

  return fetchSuggestedItems(services, 1);
});

export const authorizeService = createAsyncThunk(
  IntegrationsActionType.authorizeService,
  ({ service, options }: { service: IntegrationService; options?: Record<string, string> }) => authorize(service, options)
);

export const logoutFromService = createAsyncThunk(IntegrationsActionType.logoutFromService, (service: IntegrationService) => logout(service));
