import { IndividualSettingToggleImplementation } from '../../settings';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const toggleAutoHideInterface: IndividualSettingToggleImplementation<'autoHideInterface'> = value => {
  // TODO(overhaul): Implement the auto hide interface setting toggle
  // if (value) {
  // } else {
  // }
};

export default toggleAutoHideInterface;
