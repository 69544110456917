import * as React from 'react';

function UpgradeIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.687 4.12A.25.25 0 015.901 4H14.1a.25.25 0 01.213.12l2.37 3.874a.25.25 0 01-.02.288l-6.47 7.903a.25.25 0 01-.386 0l-6.47-7.903a.25.25 0 01-.02-.288l2.37-3.874zM5.901 2a2.25 2.25 0 00-1.92 1.076L1.611 6.95a2.25 2.25 0 00.178 2.6l6.47 7.902a2.25 2.25 0 003.482 0l6.47-7.903a2.25 2.25 0 00.179-2.6l-2.371-3.873A2.25 2.25 0 0014.099 2H5.902zM6.5 7a1 1 0 100 2h7a1 1 0 100-2h-7z"
        fill="url(#paint0_linear_19615_2352)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.687 4.12A.25.25 0 015.901 4H14.1a.25.25 0 01.213.12l2.37 3.874a.25.25 0 01-.02.288l-6.47 7.903a.25.25 0 01-.386 0l-6.47-7.903a.25.25 0 01-.02-.288l2.37-3.874zM5.901 2a2.25 2.25 0 00-1.92 1.076L1.611 6.95a2.25 2.25 0 00.178 2.6l6.47 7.902a2.25 2.25 0 003.482 0l6.47-7.903a2.25 2.25 0 00.179-2.6l-2.371-3.873A2.25 2.25 0 0014.099 2H5.902zM6.5 7a1 1 0 100 2h7a1 1 0 100-2h-7z"
        fill="url(#paint1_radial_19615_2352)"
        fillOpacity={0.4}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.687 4.12A.25.25 0 015.901 4H14.1a.25.25 0 01.213.12l2.37 3.874a.25.25 0 01-.02.288l-6.47 7.903a.25.25 0 01-.386 0l-6.47-7.903a.25.25 0 01-.02-.288l2.37-3.874zM5.901 2a2.25 2.25 0 00-1.92 1.076L1.611 6.95a2.25 2.25 0 00.178 2.6l6.47 7.902a2.25 2.25 0 003.482 0l6.47-7.903a2.25 2.25 0 00.179-2.6l-2.371-3.873A2.25 2.25 0 0014.099 2H5.902zM6.5 7a1 1 0 100 2h7a1 1 0 100-2h-7z"
        fill="url(#paint2_radial_19615_2352)"
        fillOpacity={0.8}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.687 4.12A.25.25 0 015.901 4H14.1a.25.25 0 01.213.12l2.37 3.874a.25.25 0 01-.02.288l-6.47 7.903a.25.25 0 01-.386 0l-6.47-7.903a.25.25 0 01-.02-.288l2.37-3.874zM5.901 2a2.25 2.25 0 00-1.92 1.076L1.611 6.95a2.25 2.25 0 00.178 2.6l6.47 7.902a2.25 2.25 0 003.482 0l6.47-7.903a2.25 2.25 0 00.179-2.6l-2.371-3.873A2.25 2.25 0 0014.099 2H5.902zM6.5 7a1 1 0 100 2h7a1 1 0 100-2h-7z"
        fill="#000"
        fillOpacity={0.4}
        style={{
          mixBlendMode: 'overlay'
        }}
      />
      <defs>
        <linearGradient id="paint0_linear_19615_2352" x1={18.6675} y1={18.2768} x2={-1.49373} y2={13.3505} gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFA82F" />
          <stop offset={1} stopColor="#FF795B" />
        </linearGradient>
        <radialGradient
          id="paint1_radial_19615_2352"
          cx={0}
          cy={0}
          r={1}
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(26.4725 0 0 15.8837 .19 20.602)"
        >
          <stop stopColor="#FF1A96" />
          <stop offset={0.841762} stopColor="#FA00FF" stopOpacity={0.0634006} />
          <stop offset={1} stopColor="#FA00FF" stopOpacity={0} />
        </radialGradient>
        <radialGradient
          id="paint2_radial_19615_2352"
          cx={0}
          cy={0}
          r={1}
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(-10.74466 15.55029 -15.96748 -11.03293 16.073 -.325)"
        >
          <stop stopColor="#FFE600" />
          <stop offset={1} stopColor="#FF9500" stopOpacity={0} />
        </radialGradient>
      </defs>
    </svg>
  );
}

export default UpgradeIcon;
