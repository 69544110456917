import { IRecord } from 'interfaces/library';

import { ContentMetaType } from '../../base';
import { BaseItemListenableContent } from '../BaseItemListenableContent';

export class EPUBItemListenableContent extends BaseItemListenableContent {
  public readonly metaType: ContentMetaType = ContentMetaType.EPUB;
  constructor(item: IRecord) {
    super(item);
  }
}
