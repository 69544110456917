import { createSelector, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'store';

const name = 'darkMode';

type DarkModeState = {
  isDarkMode: boolean;
  isManual: boolean;
};

const initialState: DarkModeState = {
  isDarkMode: false,
  isManual: false
};

const { actions: generatedActions, reducer } = createSlice({
  name,
  initialState,
  reducers: {
    toggleDarkMode: state => {
      state.isManual = true;
      state.isDarkMode = !state.isDarkMode;
    },
    setDarkMode: (
      state,
      {
        payload
      }: {
        payload: {
          isDarkMode: boolean;
          isManual?: boolean;
        };
      }
    ) => {
      state.isDarkMode = payload.isDarkMode;
      if (payload.isManual !== undefined) {
        state.isManual = payload.isManual;
      }
    }
  }
});

const actions = { ...generatedActions };

export const getIsDarkMode = () =>
  createSelector(
    (state: RootState) => state.darkMode,
    (darkMode: DarkModeState) => darkMode.isDarkMode
  );

export const getIsManual = () =>
  createSelector(
    (state: RootState) => state.darkMode,
    (darkMode: DarkModeState) => darkMode.isManual
  );

const selectors = {
  getIsDarkMode,
  getIsManual
};

export { actions, selectors };

export default reducer;
