import 'client-only';

import { initializeSubscriptionStore } from 'modules/subscription/stores/actions/initializeSubscriptionStore';

import packageJson from '../../../../package.json';
import { SDKBundleFacade, SDKContentFacade, SDKLibraryFacade, SDKSubscriptionFacade, SDKVoiceFacade } from './facade';
import { SDKAskAiFacade } from './facade/askAi';

export const importSDK = async () => {
  const sdkModule = await import('@speechifyinc/multiplatform-sdk');
  // TODO(albertusdev): Determine if we need to call setupDiagnosticReporter here
  return sdkModule.default;
};

const initSDK = async () => {
  const [sdkModule, { firebaseApp, firebaseAuth }, { WebAdapterFactory }, { WebBoundaryMap }, { promisify }, { authorizationTokenProvider }] =
    await Promise.all([
      importSDK(),
      import('lib/firebase/firebase.client'),
      import('lib/speechify/adaptors/index'),
      import('lib/speechify/adaptors/boundarymap'),
      import('lib/speechify/adaptors/promisify'),
      import('lib/speechify/adaptors/audioServer')
    ]);
  const { AudioServerConfiguration, AppEnvironment, ClientConfig, ClientOptions, FirebaseDynamicLinksConfig, SpeechifyClientFactory } = sdkModule;

  const platform = new WebAdapterFactory(firebaseApp, true);

  const clientOptions = new ClientOptions(new AudioServerConfiguration(authorizationTokenProvider)).enableLiveQueryViewV2().enableEpubV2ForEpubs();

  const factory = new SpeechifyClientFactory(
    new ClientConfig(
      AppEnvironment.WEB_APP,
      packageJson.version || '0.0.0',
      process.env.NEXT_PUBLIC_PAYMENT_SERVER_URL!,
      `https://us-central1-${process.env.NEXT_PUBLIC_FB_PROJECT_ID}.cloudfunctions.net`,
      process.env.NEXT_PUBLIC_AUDIO_SERVER_URL!,
      process.env.NEXT_PUBLIC_VOICES_SERVER!.replace(/\/v\d+/, ''), // exclude version
      process.env.NEXT_PUBLIC_PLATFORM_CATALOG_URL!,
      process.env.NEXT_PUBLIC_FB_PROJECT_ID!,
      new FirebaseDynamicLinksConfig(
        process.env.NEXT_PUBLIC_DL_KEY!,
        'https://speechify.page.link',
        'com.cliffweitzman.speechify2',
        'com.cliffweitzman.speechifyMobile2'
      ),
      process.env.NEXT_PUBLIC_WEBAPP_URL!,
      process.env.NEXT_PUBLIC_PLATFORM_ML_PAGE_PARSING_URL!,
      clientOptions,
      process.env.NEXT_PUBLIC_AI_CHAT_URL!,
      process.env.NEXT_PUBLIC_EBOOK_BACKEND_URL!,
      process.env.NEXT_PUBLIC_DIAGNOSTICS_SERVICE_URL!
    ),
    platform
  );

  const client = factory.getClient();

  // this should omit the `getCurrentUser` hack in the previous codebase since SDK should only initialize after auth state is stable.
  await firebaseAuth.authStateReady();

  // TODO(albertusdev): Recheck if this assumption is correct and whether we need to assert for currentUser is not null here for this to be returned.

  return {
    platform,
    factory,
    client,
    promisify,
    sdkModule,
    WebBoundaryMap
  };
};
export type MultiplatformSDKInstance = Awaited<ReturnType<typeof initSDK>>;
let sdkInstance: MultiplatformSDKInstance;

export type SDKFacade = {
  sdk: MultiplatformSDKInstance;
  content: SDKContentFacade;
  library: SDKLibraryFacade;
  bundle: SDKBundleFacade;
  subscription: SDKSubscriptionFacade;
  voice: SDKVoiceFacade;
  askAi: SDKAskAiFacade;
};

let sdkFacade: SDKFacade;

export const getSDK = async (): Promise<SDKFacade> => {
  if (sdkFacade) {
    return sdkFacade;
  }
  sdkInstance = await initSDK();

  sdkFacade = {
    sdk: sdkInstance,
    content: new SDKContentFacade(sdkInstance),
    library: new SDKLibraryFacade(sdkInstance),
    bundle: new SDKBundleFacade(sdkInstance),
    subscription: new SDKSubscriptionFacade(sdkInstance),
    voice: new SDKVoiceFacade(sdkInstance),
    askAi: new SDKAskAiFacade(sdkInstance)
  };

  initializeSubscriptionStore(sdkFacade.subscription);

  return sdkFacade;
};
