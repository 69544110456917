import { AuthorizationHeaderScheme, AuthorizationTokenProvider } from '@speechifyinc/multiplatform-sdk';
import { User } from 'firebase/auth';
import * as jose from 'jose';

import { callbackFromAsync } from './lib/callbackFromAsync';
import { Callback } from './lib/typeAliases';
import { PromiseWithCompletionController, PromiseWithCompletionControllerUtil } from './utils/promiseWithCompletionController';

/**
 * This should account for time the user needs to perform any extra authentication verification (to be able to seamlessly rollover tokens without breaking any functionality).
 */
const minimumTimeToExpiryToRefreshMs = 60 * 5 * 1000;
/**
 * This should account for network latency or device clocks being slightly off
 */
const minimumExpiryTimeToDiscardMs = 30 * 1000;

type TokenWithInfo = {
  expiryDate: Date | null;
  tokenString: string;
};

/**
 * Responsible for a single user's token - tries to make it available as soon as possible (before it is needed, and as soon as user
 * info is there), and makes sure that any errors (e.g. network problems) does not severe the ability to obtain it once the problem
 * causing the error ceases.
 */
class UserAudioServerTokenProvider {
  #fbUserPromise: PromiseWithCompletionController<User> = PromiseWithCompletionControllerUtil.create<User>();

  constructor(user: User | null = null) {
    if (user) {
      this.setAudioServerUser(user);
    }
  }

  #audioServerTokenPromise: Promise<TokenWithInfo> | null = null;

  #hasUser = false;

  public get hasUser(): boolean {
    return this.#hasUser;
  }

  setAudioServerUser(user: User) {
    this.#fbUserPromise.completionController.resolve(user);
    this.#hasUser = true;
    // Eagerly prepare the token as soon as there is a user
    (async () => {
      await this.getAudioServerToken();
    })();
  }

  async getAudioServerToken(): Promise<string> {
    const currentToken = this.#audioServerTokenPromise && (await this.#audioServerTokenPromise);
    if (currentToken && !currentToken.expiryDate) {
      return currentToken.tokenString;
    }

    if (currentToken && currentToken.expiryDate && !isTimePassedNowMinusMs(currentToken.expiryDate, minimumTimeToExpiryToRefreshMs))
      return currentToken.tokenString;

    const newTokenPromise = (this.#audioServerTokenPromise = (async () => {
      try {
        return await getNewAudioServerToken(await this.#fbUserPromise.promise);
      } catch (e) {
        // Throw the error to the current awaiters, but any new ones should cause us to retry, so let's clear the promise
        this.#audioServerTokenPromise = null;
        throw e;
      }
    })());

    if (currentToken && currentToken.expiryDate && !isTimePassedNowMinusMs(currentToken.expiryDate, minimumExpiryTimeToDiscardMs))
      return currentToken.tokenString;

    return (await newTokenPromise).tokenString;
  }
}

/**
 * Call this as soon as there's a user available.
 */
export async function setAudioServerUser(user: User) {
  authorizationTokenProvider.setAudioServerUser(user);
}

/**
 * The token provider for the SDK.
 */
export const authorizationTokenProvider = new (class extends AuthorizationTokenProvider {
  private currentUserTokenProvider: UserAudioServerTokenProvider = new UserAudioServerTokenProvider();

  setAudioServerUser(user: User) {
    if (!this.currentUserTokenProvider.hasUser) this.currentUserTokenProvider.setAudioServerUser(user);
    else this.currentUserTokenProvider = new UserAudioServerTokenProvider(user);
  }

  override getValidToken = (callback: Callback<string>): void =>
    callbackFromAsync(async () => {
      return await this.currentUserTokenProvider.getAudioServerToken();
    }, /* callback: */ callback);

  get scheme(): AuthorizationHeaderScheme {
    return AuthorizationHeaderScheme.Bearer;
  }
})();

/**
 * Logic for getting a new token, in idiomatic TypeScript.
 */
async function getNewAudioServerToken(fbUser: User): Promise<TokenWithInfo> {
  /* According to [`getIdToken` docs](https://firebase.google.com/docs/reference/js/v8/firebase.User#getidtokenresult), this token will be refreshed as needed
     and it says that it is already fast, if the token is not expired.
     Alternatively, we could even use [getidtokenresult](https://firebase.google.com/docs/reference/js/v8/firebase.User#getidtokenresult) and then [expirationtime](https://firebase.google.com/docs/reference/js/v8/firebase.auth.IDTokenResult#expirationtime)
   */
  const validFirebaseToken = await fbUser.getIdToken();

  /**
   * A separate token is recommended for Web as per [docs _"Ideally, App Check is not used on Web since ReCaptcha adds friction,
   *  reduces load performance, and increases latency."_](https://audio.docs.speechify.dev/synthesis/authorization.html#subscription-token)
   */
  const fetchResult = await fetch(
    // See https://audio.docs.speechify.dev/synthesis/authorization.html#subscription-token
    `${process.env.NEXT_PUBLIC_AUDIO_SERVER_URL!}/v1/auth/sign?id_token=${validFirebaseToken}`
  );
  const json = await fetchResult.json();
  const audioServerToken = json.token;

  const decodedToken = jose.decodeJwt(audioServerToken) as jose.JWTPayload & SubscriptionToken;

  return {
    expiryDate: !decodedToken.exp ? null : new Date(decodedToken.exp * 1000),
    tokenString: audioServerToken
  };
}

function isTimePassedNowMinusMs(time: Date, marginMs: number) {
  const timeToExpiry = time.getTime() - new Date().getTime();
  return timeToExpiry < marginMs;
}

/**
 * See https://audio.docs.speechify.dev/synthesis/authorization.html#subscription-token
 */
type SubscriptionToken = {
  // Firebase User ID
  uid: string;
  // Number of hd words left at the time of token creation
  wordQuota: unknown; // TODO - define this (in docs it's `subscription.hdWordsLeft`)
  // Date of last word quota refill - Unix timestamp (seconds)
  lastWordQuotaGrantDate: unknown; // TODO - define this (in docs it's `subscription.lastRefilledAt`)
  // (Well Known) Creation date of token - Unix timestamp (seconds)
  iat: number;
  // (Well Known) Expiration date of token - Unix timestamp (seconds)
  exp: number;
};
