import { FeatureNameEnum } from 'config/constants/featureDefinitions';
import { getFeatureVariant } from 'hooks/useFeatureFlags';
import { AnalyticsEventKey, logAnalyticsEvent } from 'modules/analytics/logAnalyticsEvent';
import { onUpgradeButtonClick } from 'modules/subscription/utils/upgrade';
import { UpgradeSource } from 'modules/subscription/utils/UpgradeSource';

import { formatUpsellModalTypeAsString } from '../../utils';
import { UpsellModalType } from '../types';
import { useUpsellStore } from '../upsellStores';

export const showUpsellModal = async (modal: UpsellModalType) => {
  const showContextualUpsell = (await getFeatureVariant(FeatureNameEnum.SHOW_CONTEXTUAL_UPSELL)) === true;

  if (showContextualUpsell) {
    useUpsellStore.setState({ activeModal: modal });
    logAnalyticsEvent(AnalyticsEventKey.upsellModalsContextual, { variant: formatUpsellModalTypeAsString(modal)! });
  } else {
    onUpgradeButtonClick(UpgradeSource.contextualUpsellControl);
  }
};
