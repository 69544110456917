import { createSlice } from '@reduxjs/toolkit';

import { loginWithCustomToken } from 'store/auth/actions';

import { CrossLoginQrCodeData, checkAuthStatus, fetchCrossLoginQr } from './actions';

const name = 'crossLogin';

type CrossLoginState = {
  qrCodeData: CrossLoginQrCodeData | null;
  isQrCodeLoading: boolean;
  isAuthStatusLoading: boolean;
  isCrossLoginLoading: boolean;
  hasLoggedIn: boolean;
};
const initialState: CrossLoginState = {
  qrCodeData: null,
  isQrCodeLoading: false,
  isAuthStatusLoading: false,
  isCrossLoginLoading: false,
  hasLoggedIn: false
};

const { actions: generatedActions, reducer } = createSlice({
  name,
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchCrossLoginQr.pending, state => {
      state.isQrCodeLoading = true;
    });
    builder.addCase(fetchCrossLoginQr.fulfilled, (state, action) => {
      // @ts-expect-error TS(2322): Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
      state.qrCodeData = action.payload;
      state.isQrCodeLoading = false;
      state.isAuthStatusLoading = false;
    });

    builder.addCase(checkAuthStatus.pending, state => {
      state.isAuthStatusLoading = true;
    });
    builder.addCase(checkAuthStatus.fulfilled, state => {
      state.isAuthStatusLoading = false;
    });

    builder.addCase(loginWithCustomToken.pending, state => {
      state.isCrossLoginLoading = true;
    });
    builder.addCase(loginWithCustomToken.fulfilled, state => {
      state.isCrossLoginLoading = false;
      state.hasLoggedIn = true;
    });
  }
});

const actions = {
  ...generatedActions,
  fetchCrossLoginQr,
  checkAuthStatus
};

export { actions, generatedActions };

export default reducer;
