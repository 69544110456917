import type {
  ContentOverlayRange,
  CurrentWordAndSentenceOverlayEvent,
  CurrentWordAndSentenceOverlayHelper,
  EmbeddedReadingBundle,
  OriginalContentOverlayProvider,
  SpeechSentence,
  SpeechView,
  StandardView
} from '@speechifyinc/multiplatform-sdk';

import { logError } from 'lib/observability';
import { TimeoutError, withTimeout } from 'utils/promise';

import { MultiplatformSDKInstance } from '../../sdk';
import { PlaybackInfo } from '../playback';
import { HighlightingInfo, OverlayInfo, PlaybackCursorPosition, SentenceMark } from './base';
import { getRelativeRect } from './utils';

type EmbeddedOverlayContentRef = Node;

export class EmbeddedOverlayInfo extends OverlayInfo<EmbeddedOverlayContentRef> {
  private _cleanUpFunctions: (() => void)[] = [];

  private _overlayProvider: OriginalContentOverlayProvider<EmbeddedOverlayContentRef>;
  private _overlayHelper: CurrentWordAndSentenceOverlayHelper<EmbeddedOverlayContentRef>;

  constructor(
    private readonly sdk: MultiplatformSDKInstance,
    private readonly bundle: EmbeddedReadingBundle,
    public readonly playbackInfo: PlaybackInfo,
    private _overlayElement: HTMLElement,
    private _scrollerElement: HTMLElement
  ) {
    super();
    this._overlayProvider = new sdk.sdkModule.OriginalContentOverlayProvider<EmbeddedOverlayContentRef>();
    this._overlayHelper = new sdk.sdkModule.CurrentWordAndSentenceOverlayHelper<EmbeddedOverlayContentRef>(bundle.playbackControls, this._overlayProvider);
  }

  addRenderedContentToOverlayProvider = async (): Promise<void> => {};

  get standardView(): StandardView {
    return this.bundle.listeningBundle.contentBundle.standardView;
  }

  get speechView(): SpeechView {
    return this.bundle.listeningBundle.contentBundle.speechView;
  }

  public get overlayElement(): HTMLElement {
    return this._overlayElement;
  }

  public updateOverlayElement = (overlayElement: HTMLElement): void => {
    this._overlayElement = overlayElement;
  };

  get overlayProvider(): OriginalContentOverlayProvider<EmbeddedOverlayContentRef> {
    return this._overlayProvider;
  }

  override get overlayHelper(): CurrentWordAndSentenceOverlayHelper<EmbeddedOverlayContentRef> {
    return this._overlayHelper;
  }

  override overlayRangeToRects(range: ContentOverlayRange<EmbeddedOverlayContentRef>, baseRect: DOMRect): DOMRect[] {
    const node = range.ref.value;

    if (!node) {
      return [];
    }

    const textNode = node.nodeType === Node.TEXT_NODE ? node : (node as HTMLElement).childNodes[0];
    if (!(textNode instanceof Node)) return [];
    const r = new Range();

    try {
      r.setStart(textNode, range.startIndex);
      r.setEnd(textNode, range.endIndex);
    } catch (error) {
      return [];
    }

    return Array.from(r.getClientRects()).map(rect => getRelativeRect(baseRect, rect));
  }

  // TODO(albertusdev): Consider refactoring this into abstract class which simply calls `highlightContainer.getClientRects()[0]`
  override getBaseRect(): DOMRect {
    return this.overlayElement.getClientRects()[0];
  }

  get scroller(): HTMLElement {
    return this._scrollerElement;
  }

  override getActiveSentenceTopPositionForAutoScroll = async (sentenceOverlayRanges: ContentOverlayRange<EmbeddedOverlayContentRef>[]) => {
    if (sentenceOverlayRanges.length === 0) {
      return null;
    }

    const firstSentence = sentenceOverlayRanges[0];

    // const activeBlockIndex = firstSentence.ref.value.blockIndex;

    // await this.maybeScrollToBlockIndex(activeBlockIndex);

    const baseRect = this.getBaseRect();
    const rects = this.overlayRangeToRects(firstSentence, baseRect);

    if (rects?.length === 0) {
      return null;
    }

    return rects[0].top;
  };

  public isActiveSentenceInView = (): boolean => {
    if (!this._latestHighlightingEvent) {
      return false;
    }

    const sentenceOverlayRanges = this._latestHighlightingEvent.sentenceOverlayRanges;
    if (sentenceOverlayRanges.length === 0) {
      return false;
    }

    const firstSentenceRange = sentenceOverlayRanges[0];
    const node = firstSentenceRange.ref.value;
    if (!node) {
      return false;
    }

    // Determine the text node and create a range for the active sentence
    const textNode = node.nodeType === Node.TEXT_NODE ? node : node.childNodes[0];
    if (!(textNode instanceof Node)) {
      return false;
    }

    const range = document.createRange();
    try {
      range.setStart(textNode, firstSentenceRange.startIndex);
      range.setEnd(textNode, firstSentenceRange.endIndex);
    } catch (error) {
      return false;
    }

    const rects = range.getClientRects();
    if (rects.length === 0) {
      return false;
    }

    // first rect of active sentence
    const sentenceRect = rects[0];

    // scroller's bounding rect
    const scrollerRect = this.scroller.getBoundingClientRect();
    const adjustedScrollerBottom = scrollerRect.bottom - 100;

    // is sentence is within the scroller's visible area
    return sentenceRect.bottom > scrollerRect.top && sentenceRect.top < adjustedScrollerBottom;
  };

  get highlightContainer(): HTMLElement {
    return this.overlayElement;
  }

  private _latestHighlightingEvent: CurrentWordAndSentenceOverlayEvent<EmbeddedOverlayContentRef> | null = null;

  public listenToCurrentWordAndSentenceHighlighting = (callback: (currentWordAndSentence: HighlightingInfo) => void): (() => void) => {
    const overlayHelper = this.overlayHelper;

    const mapOverlayEventToCurrentWordAndSentence = async (event: CurrentWordAndSentenceOverlayEvent<EmbeddedOverlayContentRef>) => {
      try {
        const { sentenceOverlayRanges, wordOverlayRanges } = event;
        if (wordOverlayRanges.length === 0) {
          throw new Error('No sentence overlay ranges found');
        }

        const baseRect = this.getBaseRect();

        const wordRects = wordOverlayRanges.flatMap(wordRange => {
          const rects = this.overlayRangeToRects(wordRange, baseRect);
          return rects;
        });

        const sentencesRects = sentenceOverlayRanges.flatMap(sentenceRange => {
          const rects = this.overlayRangeToRects(sentenceRange, baseRect);
          return rects;
        });

        return {
          word: { rects: wordRects },
          sentence: { rects: sentencesRects }
        };
      } catch (e) {
        return { word: { rects: [] }, sentence: { rects: [] } };
      }
    };

    const cleanUp = overlayHelper.addEventListener(async event => {
      this._latestHighlightingEvent = event;
      try {
        const highlightingInfo = await withTimeout(mapOverlayEventToCurrentWordAndSentence(this._latestHighlightingEvent), 2000);
        callback(highlightingInfo);
      } catch (e) {
        if (e instanceof TimeoutError) {
          // ignore timeout error and simply move on to the next event
          if (process.env.NODE_ENV === 'development') {
            console.warn('Timeout while processing highlighting event, moving on to the next event');
          }
          return;
        }
        logError(new Error(`Error while processing highlighting event: ${e}`));
      }
    });

    const cleanUpZoomListener = this.customHighlightingEventEmitter.addEventListener('zoom', async () => {
      await new Promise(resolve => setTimeout(resolve, 1));
      if (this._latestHighlightingEvent) {
        mapOverlayEventToCurrentWordAndSentence(this._latestHighlightingEvent).then(callback);
      }
    });

    this._cleanUpFunctions.push(cleanUp);
    this._cleanUpFunctions.push(cleanUpZoomListener);

    return () => {
      cleanUp();
      this._cleanUpFunctions = this._cleanUpFunctions.filter(f => f !== cleanUp && f !== cleanUpZoomListener);
    };
  };

  public getSentences = async (): Promise<SpeechSentence[]> => {
    // TODO(albertus/harshit): Cache these sentence tokenization
    const { CursorQueryBuilder, SpeechQueryBuilder } = this.sdk.sdkModule;
    const promisify = this.sdk.promisify;
    const view = this.standardView;
    const range = view;

    const speechView = this.speechView;
    const sentenceQuery = SpeechQueryBuilder.fromBounds(CursorQueryBuilder.fromCursor(range.start), CursorQueryBuilder.fromCursor(range.end));

    const result = await promisify(speechView.getSpeech.bind(speechView))(sentenceQuery);

    return result.sentences;
  };

  private sentenceToSentenceMark = (sentence: SpeechSentence): SentenceMark => {
    const CursorQueryBuilder = this.sdk.sdkModule.CursorQueryBuilder;
    const overlayRanges = this.overlayProvider.getOverlayRanges(sentence.text);
    return {
      playFromHere: () => {
        return this.playbackInfo.playFromQuery(CursorQueryBuilder.fromCursor(sentence.text.start));
      },
      rects: overlayRanges.flatMap(range => this.overlayRangeToRects(range, this.getBaseRect()))
    };
  };

  private _clickToListenAbortController: AbortController | null = null;
  public override getRelevantClickToListenSentenceMark = async (x: number, y: number): Promise<SentenceMark | null> => {
    if (this._clickToListenAbortController) {
      this._clickToListenAbortController.abort();
    }
    this._clickToListenAbortController = new AbortController();
    const { signal } = this._clickToListenAbortController;

    if (signal.aborted) {
      return null;
    }

    const sentences = await this.getSentences();

    for (const sentence of sentences) {
      if (signal.aborted) {
        return null;
      }

      const overlayRanges = this.overlayProvider.getOverlayRanges(sentence.text);
      const clientRects = overlayRanges.flatMap(range => {
        if (signal.aborted) {
          return [];
        }
        return this.overlayRangeToRects(
          range,
          // We do comparison within the same client coordinate space and avoid any transformations
          new DOMRect(0, 0, 0, 0)
        );
      });

      if (clientRects.some(rect => x >= rect.left && x <= rect.right && y >= rect.top && y <= rect.bottom)) {
        return this.sentenceToSentenceMark(sentence);
      }
    }

    return null;
  };

  private _callbacks: ((visibleSpeechSentenceMarks: SentenceMark[]) => void)[] = [];
  public listenToLatestVisibleSentenceMarks = (callback: (visibleSpeechSentenceMarks: SentenceMark[]) => void): (() => void) => {
    this._callbacks.push(callback);
    return () => {
      this._callbacks = this._callbacks.filter(c => c !== callback);
    };
  };

  override getCurrentPlaybackCursorPosition(): PlaybackCursorPosition {
    // no-op, this is used for re-enable auto scroll
    return 'notFound';
  }

  destroy(): void {
    this._cleanUpFunctions.forEach(cleanUpFunction => cleanUpFunction());
    this._cleanUpFunctions = [];
  }
}
