import type { IRecord } from 'interfaces/library';

import { ContentMetaType } from '../../base';
import { BaseItemListenableContent } from '../BaseItemListenableContent';

export class UnknownItemListenableContent extends BaseItemListenableContent {
  public readonly metaType: ContentMetaType = ContentMetaType.UNKNOWN;
  constructor(item: IRecord) {
    super(item);
  }
}
