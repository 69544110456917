import { firebaseAuth } from 'lib/firebase/firebase.client';
import { User } from 'lib/speechify/auth';
import { createNonPersistentStore } from 'lib/zustand/store';

import { currentUserEmitter } from './authUserEmitter';

type AuthStateLoading = {
  isLoading: true;
  user: null | undefined;
};

type AuthStateReady = {
  isLoading: false;
  user: User | null | undefined;
};

export type AuthStoreState = AuthStateLoading | AuthStateReady;

const createAuthStore = () => {
  const store = createNonPersistentStore<AuthStoreState>(
    () => {
      return {
        isLoading: true,
        user: null
      };
    },
    {
      isListeningScreenStore: false
    }
  );

  firebaseAuth.authStateReady().then(() => {
    store.setState({
      isLoading: false,
      user: firebaseAuth.currentUser
    });
  });

  firebaseAuth.onAuthStateChanged(user => {
    store.setState({
      isLoading: false,
      user: user || null
    });
  });

  currentUserEmitter.on('onUserUpdate', () => {
    store.setState(state => {
      return {
        isLoading: false,
        user: firebaseAuth.currentUser || state.user
      };
    });
  });

  return store;
};

export const useAuthStore = createAuthStore();
