import {
  AbstractLocalSpeechSynthesisAdapter,
  AbstractLocalSpeechSynthesisPlayer,
  LocalSpeechSynthesisEvent,
  LocalSpeechSynthesisPlayer,
  LocalSynthesisUtterance,
  LocalSynthesisVoice,
  VoiceRef
} from '@speechifyinc/multiplatform-sdk/api/adapters/localsynthesis';
import { VoiceGender } from '@speechifyinc/multiplatform-sdk/api/audio';
import { Result, SDKError } from '@speechifyinc/multiplatform-sdk/api/util';

import { getLocalVoicePromise } from 'modules/voices/utils/localVoices';

import { callbackFromAsync } from './lib/callbackFromAsync';
import { Callback } from './lib/typeAliases';

function toLocalVoice(v: SpeechSynthesisVoice): LocalSynthesisVoice {
  return new LocalSynthesisVoice(v.voiceURI, v.name, v.lang, VoiceGender.MALE, new VoiceRef(v));
}

export class WebLocalSpeechSynthesisAdapter extends AbstractLocalSpeechSynthesisAdapter {
  override createLocalSpeechSynthesisPlayer(callback: (p0: Result<LocalSpeechSynthesisPlayer>) => void): void {
    callbackFromAsync(async () => new WebLocalSpeechSynthesisPlayer(), callback);
  }

  getAllVoices = (callback: Callback<LocalSynthesisVoice[]>) => {
    getLocalVoicePromise()
      // We exclude Google Voices because they don't support word boundary events
      .then(voices => voices.filter(voice => !voice.voiceURI.startsWith('Google')).map(toLocalVoice))
      .then(voices => callback(new Result.Success(voices)));
  };
}

export class WebLocalSpeechSynthesisPlayer extends AbstractLocalSpeechSynthesisPlayer {
  speak(utterance: LocalSynthesisUtterance, callback: Callback<LocalSpeechSynthesisEvent>): void {
    const utterThis = new SpeechSynthesisUtterance(utterance.text);
    utterThis.voice = utterance.voice.source.get;
    utterThis.onstart = () => callback(new Result.Success(LocalSpeechSynthesisEvent.Started));
    utterThis.onpause = () => callback(new Result.Success(LocalSpeechSynthesisEvent.Paused));
    utterThis.onend = () => callback(new Result.Success(LocalSpeechSynthesisEvent.Ended));
    utterThis.onerror = e => callback(new Result.Failure(new SDKError.OtherMessage(e.error)));
    utterThis.onboundary = ({ charIndex }) => callback(new Result.Success(new LocalSpeechSynthesisEvent.Progressed(charIndex)));
    utterThis.rate = utterance.options.speed;
    utterThis.volume = utterance.options.volume;

    window.speechSynthesis.speak(utterThis);
  }

  pause(): void {
    window.speechSynthesis.pause();
  }

  resume(): void {
    window.speechSynthesis.resume();
  }

  cancel(): void {
    window.speechSynthesis.cancel();
  }
}
