export const getLocalVoicePromise = () => {
  const timeout = new Promise<SpeechSynthesisVoice[]>(resolve => {
    setTimeout(() => {
      resolve([]);
    }, 2000);
  });

  const localVoicesPromise = new Promise<SpeechSynthesisVoice[]>(resolve => {
    const voices = speechSynthesis.getVoices();

    if (voices.length > 0) resolve(voices);

    speechSynthesis.addEventListener(
      'voiceschanged',
      () => {
        resolve(speechSynthesis.getVoices());
      },
      { once: true }
    );
  });

  return Promise.race([localVoicesPromise, timeout]);
};
