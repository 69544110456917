import { AnyAction, Dispatch } from '@reduxjs/toolkit';

import * as speechify from 'lib/speechify';
import { currentUserEmitter } from 'modules/auth/store/authUserEmitter';
import { actions as authActions } from 'store/auth';
import { setCustomAccountSetting } from 'utils/baseAccountSettings';
import { getExtensionId, getSettings } from 'utils/extension';

const authMiddleware =
  ({ dispatch, getState }: $TSFixMe) =>
  (next: Dispatch<AnyAction>) =>
  async (action: $TSFixMe) => {
    const state = getState();

    let shouldEmitUserUpdate = false;

    switch (action.type) {
      case authActions.setUser.fulfilled.type: {
        const uid = action.meta?.arg?.uid;

        if (uid) {
          // subscribe to any subscription or entitlement changes
          speechify.subscriptionService.addEntitlementsChangeListener(result => {
            if (result instanceof speechify.Success) {
              const entitlements = speechify.parseUnserializables(speechify.toPOJO(result.value));
              dispatch(authActions.setEntitlements(entitlements));
            }
          });

          speechify.subscriptionService.addSubscriptionChangeListener(result => {
            if (result instanceof speechify.Success) {
              if (result.value?.plan?.productTypes.includes('tts')) {
                const subscription = speechify.parseUnserializables(speechify.toPOJO(result.value));
                dispatch(authActions.setSubscription(subscription));
              }
            }
          });

          if (!state.auth.user?.mobileAppInstalled) {
            const data = await speechify.getUserUsageData();
            const mobileAppInstalled = !!(data?.android?.lastVersion || data?.ios?.lastVersion);
            dispatch(authActions.setMobileAppInstalled(mobileAppInstalled));
          }

          // extension settings
          const extensionSettings = await getSettings();

          if (extensionSettings) {
            dispatch(authActions.setExtensionInstalled(true));
            dispatch(authActions.setExtensionSettings(extensionSettings));
          } else {
            dispatch(authActions.setExtensionInstalled(false));
          }
        }

        shouldEmitUserUpdate = true;
        break;
      }

      case authActions.setBillingDashboardUrl.type: {
        const twentyMinutes = 1000 * 60 * 10 * 2;

        setTimeout(async () => {
          const extensionId = await getExtensionId();
          const billingDashboardUrl = await speechify.getBillingDashboardUrl(new speechify.BillingDashboardOptions(extensionId));
          dispatch(authActions.setBillingDashboardUrl(billingDashboardUrl));
        }, twentyMinutes);

        break;
      }

      case authActions.setExtensionInstalled.type: {
        if (!action.payload && !state.auth.user?.extensionInstalled) {
          const fiveMinutes = 1000 * 60 * 5;
          const uid = state.auth.user?.uid;

          if (uid) {
            const timeout = setTimeout(async () => {
              const extensionSettings = await getSettings();

              if (extensionSettings) {
                dispatch(authActions.setExtensionInstalled(true));
                dispatch(authActions.setExtensionSettings(extensionSettings));
                setCustomAccountSetting('extensionInstalled', 'true');
                clearTimeout(timeout);
              }
            }, fiveMinutes);
          }
        }
        break;
      }

      case authActions.setMobileAppInstalled.type: {
        if (!action.payload && !state.auth.user?.mobileAppInstalled) {
          const fiveMinutes = 1000 * 60 * 5;
          const uid = state.auth.user?.uid;

          if (uid) {
            setTimeout(async () => {
              const data = await speechify.getUserUsageData();
              const mobileAppInstalled = !!(data?.android?.lastVersion || data?.ios?.lastVersion);
              dispatch(authActions.setMobileAppInstalled(mobileAppInstalled));
              setCustomAccountSetting('mobileAppInstalled', 'true');
            }, fiveMinutes);
          }
        }
        break;
      }

      case authActions.setSubscription.type:
      case authActions.setEntitlements.type:
      case authActions.setUsageStats.type:
      case authActions.setWordsLeft.type:
      case authActions.setFirstPdfDocument.type:
        shouldEmitUserUpdate = true;
        break;
    }

    const result = next(action);
    if (shouldEmitUserUpdate) {
      const updatedState = getState();
      currentUserEmitter.emit('onUserUpdate', { user: updatedState.auth.user });
    }

    return result;
  };

export default authMiddleware;
