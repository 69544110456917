import * as React from 'react';

function CloseIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.874 3.46a1 1 0 10-1.415 1.414L8.586 10l-5.127 5.126a1 1 0 101.415 1.414L10 11.414l5.126 5.126a1 1 0 001.414-1.414L11.414 10l5.126-5.126a1 1 0 00-1.414-1.415L10 8.586 4.874 3.459z"
        fill="currentColor"
      />
    </svg>
  );
}

export default CloseIcon;
