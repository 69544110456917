import * as React from 'react';

function DarkModeIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.317 6.125a7.335 7.335 0 0110.026-2.681c-1.427.487-2.696 1.52-3.505 3.015-1.725 3.188-.701 7.264 2.288 9.105.394.243.803.435 1.22.578A7.335 7.335 0 014.317 6.126z"
        className="fill-icon-secondary"
      />
      <path
        d="M14.338 3.44l.5-.866-.5.866zM4.318 6.125l-.867-.5.866.5zm10.025-2.681l.323.946a1 1 0 00.178-1.812l-.5.866zm-3.505 3.015l-.88-.476.88.476zm2.288 9.105l-.525.852.524-.852zm1.22.578l.5.866a1 1 0 00-.176-1.812l-.324.946zm-7.344.003l.5-.866-.5.866zm7.836-13.57A8.335 8.335 0 003.45 5.624l1.732 1a6.335 6.335 0 018.655-2.319l1-1.732zm.006.003l-.006-.004-1 1.732.005.003 1.001-1.73zm-.824-.08c-1.668.569-3.134 1.772-4.061 3.485l1.759.952c.69-1.275 1.76-2.14 2.948-2.545l-.646-1.893zM9.96 5.982c-1.965 3.63-.822 8.3 2.642 10.433l1.049-1.703c-2.514-1.548-3.419-5.032-1.932-7.778l-1.76-.952zM12.6 16.416c.458.281.934.505 1.421.672l.648-1.892a5.086 5.086 0 01-1.02-.483L12.6 16.416zm-6.099.595a8.332 8.332 0 008.345-.003l-1.001-1.732a6.333 6.333 0 01-6.344.003l-1 1.732zM3.452 5.625a8.335 8.335 0 003.05 11.386l1-1.732a6.335 6.335 0 01-2.319-8.654l-1.732-1z"
        className="fill-icon-secondary"
      />
    </svg>
  );
}

export default DarkModeIcon;
