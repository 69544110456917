import { Switch as HeadlessSwitch } from '@headlessui/react';
import React from 'react';

import { cn } from 'utils/cn';

import LockIcon from './LockIcon';

interface SwitchProps {
  checked: boolean;
  locked?: boolean;
  onChange: (checked: boolean) => void;
}

const Switch = ({ checked, onChange, locked = false }: SwitchProps) => {
  const isChecked = !locked && checked;

  return (
    <HeadlessSwitch checked={isChecked} disabled={locked} onChange={onChange}>
      <span className={cn('h-6 w-10 rounded-full flex p-spl-2 bg-bg-tertiary', isChecked && 'bg-bg-secondary-cta')}>
        <span
          className={cn(
            'h-full block relative transition w-1/2 rounded-full duration-300 ease-in-out transform bg-surface-primary',
            isChecked && 'translate-x-full'
          )}
        >
          {locked && <LockIcon />}
        </span>
      </span>
    </HeadlessSwitch>
  );
};

export default Switch;
