import { createSlice } from '@reduxjs/toolkit';

import type { IRouter } from '../../interfaces/router';

const name = 'router';

const initialState: IRouter = {
  navigationStart: performance.timeOrigin,
  clientNavigation: false
};

const { actions: generatedActions, reducer } = createSlice({
  name,
  initialState,
  reducers: {
    navigate: state => {
      state.navigationStart = performance.now();
      state.clientNavigation = true;
    }
  }
});

const actions = { ...generatedActions };
const selectors = {};

export { actions, selectors };

export default reducer;
