function TextHighlightingIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.082 2C4.485 2 4 2.485 4 3.083V5.8a.901.901 0 001.784.185l.38-2.27h2.979v8.357l-1.571.148A.893.893 0 007.669 14h4.687a.893.893 0 00.097-1.78l-1.596-.15V3.715h2.978l.381 2.271A.901.901 0 0016 5.8V3.083C16 2.485 15.515 2 14.918 2H5.082zM4 16a1 1 0 100 2h12a1 1 0 100-2H4z"
        className="fill-icon-secondary"
      />
    </svg>
  );
}

export default TextHighlightingIcon;
