export function bootIntercom({ name, email, userId }: { name?: string | null; email?: string | null; userId?: string | null }) {
  const intercom = window['Intercom'];

  if (intercom && userId) {
    const config = {
      app_id: process.env.NEXT_PUBLIC_INTERCOM_APPID,
      email: email || undefined,
      name: name || undefined,
      user_id: userId,
      vertical_padding: 80,
      hide_default_launcher: true
    } as Intercom_.IntercomSettings;
    intercom('boot', config);
  }
}
