export const easing = ({
  elapsedTime,
  initialScrollTop,
  change,
  duration
}: {
  elapsedTime: number;
  initialScrollTop: number;
  change: number;
  duration: number;
}) => {
  if ((elapsedTime /= duration / 2) < 1) {
    return (change / 2) * elapsedTime * elapsedTime + initialScrollTop;
  }

  return (-change / 2) * (--elapsedTime * (elapsedTime - 2) - 1) + initialScrollTop;
};

export const isMouseOverScrollbar = (element: HTMLElement, event: MouseEvent) => {
  const rect = element.getBoundingClientRect();
  const scrollbarWidth = element.offsetWidth - element.clientWidth;
  const isOverScrollbar = event.clientX > rect.right - scrollbarWidth;
  return isOverScrollbar;
};

// TODO(albertusdev): Refactor and move this into a more generic utils
// A more ergonomic addEventListener that automatically returns the removeEventListener function
export function addEventListener(window: Window, ...args: Parameters<Window['addEventListener']>): () => void;
export function addEventListener(element: HTMLElement, ...args: Parameters<HTMLElement['addEventListener']>): () => void;
export function addEventListener(target: Window | HTMLElement, ...args: Parameters<HTMLElement['addEventListener'] | Window['addEventListener']>) {
  const [eventName, listener, options] = args;
  target.addEventListener(eventName, listener, options);
  return () => target.removeEventListener(eventName, listener, options);
}

export const getAutoScrollAnchor = () => {
  return window.innerHeight * 0.25;
};
