export const INITIAL_PLAYBACK_RELATIVE_SPEED = 1.1;
export const BASE_FACTOR_SPEED_WPM = 200;

export const MINIMUM_WPM = 80;
export const MINIMUM_RELATIVE_SPEED = 0.4;

export const MAXIMUM_FREE_WPM = 300;
export const MAXIMUM_PREMIUM_WPM = 900;

export const MAXIMUM_FREE_RELATIVE_SPEED = 1.5;
export const MAXIMUM_PREMIUM_RELATIVE_SPEED = 4.5;
