import { IndividualSettingToggleImplementation } from '../../settings';

const toggleDarkModeSetting: IndividualSettingToggleImplementation<'darkMode'> = value => {
  if (value) {
    document.documentElement.classList.add('dark');
    document.body.style.backgroundColor = '#14202E';
  } else {
    document.documentElement.classList.remove('dark');
    document.body.style.backgroundColor = '#F1F4F9';
  }
};

export default toggleDarkModeSetting;
