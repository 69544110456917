import { MeasurementKey } from 'modules/profiling/measurementTypes';
import { profilingStoreActions } from 'modules/profiling/profilingStore';

import { useVoiceStore } from '../voicesStore';

export const fetchInitialVoice = async () => {
  await profilingStoreActions.measureAction(() => useVoiceStore.waitForInitialHydration(), MeasurementKey.zustandIdbHydration, {
    storeName: 'voicesStore'
  });
  return useVoiceStore.getState().lastPlayingVoice;
};
