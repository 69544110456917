import type { VoiceList as VoiceListResponse } from '@speechifyinc/centralized-voice-list';

export enum LocalStorageCacheKey {
  cvl = 'cvl'
}

export type CacheContent<T extends object> = {
  data: T;
  timestamp: number;
};

export type AllCacheContent = {
  [LocalStorageCacheKey.cvl]: CacheContent<VoiceListResponse>;
};

export type CacheContentType<Key extends LocalStorageCacheKey> = AllCacheContent[Key];

export type CacheData<Key extends LocalStorageCacheKey> = CacheContentType<Key>['data'];

export class LocalStorageCache<Key extends LocalStorageCacheKey & string> {
  private prefix = 'speechify_cache_';

  constructor(
    private key: Key,
    private ttlInMs: number
  ) {}

  private serialize(dataWithTimestamp: CacheContentType<Key>): string {
    return JSON.stringify(dataWithTimestamp);
  }

  private deserialize(dataWithTimestampString: string): CacheContentType<Key> {
    return JSON.parse(dataWithTimestampString);
  }

  async retrieve(): Promise<
    | (CacheContentType<Key> & {
        isStale: boolean;
      })
    | null
  > {
    const item = localStorage.getItem(this.prefix + this.key);
    if (!item) return null;
    const { data, timestamp } = this.deserialize(item);
    const isStale = Date.now() - timestamp > this.ttlInMs;
    return { data, timestamp, isStale };
  }

  async store(data: CacheData<Key>): Promise<void> {
    const item = this.serialize({ data, timestamp: Date.now() });
    localStorage.setItem(this.prefix + this.key, item);
  }

  async clear(): Promise<void> {
    localStorage.removeItem(this.prefix + this.key);
  }
}
