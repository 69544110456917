import { usePlaybackStore } from 'modules/listening/stores/playback/playbackStore';
import { PDFOverlayInfo } from 'modules/sdk/lib';

import { handleOnSearchIconClickForPDF } from './pdfSearch';

export const handleOnSearchIconClick = () => {
  const currentOverlayInfo = usePlaybackStore.getState().currentOverlayInfo;
  if (currentOverlayInfo instanceof PDFOverlayInfo) {
    handleOnSearchIconClickForPDF();
  } else {
    // TODO(overhaul): Implement search for classic experience
  }
};
