import { useEffect, useState } from 'react';

import { BaseListenableContent } from '../lib/facade/listenableContent/base';

export const useLatestListenableContentTitle = (listenableContent: BaseListenableContent) => {
  const [title, setTitle] = useState(listenableContent.title);

  useEffect(() => {
    return listenableContent.listenToTitleUpdate('onTitleUpdate', setTitle);
  }, [listenableContent]);

  return title;
};
