import * as React from 'react';

function BracketsIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 4a2 2 0 012-2h2a1 1 0 010 2H6v12h2a1 1 0 110 2H6a2 2 0 01-2-2V4zm7-1a1 1 0 011-1h2a2 2 0 012 2v12a2 2 0 01-2 2h-2a1 1 0 110-2h2V4h-2a1 1 0 01-1-1z"
        className="fill-icon-secondary"
      />
    </svg>
  );
}

export default BracketsIcon;
