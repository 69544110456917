import { logUpgradeButtonAnalyticsEvent } from 'modules/analytics/impl/upgradeButtonAnalytics';
import { UpsellModalType } from 'modules/upsells/stores/types';
import { formatUpsellModalTypeAsString } from 'modules/upsells/utils';

import { UpgradeSource } from './UpgradeSource';

export const getUpgradeURL = (source: string): string => `${process.env.NEXT_PUBLIC_SAME_DOMAIN_ONBOARDING_URL}/onboarding/sso/?source=${source}`;

export const onUpgradeButtonClick = (source: UpgradeSource, variant?: UpsellModalType): void => {
  const onboardingSource = `${source}${variant ? `_${formatUpsellModalTypeAsString(variant)!.toLowerCase().replaceAll(' ', '_')}` : ''}`;
  window.open(getUpgradeURL(onboardingSource), '_blank');
  logEventOnUpgradeButtonClick(source, variant);
};

export const logEventOnUpgradeButtonClick = (source: UpgradeSource, variant?: UpsellModalType): void => {
  logUpgradeButtonAnalyticsEvent(source, formatUpsellModalTypeAsString(variant));
};
