import * as React from 'react';

function AutoScrollIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} viewBox="0 0 20 20" fill="none" {...props}>
      <rect opacity={0.3} x={16.6667} y={4.16666} width={1.66667} height={13.3333} rx={0.833334} fill="#8791A0" />
      <path
        d="M5 13.333c0-.46.373-.833.833-.833h5a.833.833 0 110 1.667h-5A.833.833 0 015 13.333zM5.833 9.167a.833.833 0 000 1.666h2.5a.833.833 0 100-1.666h-2.5z"
        className="fill-icon-secondary"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.167 1.667a2.5 2.5 0 00-2.5 2.5v11.21c0 1.129.764 2.161 1.922 2.397 3.166.647 6.323.647 9.488 0C14.235 17.538 15 16.506 15 15.378V4.167a2.5 2.5 0 00-2.5-2.5H4.167zm-.834 2.5c0-.46.373-.834.834-.834H12.5c.46 0 .833.373.833.834v11.21c0 .39-.26.697-.59.764a21.788 21.788 0 01-8.82 0c-.33-.067-.59-.373-.59-.763V4.167z"
        className="fill-icon-secondary"
      />
      <path d="M17.5 2.5a.833.833 0 00-.833.833v5a.833.833 0 101.666 0v-5A.833.833 0 0017.5 2.5z" className="fill-icon-secondary" />
    </svg>
  );
}

export default AutoScrollIcon;
