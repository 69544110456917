const RecognizedErrors = {
  'Library Item bundling not supported for items with multiple pages':
    'We are currently working on adding support for scanned items on the Web App. Please use your mobile device in the meanwhile to continue reading them. '
};

export const mapErrorToMessage = (message: string) => {
  // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  return RecognizedErrors[message] ?? 'We are having trouble loading this item at the moment. Please try again later.';
};

export class ErrorWithContext extends Error {
  constructor(
    message: string,
    public context: Record<string, string>
  ) {
    super(message);
  }
}
