import * as React from 'react';

function RenameIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={21} height={20} viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.298 5.028l-.736-.736a.5.5 0 00-.704-.002l-1.256 1.236 1.438 1.438 1.254-1.225a.5.5 0 00.004-.71zM4.666 14.321l7.51-7.392 1.434 1.433-7.655 7.481a.5.5 0 01-.349.143h-1.09v-1.309a.5.5 0 01.15-.356zm12.31-11.443a2.5 2.5 0 00-3.521-.014L3.263 12.895a2.5 2.5 0 00-.747 1.782v2.475c0 .46.373.834.834.834h2.256a2.5 2.5 0 001.747-.713L17.691 7.17a2.5 2.5 0 00.02-3.556l-.735-.736zM11 16a1 1 0 100 2h6.5a1 1 0 000-2H11z"
        fill="currentColor"
      />
    </svg>
  );
}

export default RenameIcon;
