import React from 'react';
const CanvasLmsIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width={36} height={36} viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_10201_4015)" fill="#E72429">
        <path d="M5.223 17.962A5.206 5.206 0 00.653 12.8a20.69 20.69 0 000 10.32 5.204 5.204 0 004.57-5.158zM8.247 16.329a1.634 1.634 0 100 3.268 1.634 1.634 0 000-3.268zM30.777 17.962a5.2 5.2 0 004.57 5.159 20.689 20.689 0 000-10.321 5.21 5.21 0 00-4.57 5.162zM27.743 16.329a1.633 1.633 0 10-.006 3.267 1.633 1.633 0 00.006-3.267zM17.965 30.782a5.207 5.207 0 00-5.16 4.564c3.386.872 6.938.872 10.324 0a5.2 5.2 0 00-5.164-4.564zM17.968 26.123a1.634 1.634 0 00-1.159 2.787 1.633 1.633 0 101.16-2.787zM17.968 5.218a5.201 5.201 0 005.16-4.564 20.708 20.708 0 00-10.323 0 5.204 5.204 0 005.163 4.564zM17.968 6.614a1.634 1.634 0 10-.005 3.268 1.634 1.634 0 00.005-3.268zM27.017 27.01a5.206 5.206 0 00-.424 6.873 20.662 20.662 0 007.298-7.298 5.207 5.207 0 00-6.873.424zM23.723 23.715a1.633 1.633 0 102.308 2.31 1.633 1.633 0 00-2.308-2.31zM8.943 8.94a5.195 5.195 0 00.424-6.871A20.642 20.642 0 002.07 9.364a5.198 5.198 0 006.874-.424zM9.928 9.928a1.633 1.633 0 102.308 2.309 1.633 1.633 0 00-2.308-2.31zM27.006 8.923a5.207 5.207 0 006.877.416 20.629 20.629 0 00-7.301-7.287 5.203 5.203 0 00.424 6.87zM26.02 12.217a1.63 1.63 0 10-2.306-2.305 1.63 1.63 0 002.306 2.305zM8.934 26.99a5.207 5.207 0 00-6.873-.425 20.662 20.662 0 007.297 7.298 5.2 5.2 0 00-.424-6.874zM9.908 23.695a1.633 1.633 0 102.309 2.31 1.633 1.633 0 00-2.309-2.31z" />
      </g>
      <defs>
        <clipPath id="clip0_10201_4015">
          <path fill="#fff" d="M0 0H36V36H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CanvasLmsIcon;
