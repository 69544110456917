const KEY = 'localPreferences';

export type localPreferences = {
  shownMigration?: string;
  shownQrCode?: string;
  shownQrCodeV2?: string;
  onboarded?: string;
  webapp_install_mobile_app_with_qr_code?: string;
  feature_flags?: string;
  shownUnlimitedSpeedPopup?: string;
};

export function updateLocalPreferences(preferences: localPreferences) {
  const currentPreferencesString = localStorage.getItem(KEY);
  // @ts-expect-error TS(2345): Argument of type 'string | null' is not assignable... Remove this comment to see the full error message
  const currentPreferences = JSON.parse(currentPreferencesString) as localPreferences;
  const updatedPrefereces = { ...currentPreferences, ...preferences };

  localStorage.setItem(KEY, JSON.stringify(updatedPrefereces));
}

export function getLocalPreference(preference?: keyof localPreferences) {
  const currentPreferencesString = localStorage.getItem(KEY) || '{}';
  const currentPreferences = JSON.parse(currentPreferencesString) as localPreferences;

  return preference ? currentPreferences?.[preference] : currentPreferences;
}
