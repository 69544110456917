import { createSlice } from '@reduxjs/toolkit';

import { IGamification } from 'interfaces/gamification';
import { Timestamp } from 'lib/speechify';
import { setDailyListeningGoal, setStreakGoal, subscribe, unsubscribe } from 'store/gamification/actions';

import { POSSIBLE_STREAK_GOALS_IN_DAYS } from './constants';
import * as selectors from './selectors';

export type GamificationStoreState = {
  gamification: IGamification | null;
  isEnabled: boolean;
  isLoading: boolean;
  subscribedUserId?: string;

  // local state before saving to server
  selectedDailyListeningGoal: number;
};

const overrideCurrentStreak = (state: IGamification): IGamification['currentStreak'] => {
  // Return 0 if the difference between the current date and the last streak change date is more than 1 day
  const lastModifiedCurrentStreak = new Timestamp(state.lastModifiedCurrentStreak?.seconds ?? 0, state.lastModifiedCurrentStreak?.nanoseconds ?? 0).toDate();
  const currentTime = new Date();

  // set both dates to midnight
  lastModifiedCurrentStreak.setHours(0, 0, 0, 0);
  currentTime.setHours(0, 0, 0, 0);

  const differenceInDays = (currentTime.getTime() - lastModifiedCurrentStreak.getTime()) / (1000 * 3600 * 24);
  if (differenceInDays > 1) {
    return 0;
  }
  return state.currentStreak || 0;
};

const overrideStreakGoalInDays = (state: IGamification): IGamification['streakGoalInDays'] => {
  // Return lowest streak goal if current streak is more than 1 day and streakGoalInDays has not been set
  // @ts-expect-error TS(2532): Object is possibly 'undefined'.
  if (state.currentStreak > 1 && !state.streakGoalInDays) {
    return POSSIBLE_STREAK_GOALS_IN_DAYS[0].value;
  }
  return state.streakGoalInDays;
};

const name = 'gamification';
const initialState: GamificationStoreState = {
  gamification: null, //
  isLoading: true,
  selectedDailyListeningGoal: 2,
  isEnabled: false,
  // @ts-expect-error TS(2322): Type 'null' is not assignable to type 'string | un... Remove this comment to see the full error message
  subscribedUserId: null
};

const { actions: generatedActions, reducer } = createSlice({
  name,
  initialState,
  reducers: {
    clear: state => {
      state.gamification = null;
      // @ts-expect-error TS(2322): Type 'null' is not assignable to type 'string | un... Remove this comment to see the full error message
      state.subscribedUserId = null;
      state.isLoading = false;
      state.isEnabled = false;
    },
    set: (state, action) => {
      state.isEnabled = true;
      state.gamification = action.payload;

      if (state.gamification) {
        state.gamification.currentStreak = overrideCurrentStreak(state.gamification);
        state.gamification.streakGoalInDays = overrideStreakGoalInDays(state.gamification);
        state.gamification.todayListeningDurationInMinutes = state.gamification.todayListeningDurationInMinutes ?? 0;
        state.selectedDailyListeningGoal = state.gamification.dailyListeningGoalInMinutes || 2;
      }

      state.isLoading = false;
    },
    setSelectedDailyListeningGoal: (state, action) => {
      state.selectedDailyListeningGoal = action.payload;
    },
    enableGamification: state => {
      state.isEnabled = true;
    },
    setSubscribedUserId: (state, action) => {
      state.subscribedUserId = action.payload;
    }
  },
  // ESLint: 'builder' is defined but never used
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  extraReducers: builder => {}
});

const actions = { ...generatedActions, subscribe, unsubscribe, setDailyListeningGoal, setStreakGoal };

const gamificationActions = actions;

export { actions, gamificationActions, selectors };

export default reducer;
