import * as React from 'react';

function ZoomInIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 11a7 7 0 11-14 0 7 7 0 0114 0zm-1.382 7.032a9 9 0 111.414-1.414l3.176 3.175a1 1 0 01-1.414 1.414l-3.176-3.175zM10.941 7a1 1 0 011 1v1.974H14a1 1 0 110 2h-2.059V14a1 1 0 11-2 0v-2.026H8a1 1 0 110-2h1.941V8a1 1 0 011-1z"
        className="fill-icon-primary"
      />
    </svg>
  );
}

export default ZoomInIcon;
