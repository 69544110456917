import { useMemo } from 'react';

import { usePlaybackStore } from 'modules/listening/stores/playback/playbackStore';
import { subscriptionStoreSelectors } from 'modules/subscription/stores/selectors';
import { useSubscriptionStore } from 'modules/subscription/stores/subscriptionStore';

import { useVoiceStore } from '../stores/voicesStore';
import { VoiceItemUIProp } from '../types';
import { mapVoiceInfoToVoiceItemUI } from '../utils/mapVoiceInfoToVoiceItemUI';
import { groupVoicesByLanguage } from '../utils/utils';

const regionNamesInEnglish = new Intl.DisplayNames('en', { type: 'region' });
const languageNamesInEnglish = new Intl.DisplayNames('en', { type: 'language' });

const languageNameCache = new Map<string, string | null>();
const regionNameCache = new Map<string, string | null>();

const getLanguageNameInEnglish = (languageCodeWithLocale: string) => {
  if (languageNameCache.has(languageCodeWithLocale)) {
    return languageNameCache.get(languageCodeWithLocale);
  }
  try {
    const languageName = languageNamesInEnglish.of(languageCodeWithLocale);
    languageNameCache.set(languageCodeWithLocale, languageName ?? null);
    return languageName ?? null;
  } catch (e) {
    languageNameCache.set(languageCodeWithLocale, null);
    return null;
  }
};

const getRegionNameInEnglish = (languageCodeWithLocale: string) => {
  if (regionNameCache.has(languageCodeWithLocale)) {
    return regionNameCache.get(languageCodeWithLocale);
  }
  try {
    const regionName = regionNamesInEnglish.of(languageCodeWithLocale);
    regionNameCache.set(languageCodeWithLocale, regionName ?? null);
    return regionName ?? null;
  } catch (e) {
    regionNameCache.set(languageCodeWithLocale, null);
    return null;
  }
};

export const useVoiceByLanguage = (searchString: string | null) => {
  const voiceState = useVoiceStore();
  const playbackInfo = usePlaybackStore(s => s.currentPlaybackInfo);
  const hasHdWords = useSubscriptionStore(subscriptionStoreSelectors.getHasHdWords);

  const voiceByLanguage = useMemo(() => {
    const { personalVoices, allVoices } = voiceState;
    const voiceSpecsByLanguage = groupVoicesByLanguage([...personalVoices, ...allVoices]);

    const voicesByLanguage: Record<string, VoiceItemUIProp[]> = {};
    for (const [languageCode, voices] of Object.entries(voiceSpecsByLanguage)) {
      voicesByLanguage[languageCode] = voices
        .filter(v => {
          if (!searchString) return true;
          const searchStringCaseInsensitive = searchString.toLowerCase();

          if (v.displayName.toLowerCase().includes(searchStringCaseInsensitive)) {
            return true;
          }

          const languageName = getLanguageNameInEnglish(v.languageCodeWithLocale);
          if (languageName?.toLowerCase().includes(searchStringCaseInsensitive)) {
            return true;
          }

          if (v.country) {
            const regionName = getRegionNameInEnglish(v.country);
            if (regionName?.toLowerCase().includes(searchStringCaseInsensitive)) {
              return true;
            }
          }

          if (v.labels?.some(label => label.toLowerCase().includes(searchStringCaseInsensitive))) {
            return true;
          }

          if (v.gender.toLowerCase().includes(searchStringCaseInsensitive)) {
            return true;
          }
          return false;
        })
        .map(v => mapVoiceInfoToVoiceItemUI({ voiceInfo: v, playbackInfo, hasHdWords, isPreviewPlaying: voiceState.isPreviewPlaying }));
    }
    return voicesByLanguage;
  }, [voiceState, playbackInfo, searchString, hasHdWords]);

  return voiceByLanguage;
};
