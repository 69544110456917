import * as React from 'react';

function EnhancedSkippingIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
      <path
        d="M12.5838 8.57686L12.4639 8.62122C10.6834 9.28004 9.27968 10.6838 8.62086 12.4642L8.5765 12.5841C8.40981 13.0346 7.98028 13.3337 7.49996 13.3337C7.01964 13.3337 6.59011 13.0346 6.42342 12.5841L6.37906 12.4642C5.72024 10.6838 4.31647 9.28005 2.53603 8.62122L2.41614 8.57686C1.96568 8.41017 1.66663 7.98064 1.66663 7.50033C1.66663 7.02001 1.96568 6.59048 2.41614 6.42379L2.53603 6.37943C4.31647 5.72061 5.72024 4.31684 6.37906 2.5364L6.42342 2.41651C6.59011 1.96605 7.01964 1.66699 7.49996 1.66699C7.98028 1.66699 8.40981 1.96605 8.5765 2.41651L8.62086 2.5364C9.27968 4.31684 10.6834 5.72061 12.4639 6.37943L12.5838 6.42379C13.0342 6.59048 13.3333 7.02001 13.3333 7.50033C13.3333 7.98064 13.0342 8.41017 12.5838 8.57686Z"
        fill="url(#paint0_radial_23398_6430)"
      />
      <path
        d="M15 11.667C15.2744 11.667 15.5199 11.8379 15.6151 12.0953L15.6405 12.1638C16.0169 13.1812 16.8191 13.9833 17.8365 14.3598L17.905 14.3852C18.1624 14.4804 18.3333 14.7259 18.3333 15.0003C18.3333 15.2748 18.1624 15.5202 17.905 15.6155L17.8365 15.6408C16.8191 16.0173 16.0169 16.8195 15.6405 17.8369L15.6151 17.9054C15.5199 18.1628 15.2744 18.3337 15 18.3337C14.7255 18.3337 14.48 18.1628 14.3848 17.9054L14.3594 17.8369C13.983 16.8195 13.1808 16.0173 12.1634 15.6408L12.0949 15.6155C11.8375 15.5202 11.6666 15.2748 11.6666 15.0003C11.6666 14.7259 11.8375 14.4804 12.0949 14.3852L12.1634 14.3598C13.1808 13.9833 13.983 13.1812 14.3594 12.1638L14.3848 12.0953C14.48 11.8379 14.7255 11.667 15 11.667Z"
        fill="url(#paint1_radial_23398_6430)"
      />
      <defs>
        <radialGradient
          id="paint0_radial_23398_6430"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(-5.4167 -0.833006) rotate(44.6155) scale(43.9005)"
        >
          <stop offset="0.224035" stopColor="#EA6AFF" />
          <stop offset="0.664903" stopColor="#6B78FC" />
        </radialGradient>
        <radialGradient
          id="paint1_radial_23398_6430"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(-5.4167 -0.833006) rotate(44.6155) scale(43.9005)"
        >
          <stop offset="0.224035" stopColor="#EA6AFF" />
          <stop offset="0.664903" stopColor="#6B78FC" />
        </radialGradient>
      </defs>
    </svg>
  );
}

export default EnhancedSkippingIcon;
