import { createSelector, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'store';

const name = 'sidebar';

export type SidebarTabs = 'voice' | 'speed' | 'options' | 'report' | 'thumbnails' | 'search' | 'summarize';
type SidebarState = {
  currentTab?: SidebarTabs;
  isOpen: boolean;
  isLoading: boolean;
};

const initialState: SidebarState = {
  isOpen: false,
  isLoading: true
};

const { actions: generatedActions, reducer } = createSlice({
  name,
  initialState,
  reducers: {
    toggleTab: (state, action: { payload: SidebarTabs }) => {
      if (!state.currentTab) {
        state.isOpen = true;
        state.currentTab = action.payload;
        return;
      }

      if (state.currentTab === action.payload) {
        state.isOpen = !state.isOpen;
      } else {
        state.isOpen = true;
        state.currentTab = action.payload;
      }
    },
    openTab: (state, action: { payload: SidebarTabs }) => {
      state.isOpen = true;
      state.currentTab = action.payload;
    },
    closeTab: state => {
      state.isOpen = false;
    },
    setIsLoading: (state, { payload }: { payload: boolean }) => {
      state.isLoading = payload;
    }
  }
});

export const getCurrentTab = () =>
  // @ts-expect-error TS(2769): No overload matches this call.
  createSelector(
    (state: RootState) => state.sidebar.currentTab,
    (currentTab: SidebarTabs) => currentTab
  );

export const getIsSidebarOpen = () =>
  createSelector(
    (state: RootState) => state.sidebar.isOpen,
    (isOpen: boolean) => isOpen
  );

export const getIsSidebarLoading = () =>
  createSelector(
    (state: RootState) => state.sidebar.isLoading,
    (isLoading: boolean) => isLoading
  );

const actions = { ...generatedActions };

const selectors = {
  getCurrentTab,
  getIsSidebarOpen,
  getIsSidebarLoading
};

export { actions, selectors };

export default reducer;
