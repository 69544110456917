import React from 'react';

export function SpeechifyLogo(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="24" height="16" viewBox="0 0 24 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.44974 4.96208C6.05988 4.07046 6.589 3.6296 7.38119 3.8745C7.86056 4.02269 8.05734 4.63464 7.86056 5.40248C7.51457 6.75258 7.38119 7.52757 7.38119 8.44993C8.59809 5.89931 10.8476 2.50931 11.8226 1.36909C12.4964 0.580999 13.4623 0.681502 13.826 0.85729C14.4168 1.14292 14.448 1.76225 14.2441 2.35454C12.8332 6.45229 12.784 8.64083 12.6108 10.767C13.4877 8.40895 14.5538 6.19084 15.8412 4.62867C16.3836 3.8794 17.3344 3.64419 17.9349 3.88759C18.5354 4.13099 18.6722 4.71333 18.5354 5.44307C18.1784 7.34607 18.0013 8.23798 18.0013 8.97519C18.6534 8.47644 19.1726 8.05506 20.3597 7.95875C21.5468 7.86244 24 8.33505 24 8.33505C24 8.33505 22.5838 8.57568 21.8357 8.7591C20.3023 9.13515 19.7667 9.57492 18.7741 11.0288C18.4856 11.4512 18.0013 11.7755 17.4557 11.7343C16.9101 11.693 16.5437 11.3498 16.3836 10.8862C16.1612 10.2419 16.095 9.27822 16.3836 7.19267C14.9694 9.3444 14.2441 12.2672 13.275 14.2055C13.0273 14.7011 12.6041 15.2659 12.0307 15.2659C11.4572 15.2659 10.7136 15.0434 10.617 13.3396C10.3697 8.97519 11.1942 5.50209 11.1942 5.50209C9.58829 8.0484 9.07541 9.53265 8.5168 10.3066C7.95818 11.0806 7.41614 11.7508 6.7568 11.7343C6.09747 11.7177 5.71375 10.9806 5.63031 10.3066C5.54688 9.63261 5.52287 8.77179 5.76656 7.19267C5.13224 7.82699 4.57449 8.25658 3.57562 8.52559C2.57674 8.79459 1.38397 8.62337 0 8.33505C1.38397 8.33505 3.71298 7.50006 5.44974 4.96208Z"
        fill="#2137FC"
      />
    </svg>
  );
}
