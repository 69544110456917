import { createSlice } from '@reduxjs/toolkit';
import { Team } from 'interfaces';

import { updateDetails } from 'store/team/actions';

const name = 'team';
// @ts-expect-error TS(2322): Type 'null' is not assignable to type 'Team'.
const initialState: { team: Team; isLoading: boolean } = { team: null, isLoading: true };

const { actions: generatedActions, reducer } = createSlice({
  name,
  initialState,
  reducers: {
    clear: state => {
      // @ts-expect-error TS(2322): Type 'null' is not assignable to type 'WritableDra... Remove this comment to see the full error message
      state.team = null;
      state.isLoading = false;
    },
    set: (state, action) => {
      state.team = action.payload;
      state.isLoading = false;
    }
  },
  extraReducers: builder => {
    builder.addCase(updateDetails.fulfilled, (state, action) => {
      // ESLint: Unsafe usage of optional chaining
      // eslint-disable-next-line no-unsafe-optional-chaining
      const { displayName, iconUrl } = action.meta?.arg;

      if (displayName) {
        state.team.displayName = displayName;
      }

      if (iconUrl) {
        state.team.iconUrl = iconUrl;
      }
    });
  }
});

const actions = { ...generatedActions, updateDetails };
const selectors = {};

export { actions, selectors };

export default reducer;
