import { debounce } from 'lodash';

import { VoiceInfo } from 'modules/sdk/lib';
import { voicesStoreSelectors } from 'modules/voices/stores/selectors';

import { AnalyticsEventProperty } from '../analyticsTypes';
import { AnalyticsEventKey, logAnalyticsEvent } from '../logAnalyticsEvent';

export const logAnalyticsEventOnForwardClick = () => {
  logAnalyticsEvent(AnalyticsEventKey.playerJumpBySentence, { backward: false });
};
export const logAnalyticsEventOnBackwardClick = () => {
  logAnalyticsEvent(AnalyticsEventKey.playerJumpBySentence, { backward: true });
};

export type ListenToDocumentTriggeredFrom = 'sentence' | 'auto play' | 'play button';

export const logAnalyticsEventOnPlay = (triggeredFrom: ListenToDocumentTriggeredFrom) => {
  const currentVoice = voicesStoreSelectors.getLatestPlayingVoice();
  if (!currentVoice) return;
  logAnalyticsEvent(AnalyticsEventKey.playerListenToDocument, {
    triggeredFrom,
    voiceId: currentVoice?.id,
    voiceName: currentVoice.displayName,
    isHD: currentVoice.isPremium,
    engine: currentVoice.engine,
    isClonedVoice: currentVoice.isCustomVoice
  });
};

export const logAnalyticsEventOnSpeedChange = debounce(({ speed, wpm, source }: AnalyticsEventProperty<AnalyticsEventKey.playerSpeedChanged>) => {
  logAnalyticsEvent(AnalyticsEventKey.playerSpeedChanged, {
    speed,
    wpm,
    source
  });
}, 250);

export const logAnalyticsEventOnSpeedRampChange = ({ value }: AnalyticsEventProperty<AnalyticsEventKey.playerSpeedRampChanged>) => {
  logAnalyticsEvent(AnalyticsEventKey.playerSpeedRampChanged, {
    value
  });
};

export const logAnalyticsEventOnVoiceChange = (voiceInfo: VoiceInfo) => {
  const properties = {
    voiceId: voiceInfo.id,
    voiceName: voiceInfo.displayName,
    isHD: voiceInfo.isPremium,
    engine: voiceInfo.engine
  };
  logAnalyticsEvent(AnalyticsEventKey.playerVoiceChanged, properties);
};
