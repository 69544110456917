import * as React from 'react';

function BracesIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 2a3 3 0 00-3 3v2.934l-1.3.78a1.5 1.5 0 000 2.572l1.3.78V15a3 3 0 003 3h1a1 1 0 100-2H7a1 1 0 01-1-1v-2.934a2 2 0 00-.971-1.715L4.444 10l.585-.351A2 2 0 006 7.934V5a1 1 0 011-1h1a1 1 0 100-2H7zm6 16a3 3 0 003-3v-2.934l1.3-.78a1.5 1.5 0 000-2.572l-1.3-.78V5a3 3 0 00-3-3h-1a1 1 0 100 2h1a1 1 0 011 1v2.934a2 2 0 00.971 1.715l.585.351-.585.351A2 2 0 0014 12.066V15a1 1 0 01-1 1h-1a1 1 0 100 2h1z"
        fill="#587393"
        className="fill-icon-secondary"
      />
    </svg>
  );
}

export default BracesIcon;
