import { useRouter } from 'next/router';
import React, { ComponentType, useEffect } from 'react';

import { auth } from 'lib/speechify';

import * as faro from './';

const useFaro = () => {
  const router = useRouter();

  useEffect(() => {
    auth.onAuthStateChanged(user => {
      if (user?.uid) {
        faro.setUser({
          id: user!.uid,
          email: user.email!
        });
      }
    });

    const handleRouteChange = () => {
      faro.logEventInObservability('routeChange', { url: window.location.href });
    };

    window.addEventListener('popstate', handleRouteChange);
    router.events.on('routeChangeStart', handleRouteChange);

    return () => {
      window.removeEventListener('popstate', handleRouteChange);
      router.events.off('routeChangeStart', handleRouteChange);
    };
    // ESLint: React Hook useEffect has a missing dependency: 'router.events'. Either include it or remove the dependency array.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export default function withFaro<T extends object>(ReactComponent: ComponentType<T>): ComponentType<T> {
  const WithFaroComponent = (props: T) => {
    useFaro();
    return <ReactComponent {...props} />;
  };
  return WithFaroComponent;
}
