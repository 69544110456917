import { Result } from '@speechifyinc/multiplatform-sdk/api/util';

type Callback<R> = (result: R | Result<R>) => void;

/* eslint-disable @typescript-eslint/no-explicit-any */
export const promisify =
  <A extends any[], R>(sdkFunction: (...args: [...A, Callback<R>]) => void) =>
  (...args: A) =>
    new Promise<R>((resolve, reject) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore Parameter 'result' implicitly has an 'any' type.ts(7006)
      sdkFunction(...args, result => {
        if (result instanceof Result.Failure) reject(result.error);
        else if (result instanceof Result.Success) resolve(result.value as R);
        else resolve(result as R);
      });
    });
/* eslint-enable @typescript-eslint/no-explicit-any */

export const promisifiedFlatMap = <A, B>(arr: A[], f: (a: A) => Promise<B>) => Promise.all(arr.map(f)).then(arr => arr.flat());
