import { createNonPersistentStore } from 'lib/zustand';

export type PdfStoreState = {
  pagesInView: number[];
};

export const usePdfStore = createNonPersistentStore<PdfStoreState>(
  () => {
    return {
      pagesInView: []
    };
  },
  {
    isListeningScreenStore: true
  }
);

const updatePagesInView = (pagesInView: number[]) => {
  usePdfStore.setState({ pagesInView: [...new Set(pagesInView)] }, /* replace */ true);
};

const addPagesInView = (pagesInView: number[]) => {
  usePdfStore.setState(state => {
    return {
      pagesInView: [...new Set([...state.pagesInView, ...pagesInView])]
    };
  });
};

const clearPagesInView = () => {
  usePdfStore.setState({ pagesInView: [] }, /* replace */ true);
};

export const pdfStoreActions = {
  updatePagesInView,
  addPagesInView,
  clearPagesInView
};
