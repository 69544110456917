import * as React from 'react';

function FootersIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 1.587a1 1 0 10-2 0v7.397l-1.364-1.52a.9.9 0 10-1.272 1.272l3 3a.9.9 0 001.272 0l3-3a.9.9 0 10-1.272-1.272L11 8.984V1.588zM15 6h2v7H3V6h2a1 1 0 100-2H3a2 2 0 00-2 2v10a2 2 0 002 2h14a2 2 0 002-2V6a2 2 0 00-2-2h-2a1 1 0 100 2zM3 15h14v1H3v-1z"
        className="fill-icon-secondary"
      />
    </svg>
  );
}

export default FootersIcon;
