import { DOCXItemListenableContent } from './impl/DOCXItemListenableContent';
import { EPUBItemListenableContent } from './impl/EPUBItemListenableContent';
import { HTMLItemListenableContent } from './impl/HTMLItemListenableContent';
import { PDFItemListenableContent } from './impl/PDFItemListenableContent';
import { ScanItemListenableContent } from './impl/ScanItemListenableContent';
import { TXTItemListenableContent } from './impl/TXTItemListenableContent';

type ItemListenableContent =
  | DOCXItemListenableContent
  | EPUBItemListenableContent
  | HTMLItemListenableContent
  | PDFItemListenableContent
  | ScanItemListenableContent
  | TXTItemListenableContent;

export {
  DOCXItemListenableContent,
  EPUBItemListenableContent,
  HTMLItemListenableContent,
  type ItemListenableContent,
  PDFItemListenableContent,
  ScanItemListenableContent,
  TXTItemListenableContent
};
