import { createSlice } from '@reduxjs/toolkit';
export type WordsListenedByVoice = { [voiceId: string]: number };

/* this store is only intended to live for the duration of the app session 
and it's state is not [persisted](https://github.com/SpeechifyInc/web/blob/697d0d03325c20cfefbbc0f4f82fe1aa5649fe30/src/store/index.ts#L46) */
const name = 'session';
const initialState: {
  wordsListenedByVoice: WordsListenedByVoice;
  audioVisualCtaShown: boolean;
} = {
  wordsListenedByVoice: {},
  audioVisualCtaShown: false
};

const { actions: generatedActions, reducer } = createSlice({
  name,
  initialState,
  reducers: {
    setWordsListenedByVoice: (state, action: { payload: WordsListenedByVoice }) => {
      state.wordsListenedByVoice = action.payload;
    },
    setAudioVisualCtaShown: (state, action) => {
      state.audioVisualCtaShown = action.payload;
    }
  }
});

const actions = { ...generatedActions };
export { actions };

export default reducer;
