import * as React from 'react';

function DownloadMp3Icon({ fillClass = 'fill-icon-primary', ...props }: React.SVGProps<SVGSVGElement> & { fillClass?: string }) {
  return (
    <svg width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_18391_84162)" className={fillClass}>
        <path d="M7.5.833a.833.833 0 00-1.667 0v10.834a.833.833 0 001.667 0V.833zM10.833 2.5a.833.833 0 111.667 0v3.75a.833.833 0 11-1.667 0V2.5zM1.667 1.667c.46 0 .833.373.833.833v6.667a.833.833 0 01-1.667 0V2.5c0-.46.373-.833.834-.833zM3.333 4.167a.833.833 0 011.667 0V7.5a.833.833 0 01-1.667 0V4.167zM9.167 3.333c.46 0 .833.373.833.834V7.5a.833.833 0 11-1.667 0V4.167c0-.46.373-.834.834-.834zM14.167 11.077c.368 0 .666.299.666.667v3.45l1.024-1.256a.667.667 0 11.943.943l-2.162 2.162a.667.667 0 01-.943 0l-2.161-2.162a.667.667 0 01.942-.943l1.024 1.256v-3.45c0-.368.299-.667.667-.667z" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20 14.167a5.833 5.833 0 11-11.667 0 5.833 5.833 0 0111.667 0zm-1.5 0a4.333 4.333 0 11-8.667 0 4.333 4.333 0 018.667 0z"
        />
      </g>
      <defs>
        <clipPath id="clip0_18391_84162">
          <path fill="#fff" d="M0 0H20V20H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default DownloadMp3Icon;
