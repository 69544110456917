import { ArchiveFilesAdapter, ZipArchiveView, ZipFileEntry } from '@speechifyinc/multiplatform-sdk/api/adapters/archiveFiles';
import { BinaryContentReadableRandomly } from '@speechifyinc/multiplatform-sdk/api/util/io';
import JSZip from 'jszip';

import { callbackFromAsync } from './lib/callbackFromAsync';
import { Callback } from './lib/typeAliases';

class WebZipFileEntry extends ZipFileEntry {
  constructor(
    private _path: string,
    private zip: JSZip
  ) {
    super();
  }

  get path() {
    return this._path;
  }

  override createBinaryContentReadableRandomly = (innerCallback: Callback<BinaryContentReadableRandomly>) => {
    callbackFromAsync(async () => {
      const file = this.zip.file(this.path);
      if (!file) {
        throw new Error(`File ${this.path} not found in the archive.`);
      }

      const contentBlob = await file.async('blob');
      return new BinaryContentReadableRandomly(contentBlob);
    }, innerCallback);
  };
}

class WebZipArchiveView extends ZipArchiveView {
  constructor(private _entries: ZipFileEntry[]) {
    super();
  }

  get entries() {
    return this._entries;
  }

  override destroy(callback: Callback<void>) {
    callbackFromAsync(async () => {
      return;
    }, callback);
  }
}

export class WebArchiveFilesAdapter extends ArchiveFilesAdapter {
  override createViewOfZip(data: BinaryContentReadableRandomly, callback: Callback<ZipArchiveView>) {
    callbackFromAsync(async () => {
      const zip = new JSZip();
      const blob = data.blob;
      await zip.loadAsync(blob);

      const entries: ZipFileEntry[] = Object.keys(zip.files).map(filename => new WebZipFileEntry(filename, zip));

      return new WebZipArchiveView(entries);
    }, callback);
  }

  override createViewOfZipFromByteArrayAndDeleteAnyTemporaryCreatedFiles(data: Int8Array, callback: Callback<ZipArchiveView>) {
    callbackFromAsync(async () => {
      const zip = new JSZip();

      const blob = new Blob([data], { type: 'application/epub+zip' });
      await zip.loadAsync(blob);

      const entries: ZipFileEntry[] = Object.keys(zip.files).map(filename => new WebZipFileEntry(filename, zip));

      return new WebZipArchiveView(entries);
    }, callback);
  }
}
