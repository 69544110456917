import { Result, SDKError, File as SDKFile } from '@speechifyinc/multiplatform-sdk/api/util';

export class SDKFileFromBlob extends SDKFile {
  constructor(public readonly blob: Blob) {
    super();
  }

  override get contentType(): string {
    return this.blob.type;
  }

  override getBytes(startIndex: number, endIndex: number, callback: (p1: Result<Int8Array>) => void): void {
    this.blob
      .slice(startIndex, endIndex)
      .arrayBuffer()
      .then(buf => callback(new Result.Success(new Int8Array(buf))))
      .catch(e => callback(new Result.Failure(new SDKError.IO(e.message))));
  }

  override getSizeInBytes(callback: (r: Result<number>) => void): void {
    callback(new Result.Success(this.blob.size));
  }
}

export class WebFile extends SDKFileFromBlob {
  constructor(public readonly file: File) {
    super(file);
  }

  static fromFile(file: File): WebFile {
    return new WebFile(file);
  }
}
