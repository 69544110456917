import { AnyAction, Dispatch } from '@reduxjs/toolkit';
import { pick } from 'lodash';

import * as speechify from 'lib/speechify';
import { actions as authActions } from 'store/auth';
import { actions as personalVoicesActions } from 'store/personalVoices';

const personalVoicesMiddleware =
  ({ dispatch }: $TSFixMe) =>
  (next: Dispatch<AnyAction>) =>
  async (action: $TSFixMe) => {
    switch (action.type) {
      case authActions.setUser.pending.type: {
        const uid = action.meta?.arg?.uid;

        if (uid) {
          const personalVoices = await speechify.getPersonalVoices();
          dispatch(personalVoicesActions.set(personalVoices));
        }

        break;
      }

      case authActions.setVoice.fulfilled.type: {
        const voice = action.payload;

        if (voice?.name) {
          const isPersonalVoice = voice.name.startsWith('PVL');

          if (isPersonalVoice) {
            // ESLint: Unexpected any
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const props = pick(voice as any, ['displayName', 'name', 'engine', 'gender', 'languageCode']);
            const { displayName, name, engine, gender, languageCode } = props;
            dispatch(personalVoicesActions.select({ displayName, engine, gender, languageCode, name, isPersonalVoice: true }));
          } else {
            dispatch(personalVoicesActions.clearSelection());
          }
        }

        break;
      }

      case personalVoicesActions.deletePersonalVoice.fulfilled.type: {
        dispatch(personalVoicesActions.setDeletedId(action.payload));
        dispatch(personalVoicesActions.refresh());
        break;
      }
    }

    next(action);
  };

export default personalVoicesMiddleware;
