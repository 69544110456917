import type { VoiceInfo } from 'modules/sdk/lib';

export type VoiceState = 'default' | 'active' | 'locked';

export enum VoiceLockedReasonEnum {
  NotSubscribed = 'not-subscribed',
  RunOutOfHdWords = 'run-out-of-hd-words',
  NotAvailableForDownload = 'not-available-for-download'
}

export type VoiceLabelType = 'celebrity' | 'premium' | 'ai-enhanced' | 'custom';

export type WithVoiceState = {
  state: VoiceState;
  lockedReason?: VoiceLockedReasonEnum;
};

export type VoiceListItemProps = (VoiceInfo & {
  previewPlaying: boolean;
  type?: VoiceLabelType;
  onClick: () => void;
  onDelete?: () => void;
}) &
  WithVoiceState;

export type VoiceItemUIProp = Omit<VoiceListItemProps, 'type'>;
