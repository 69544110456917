import { PersistStorage, StorageValue } from 'zustand/middleware';

export function forceMigrationOnInitialPersist<S>(originalStorage: PersistStorage<S> | undefined): PersistStorage<S> | undefined {
  if (!originalStorage) {
    return undefined;
  }
  const modifiedStorage: PersistStorage<S> = {
    getItem: async name => {
      try {
        const item = await originalStorage.getItem(name);
        if (item) {
          return item;
        }
        return {
          state: undefined,
          version: 0
        } as StorageValue<S>;
      } catch (e) {
        console.error('forceMigrationOnInitialPersist getItem', e);
        return {
          state: undefined,
          version: 0
        } as StorageValue<S>;
      }
    },
    setItem: async (...args) => {
      const result = originalStorage.setItem(...args);
      return result;
    },
    removeItem: originalStorage.removeItem
  };
  return modifiedStorage;
}
