import { useRouter } from 'next/router';
import { CSSProperties, useMemo, useRef } from 'react';
import { RootState, useSelector } from 'store';
import { useMediaQuery } from 'usehooks-ts';

import { SpeechifyLogo } from 'assets/icons/speechify-logo';
import { IUser } from 'interfaces/user';
import * as speechify from 'lib/speechify';
import { handleOnSearchIconClick } from 'modules/listening/features/search';
import { useActiveTab } from 'modules/listening/hooks/useActiveTab';
import { useFileActionV2 } from 'modules/listening/hooks/useFileAction';
import { UseListenableContentItemIdState } from 'modules/listening/hooks/useListenableContentItemId';
import { useSearchKeyboardShortcut } from 'modules/listening/hooks/useSearchKeyboardShortcut';
import { navStoreActions } from 'modules/listening/navStore';
import { SLIDE_TRANSITION_DURATION_IN_MS } from 'modules/listening/utils/constants';
import { useLatestListenableContentTitle } from 'modules/sdk/hooks/useLatestListenableContentTitle';
import { ListenableContent } from 'modules/sdk/lib';
import { TXTItemListenableContent } from 'modules/sdk/lib/facade/listenableContent/item';
import { showUpsellModal } from 'modules/upsells/stores/actions/showUpsellModal';
import { UpsellModalType } from 'modules/upsells/stores/types';
import { cn } from 'utils/cn';

import ChevronDownIcon from '../icons/ChevronDownIcon';
import SearchIcon from '../icons/SearchIcon';
import { PageControls, PageControlsProps } from '../PageControls';
import { SCROLLBAR_WIDTH_IN_PX } from '../shared/constants';
import FileAction from './FileAction';
import AiActiveIcon from './icons/AIActive';
import AIIcon from './icons/AIIcon';
import BackIcon from './icons/BackIcon';
import DownloadMp3Icon from './icons/DownloadMp3Icon';
import SettingsIcon from './icons/SettingsIcon';
import TOCIcon from './icons/TOCIcon';
import NavIcon from './NavIcon';

export type NavFeature = 'backArrow' | 'askAi' | 'thumbnails' | 'downloadMp3' | 'search' | 'pageControls' | 'fileAction';

export const ALL_NAV_FEATURES: NavFeature[] = ['backArrow', 'askAi', 'thumbnails', 'downloadMp3', 'search', 'pageControls', 'fileAction'];

export type NavProps = {
  itemIdState: UseListenableContentItemIdState;
  listenableContent: ListenableContent;
  onBackArrowClick?: () => void;
  enabledFeatures?: NavFeature[];
  isVisible?: boolean;
} & PageControlsProps;

export const TOP_NAV_HEIGHT_IN_PX = 53;
const getFadeStyle = (isVisible: boolean): CSSProperties => ({
  opacity: isVisible ? 1 : 0,
  transition: 'opacity 0.3s',
  pointerEvents: isVisible ? 'auto' : 'none'
});

const BACKGROUND_AND_BORDER_CLASS_NAMES = 'bg-bg-primary border-b-s border-border-primary';

const Nav = ({ isVisible, listenableContent, itemIdState, onBackArrowClick, enabledFeatures = [], ...paginationControlsProps }: NavProps) => {
  useSearchKeyboardShortcut();

  const router = useRouter();
  const isDesktop = useMediaQuery('(min-width: 768px)');
  const enabledFeaturesSet = useMemo(() => new Set(enabledFeatures), [enabledFeatures]);
  const { activeTab, handleTabClick } = useActiveTab(paginationControlsProps.isPdf);
  const title = useLatestListenableContentTitle(listenableContent);
  const analyticsProperties = (listenableContent as TXTItemListenableContent)?.analyticsProperties;
  const isAIStory = analyticsProperties?.isAIStory;
  const library = useSelector((state: RootState) => state.library);
  const items = library?.items;

  const shouldShowChapterTotal = useMemo(() => {
    const folderId = window.location.href.includes('folder=') ? router.query.folder?.toString() : null;
    return isAIStory && (items || []).filter(item => item.parentFolderId === (folderId || null)).length < 11;
  }, [isAIStory, items, router.query.folder]);

  const menuRef = useRef<HTMLButtonElement>(null);

  const { showFileAction, onFileNameClick, onMenuLeave, onActionClick } = useFileActionV2({
    menuRef,
    itemIdState
  });

  const currentUser = useSelector<IUser | null>(state => state.auth.user);

  const renderAskAiIcon = () => {
    if (!isDesktop) {
      return null;
    }

    if (enabledFeaturesSet.has('askAi')) {
      return (
        <NavIcon
          onClick={() => {
            handleTabClick('ask-ai');
            setTimeout(() => {
              const askAiInput = document.querySelector<HTMLInputElement>('.ask-ai-input-focus');
              askAiInput?.focus();
            }, SLIDE_TRANSITION_DURATION_IN_MS);
          }}
          active={activeTab === 'ask-ai'}
          icon={
            activeTab === 'ask-ai' ? (
              <AiActiveIcon />
            ) : (
              <AIIcon className="h-6 w-6 [&>#ai-icon-gradient]:fill-[url(#ai_icon_light_gradient)] dark:[&>#ai-icon-gradient]:fill-[url(#ai_icon_dark_gradient)]" />
            )
          }
        />
      );
    }

    if (currentUser && speechify.canUpgrade(currentUser)) {
      return <NavIcon onClick={() => showUpsellModal(UpsellModalType.ContextualAskAI)} icon={<AIIcon />} />;
    }

    return null;
  };

  const renderDownloadMp3Icon = () => {
    if (!isDesktop) {
      return null;
    }

    if (enabledFeaturesSet.has('downloadMp3')) {
      return <NavIcon onClick={() => navStoreActions.setModalState('downloadMp3')} icon={<DownloadMp3Icon />} />;
    }

    if (currentUser && speechify.canUpgrade(currentUser)) {
      return <NavIcon onClick={() => showUpsellModal(UpsellModalType.ContextualDownloadMP3)} icon={<DownloadMp3Icon />} />;
    }

    return null;
  };

  return (
    <>
      <nav
        className="fixed flex z-10"
        style={{
          height: `${TOP_NAV_HEIGHT_IN_PX}px`,
          width: isDesktop ? `calc(100% - ${SCROLLBAR_WIDTH_IN_PX}px)` : '100%',
          display: 'flex',
          ...getFadeStyle(!!isVisible)
        }}
      >
        {/* Left section */}
        <div className={cn(BACKGROUND_AND_BORDER_CLASS_NAMES, 'px-1 py-2 flex items-center gap-2 flex-shrink-0')}>
          {enabledFeaturesSet.has('backArrow') ? (
            <NavIcon icon={<BackIcon />} onClick={onBackArrowClick} />
          ) : (
            <a href="https://speechify.com" target="_blank" rel="noreferrer">
              <SpeechifyLogo className="w-8 ml-3 dark:text-white text-electric-400" />
            </a>
          )}

          {isDesktop && (enabledFeaturesSet.has('askAi') || enabledFeaturesSet.has('thumbnails')) && <div className="w-[1px] h-6 bg-border-primary" />}
          {renderAskAiIcon()}
          {isDesktop && enabledFeaturesSet.has('thumbnails') && paginationControlsProps.isPdf && (
            <NavIcon active={activeTab === 'navigator'} icon={<TOCIcon />} onClick={() => navStoreActions.toggleActiveTab('navigator')} />
          )}
        </div>

        {/* Middle section (title) */}
        <div className={cn(BACKGROUND_AND_BORDER_CLASS_NAMES, 'px-1 py-2 flex items-center justify-center relative flex-grow min-w-0')}>
          <button
            ref={menuRef}
            className={cn(
              'text-heading-6 text-text-primary relative flex items-center justify-center rounded-lg py-2 px-3',
              enabledFeaturesSet.has('fileAction') ? 'cursor-pointer hover:bg-surface-primary-hovered' : 'cursor-default',
              'focus-visible:outline-2 focus-visible:outline focus-visible:outline-[#1D76ED] w-fit',
              'text-center'
            )}
            style={{
              maxWidth: 'min(100%, 400px)'
            }}
            onClick={onFileNameClick}
            onMouseLeave={onMenuLeave}
          >
            <div className={'text-ellipsis whitespace-nowrap overflow-hidden'}>
              {title}
              {shouldShowChapterTotal ? '/10' : ''}
            </div>
            {!itemIdState.isLoading && enabledFeaturesSet.has('fileAction') ? <ChevronDownIcon className="w-4 h-4 ml-1 flex-shrink-0" /> : null}

            {enabledFeaturesSet.has('fileAction') && showFileAction && (
              <div className="absolute left-1/2 transform -translate-x-1/2 z-10 top-full -mt-9" onMouseLeave={onMenuLeave}>
                <FileAction listenableContent={listenableContent} onActionClick={onActionClick} showDownloadMp3={enabledFeaturesSet.has('downloadMp3')} />
              </div>
            )}
          </button>

          {renderDownloadMp3Icon()}
        </div>

        {/* Right section */}
        <div className={cn(BACKGROUND_AND_BORDER_CLASS_NAMES, 'px-1 py-2 flex justify-end gap-2 items-center flex-shrink-0')}>
          {isDesktop && enabledFeaturesSet.has('search') && paginationControlsProps.isPdf && (
            <NavIcon icon={<SearchIcon />} active={activeTab === 'search'} onClick={handleOnSearchIconClick} />
          )}
          <NavIcon
            className={cn(!paginationControlsProps.isPdf && 'mr-spl-6')}
            icon={<SettingsIcon />}
            active={activeTab === 'settings'}
            onClick={() => navStoreActions.toggleActiveTab('settings')}
          />
          {isDesktop && enabledFeaturesSet.has('pageControls') && paginationControlsProps.isPdf && (
            <>
              <div className="h-spl-9 w-[1px] bg-border-primary" />
              <PageControls {...paginationControlsProps} />
            </>
          )}
        </div>
      </nav>
      {/* Scrollbar space needs to have different z-index here */}
      <div
        className={cn('fixed right-0', BACKGROUND_AND_BORDER_CLASS_NAMES)}
        style={{
          zIndex: 5,
          width: `${SCROLLBAR_WIDTH_IN_PX}px`,
          height: `${TOP_NAV_HEIGHT_IN_PX}px`,
          ...getFadeStyle(!!isVisible)
        }}
      />
    </>
  );
};

export default Nav;
