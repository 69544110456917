import type { EmbeddedReadingBundle as SDKEmbeddedReadingBundle, ReadingBundle as SDKReadingBundle } from '@speechifyinc/multiplatform-sdk';

import { PSPDFKitFacade } from 'lib/pdf/pspdfkit';
import type { Virtualizer } from 'modules/listening/features/reader/components/classic/ClassicReaderV2';
import { MeasurementKey } from 'modules/profiling/measurementTypes';
import { profilingStoreActions } from 'modules/profiling/profilingStore';
import { BaseDestructible } from 'utils/destructible';
import { Nullable } from 'utils/types';

import { ListenableContent, PDFOverlayInfo, PlaybackInfo, SDKContentBundlerOptionsFacade, VoiceInfo, getSDK } from './lib';
import { ClassicOverlayInfo } from './lib/facade/overlay/ClassicOverlayInfo';
import { EmbeddedOverlayInfo } from './lib/facade/overlay/EmbeddedOverlayInfo';

type BaseListeningRequirements = {
  listenableContent: ListenableContent;
  initialVoice: Nullable<VoiceInfo>;
  initialWPM: number;
  shouldEnableOCR: boolean;
};

type PDFListeningRequirements = BaseListeningRequirements;

type ClassicListeningRequirements = BaseListeningRequirements & {
  overlayElement: HTMLElement;
  scrollerElement: HTMLElement;
  virtualizer: Virtualizer;
};

export type ListeningRequirements = PDFListeningRequirements | ClassicListeningRequirements;

export type EmbeddedListeningRequirements = Pick<BaseListeningRequirements, 'initialVoice' | 'initialWPM'> & {
  element: HTMLElement;
  overlayElement: HTMLElement;
  scrollerElement: HTMLElement;
};

export class ListeningDependencies extends BaseDestructible {
  constructor(
    private readonly bundle: SDKReadingBundle,
    public readonly playbackInfo: PlaybackInfo,
    public readonly overlayInfo: PDFOverlayInfo | ClassicOverlayInfo,
    public readonly contentBundlerOptionsFacade: SDKContentBundlerOptionsFacade
  ) {
    super();
  }

  destroy(): void {
    this.playbackInfo.destroy();
    this.overlayInfo.destroy();
    this.bundle.destroy();
  }
}

export class EmbeddedListeningDependencies extends BaseDestructible {
  constructor(
    private readonly bundle: SDKEmbeddedReadingBundle,
    public readonly playbackInfo: PlaybackInfo,
    public readonly overlayInfo: EmbeddedOverlayInfo
  ) {
    super();
  }

  destroy(): void {
    this.playbackInfo.destroy();
    this.overlayInfo.destroy();
    this.bundle.destroy();
  }
}

export class ListeningDependenciesFactory {
  public static async create(requirements: ListeningRequirements): Promise<ListeningDependencies> {
    const { sdk, bundle: bundleFacade, voice: voiceFacade } = await getSDK();

    const allVoices = await profilingStoreActions.measureAction(() => voiceFacade.getAllVoices(), MeasurementKey.sdkGetAllVoices);
    const initialVoice = voiceFacade.getVoiceSpec(requirements.initialVoice);

    profilingStoreActions.startMeasurement(MeasurementKey.sdkBundleCreation);
    const { readingBundle: bundle, contentBundlerOptions } = await bundleFacade.createBundles({
      ...requirements,
      initialVoice,
      allVoices
    });
    profilingStoreActions.endMeasurement(MeasurementKey.sdkBundleCreation);
    profilingStoreActions.endMeasurement(MeasurementKey.sdkSetup);

    const playbackInfo = new PlaybackInfo(sdk, bundle, initialVoice);

    const createOverlayInfo = async () => {
      if (requirements.listenableContent.isPDF()) {
        const pspdfKitFacade = await PSPDFKitFacade.getMostRecentInstance();
        const overlayInfo = new PDFOverlayInfo(sdk, bundle, playbackInfo, pspdfKitFacade);
        await overlayInfo.addRenderedContentToOverlayProvider();
        return overlayInfo;
      }
      const classicRequirements = requirements as ClassicListeningRequirements;
      const classicOverlayInfo = new ClassicOverlayInfo(
        sdk,
        bundle,
        playbackInfo,
        classicRequirements.scrollerElement,
        classicRequirements.overlayElement,
        classicRequirements.virtualizer
      );
      await classicOverlayInfo.addRenderedContentToOverlayProvider();
      return classicOverlayInfo;
    };

    const overlayInfo = await createOverlayInfo();

    return new ListeningDependencies(bundle, playbackInfo, overlayInfo, contentBundlerOptions);
  }

  public static async createEmbeddedListeningDeps({
    overlayElement,
    scrollerElement,
    ...requirements
  }: EmbeddedListeningRequirements): Promise<EmbeddedListeningDependencies> {
    const { sdk, bundle: bundleFacade, voice: voiceFacade } = await getSDK();

    const allVoices = await profilingStoreActions.measureAction(() => voiceFacade.getAllVoices(), MeasurementKey.sdkGetAllVoices);
    const initialVoice = voiceFacade.getVoiceSpec(requirements.initialVoice);

    profilingStoreActions.startMeasurement(MeasurementKey.sdkBundleCreation);

    const bundle = await bundleFacade.createEmbeddedBundles({
      ...requirements,
      initialVoice,
      allVoices,
      shouldEnableOCR: false
    });

    profilingStoreActions.endMeasurement(MeasurementKey.sdkBundleCreation);
    profilingStoreActions.endMeasurement(MeasurementKey.sdkSetup);

    const playbackInfo = new PlaybackInfo(sdk, bundle, initialVoice);
    const overlayInfo = new EmbeddedOverlayInfo(sdk, bundle, playbackInfo, overlayElement, scrollerElement);

    return new EmbeddedListeningDependencies(bundle, playbackInfo, overlayInfo);
  }
}
