export const POSSIBLE_DAILY_LISTENING_GOALS_IN_MINUTES: number[] = [2, 5, 10, 15];

export const POSSIBLE_STREAK_GOALS_IN_DAYS: { label: string; value: number }[] = [
  { label: 'Good', value: 7 },
  { label: 'Great', value: 14 },
  { label: 'Incredible', value: 30 },
  { label: 'Unstoppable', value: 50 }
];

export const isValidStreakGoal = (streakGoalInDays: number): boolean => {
  return POSSIBLE_STREAK_GOALS_IN_DAYS.some(goal => goal.value === streakGoalInDays);
};

export const getNextStreakGoal = (currentStreakGoal: number): number => {
  if (currentStreakGoal >= POSSIBLE_STREAK_GOALS_IN_DAYS[POSSIBLE_STREAK_GOALS_IN_DAYS.length - 1].value) {
    return currentStreakGoal + 1;
  }

  const currentStreakGoalIndex = POSSIBLE_STREAK_GOALS_IN_DAYS.findIndex(goal => goal.value === currentStreakGoal);

  if (currentStreakGoalIndex === -1) {
    return POSSIBLE_STREAK_GOALS_IN_DAYS[0].value;
  }

  if (currentStreakGoalIndex + 1 >= POSSIBLE_STREAK_GOALS_IN_DAYS.length) {
    return POSSIBLE_STREAK_GOALS_IN_DAYS[POSSIBLE_STREAK_GOALS_IN_DAYS.length - 1].value + 1;
  }

  return POSSIBLE_STREAK_GOALS_IN_DAYS[currentStreakGoalIndex + 1].value;
};
