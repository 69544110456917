import { openDB } from 'idb';
import { type StateStorage } from 'zustand/middleware';

import { IDB_DATABASE_NAME } from './constants';

export class IndexedDBStorage implements StateStorage {
  private dbPromise: ReturnType<typeof openDB> = new Promise(() => {});

  constructor(
    public objectStoreName: string,
    public dbName = IDB_DATABASE_NAME
  ) {
    this.dbPromise = openDB(dbName, 1, {
      upgrade(db) {
        db.createObjectStore(objectStoreName);
      }
    });
  }

  async getItem(name: string): Promise<string | null> {
    const db = await this.dbPromise;
    const item = await db.get(this.objectStoreName, name);
    return item;
  }

  async setItem(name: string, value: string) {
    const db = await this.dbPromise;
    const res = await db.put(this.objectStoreName, value, name);
    return res;
  }

  async removeItem(name: string) {
    const db = await this.dbPromise;
    const res = await db.delete(this.objectStoreName, name);
    return res;
  }
}
