import React from 'react';

function AiActiveIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M16.812 7.9a.9.9 0 10-1.8 0v8a.9.9 0 001.8 0v-8z" className="fill-icon-accent" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.041 16.153l-.413-1.24H8.103a.809.809 0 01-.127-.01l-.417 1.25a.8.8 0 11-1.518-.506L8.567 8.07c.395-1.185 2.071-1.185 2.466 0l2.526 7.577a.8.8 0 11-1.518.506zM9.801 9.43l1.294 3.883h-2.59L9.8 9.43z"
        className="fill-icon-accent"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.141 7.744c.17-2.477.255-3.715 1.584-5.04 1.329-1.326 2.557-1.407 5.015-1.57A64.734 64.734 0 0112 1c1.61 0 3.031.053 4.26.135 2.458.162 3.686.243 5.015 1.569 1.33 1.325 1.414 2.563 1.584 5.04.084 1.24.141 2.663.141 4.256 0 1.501-.05 2.851-.127 4.04-.168 2.6-.252 3.9-1.58 5.231-1.329 1.332-2.638 1.42-5.255 1.595-1.2.08-2.554.134-4.038.134-1.484 0-2.837-.053-4.037-.134-2.618-.175-3.927-.263-5.256-1.595-1.328-1.332-1.412-2.631-1.58-5.231A62.66 62.66 0 011 12c0-1.593.056-3.016.141-4.256zM15.903 20.87C14.745 20.949 13.436 21 12 21a58.33 58.33 0 01-3.903-.13c-2.748-.184-3.257-.293-3.974-1.011-.717-.72-.824-1.223-1-3.948A60.663 60.663 0 013 12c0-1.546.055-2.924.137-4.12.176-2.574.275-3.037 1-3.76.726-.725 1.184-.821 3.735-.99A62.727 62.727 0 0112 3c1.566 0 2.943.052 4.128.13 2.551.169 3.009.265 3.735.99.725.723.824 1.186 1 3.76.082 1.196.137 2.574.137 4.12 0 1.457-.049 2.764-.123 3.91-.176 2.726-.283 3.23-1 3.949-.717.718-1.226.827-3.973 1.012z"
        className="fill-icon-accent"
      />
    </svg>
  );
}

export default AiActiveIcon;
