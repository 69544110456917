import * as React from 'react';

function CitationIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M8.787 8.661c0 3.285-1.95 5.908-4.86 6.751-.577.167-1.109-.304-1.109-.905v-.143c0-.482.348-.885.804-1.041 1.458-.501 2.444-1.554 2.444-2.862-.351.131-.746.22-1.229.22-1.58 0-2.852-1.098-2.852-3.029 0-1.931 1.316-3.292 3.247-3.292 2.063 0 3.555 1.668 3.555 4.301zm9.23 0c0 3.285-1.951 5.908-4.86 6.751-.578.167-1.109-.304-1.109-.905v-.143c0-.482.348-.884.803-1.042 1.447-.501 2.4-1.554 2.4-2.861-.35.131-.745.22-1.228.22-1.58 0-2.809-1.098-2.809-3.029 0-1.931 1.317-3.292 3.248-3.292 2.063 0 3.555 1.668 3.555 4.301z"
        className="fill-icon-secondary"
      />
    </svg>
  );
}

export default CitationIcon;
