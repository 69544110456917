import * as React from 'react';

function ParenthesesIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.741 17.68a1 1 0 00-.043-1.404 2.795 2.795 0 01-.199-.241 5.651 5.651 0 01-.584-1.022C6.475 14.042 6 12.433 6 10s.475-4.042.915-5.013c.22-.487.436-.82.584-1.021a2.78 2.78 0 01.199-.242 1 1 0 00-1.37-1.457l.68.732c-.68-.732-.68-.731-.681-.73h-.001l-.002.002-.005.005-.013.012a2.058 2.058 0 00-.127.133 4.761 4.761 0 00-.289.357c-.228.309-.515.763-.797 1.384C4.53 5.406 4 7.298 4 10s.53 4.594 1.093 5.838c.282.621.569 1.075.797 1.384a4.77 4.77 0 00.384.458l.032.032.013.012.005.005.002.002h.001c0 .001.001.002.681-.731l-.68.733a1 1 0 001.413-.053zm4.518 0a1 1 0 01.043-1.404 2.788 2.788 0 00.199-.241 5.65 5.65 0 00.584-1.022c.44-.971.915-2.58.915-5.013s-.475-4.042-.915-5.013a5.648 5.648 0 00-.584-1.021 2.773 2.773 0 00-.199-.242 1 1 0 011.37-1.457l-.68.733c.68-.733.68-.732.681-.732l.001.001.002.002.005.005.013.012a1.843 1.843 0 01.127.133c.076.084.175.202.289.357.228.309.515.763.797 1.384C15.47 5.406 16 7.298 16 10s-.53 4.594-1.093 5.838a7.64 7.64 0 01-.797 1.384 4.76 4.76 0 01-.384.458 1.843 1.843 0 01-.033.032l-.012.012-.005.005-.002.002h-.001c0 .001-.001.002-.681-.731l.68.733a1 1 0 01-1.413-.053z"
        className="fill-icon-secondary"
      />
    </svg>
  );
}

export default ParenthesesIcon;
