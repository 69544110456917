import { SDKVoiceFacade } from 'modules/sdk/lib';

import { useVoiceStore } from '../voicesStore';

export const stopPreview = () => {
  const onPreviewStateChange = (isPlaying: boolean) => {
    useVoiceStore.setState({ isPreviewPlaying: isPlaying });
  };

  SDKVoiceFacade.singleton.stopPreview(onPreviewStateChange);
};
