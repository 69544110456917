import * as React from 'react';

function AIIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={26} height={26} viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M17.812 8.9a.9.9 0 10-1.8 0v8a.9.9 0 001.8 0v-8z" fill="currentColor" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.041 17.153l-.413-1.24H9.103a.809.809 0 01-.127-.01l-.417 1.25a.8.8 0 11-1.518-.506L9.567 9.07c.395-1.185 2.071-1.185 2.466 0l2.526 7.577a.8.8 0 11-1.518.506zm-2.24-6.723l1.294 3.883h-2.59L10.8 10.43z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.141 8.744c.17-2.477.255-3.715 1.584-5.04 1.329-1.326 2.557-1.407 5.015-1.57A64.734 64.734 0 0113 2c1.61 0 3.031.053 4.26.135 2.458.162 3.686.243 5.015 1.569 1.33 1.325 1.414 2.563 1.584 5.04.084 1.24.141 2.663.141 4.256 0 1.501-.05 2.851-.127 4.04-.168 2.6-.252 3.9-1.58 5.231-1.329 1.332-2.638 1.42-5.255 1.595-1.2.08-2.554.134-4.038.134-1.484 0-2.837-.053-4.037-.134-2.618-.175-3.927-.263-5.256-1.595-1.328-1.332-1.412-2.631-1.58-5.231A62.66 62.66 0 012 13c0-1.593.056-3.016.141-4.256zM16.904 21.87C15.745 21.949 14.436 22 13 22a58.33 58.33 0 01-3.903-.13c-2.748-.184-3.257-.293-3.974-1.011-.717-.72-.824-1.223-1-3.948A60.663 60.663 0 014 13c0-1.546.055-2.924.137-4.12.176-2.574.275-3.037 1-3.76.726-.725 1.184-.821 3.735-.99A62.726 62.726 0 0113 4c1.566 0 2.943.052 4.128.13 2.551.169 3.009.265 3.735.99.725.723.824 1.186 1 3.76.082 1.196.137 2.574.137 4.12 0 1.457-.049 2.764-.123 3.91-.176 2.726-.283 3.23-1 3.949-.717.718-1.226.827-3.973 1.012z"
        fill="currentColor"
      />
      <path
        id="ai-icon-gradient"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.275 3.704l1.413-1.416c1.865 1.86 1.996 3.8 2.149 6.07l.017.249A64.38 64.38 0 0126 13c0 1.546-.052 2.94-.131 4.169l-.018.282c-.15 2.352-.277 4.363-2.142 6.232-1.866 1.87-3.887 2.004-6.251 2.16l-.286.019C15.93 25.945 14.532 26 13 26c-1.531 0-2.93-.055-4.172-.138l-.286-.02c-2.364-.155-4.385-.289-6.25-2.159-1.866-1.87-1.993-3.88-2.143-6.232l-.018-.282A64.655 64.655 0 010 13c0-1.64.058-3.11.146-4.393l.017-.249c.153-2.27.284-4.21 2.15-6.07l1.412 1.416C2.395 5.029 2.31 6.267 2.14 8.744A62.354 62.354 0 002 13c0 1.501.05 2.851.127 4.04.168 2.6.252 3.9 1.58 5.231 1.329 1.332 2.638 1.42 5.256 1.595 1.2.08 2.553.134 4.037.134s2.837-.053 4.038-.134c2.617-.175 3.926-.263 5.255-1.595 1.328-1.332 1.412-2.631 1.58-5.231C23.95 15.852 24 14.5 24 13c0-1.593-.056-3.016-.141-4.256-.17-2.477-.255-3.715-1.584-5.04zm0 0l1.413-1.416C21.823.428 19.894.302 17.635.155l-.243-.016A66.73 66.73 0 0013 0c-1.655 0-3.12.055-4.392.139l-.243.016C6.106.302 4.177.427 2.312 2.288l1.413 1.416c1.329-1.326 2.557-1.407 5.015-1.57A64.734 64.734 0 0113 2c1.61 0 3.031.053 4.26.135 2.458.162 3.686.243 5.015 1.569z"
      />
      <defs>
        <radialGradient
          id="ai_icon_light_gradient"
          cx={0}
          cy={0}
          r={1}
          gradientUnits="userSpaceOnUse"
          gradientTransform="rotate(45.556 -1.381 3.381) scale(36.4177)"
        >
          <stop stopColor="#E09DFF" />
          <stop offset={0.435189} stopColor="#9FA4FF" />
          <stop offset={1} stopColor="#71DFFF" />
        </radialGradient>
        <radialGradient
          id="ai_icon_dark_gradient"
          cx={0}
          cy={0}
          r={1}
          gradientUnits="userSpaceOnUse"
          gradientTransform="rotate(45.556 -1.381 3.381) scale(36.4177)"
        >
          <stop stopColor="#FF00F5" />
          <stop offset={0.435189} stopColor="#D049FF" />
          <stop offset={1} stopColor="#00C2FF" />
        </radialGradient>
      </defs>
    </svg>
  );
}

export default AIIcon;
