import type { ListenableContent } from 'modules/sdk/lib';

export interface LoadingInstantListening {
  flowStartTimestamp: number;
  isLoading: true;
  details?: never;
}

export interface LoadedInstantListening {
  flowStartTimestamp: number;
  isLoading: false;
  details: {
    listenableContent: ListenableContent;
  };
}

export type InstantListening = LoadingInstantListening | LoadedInstantListening;

export type IMainPageState = {
  activeDropTargetId: string | undefined;
  activeDragTargetId: string | undefined;
  instantListening: InstantListening | null;
  isDragging: boolean;
  mobileAction: string;
  newFolderParentId: string | undefined;
  newlyImportedItemId: string | null;
};

export const isInstantListeningLoaded = (state: InstantListening): state is LoadedInstantListening =>
  state.isLoading === false && 'listenableContent' in state.details;
