import { createNonPersistentStore } from 'lib/zustand';
import { logListeningScreenTabNavigationAnalyticsEvent } from 'modules/analytics/impl/navigationAnalytics';
import { VoiceInfo } from 'modules/sdk/lib';

type Modals = 'delete' | 'downloadMp3' | 'rename' | 'move' | 'help-us-improve-ai-chat' | 'delete-personal-voice' | 'delete-ai-chat' | null;

export type ListeningScreenTabs = 'settings' | 'navigator' | 'ask-ai' | 'search';

type NavState =
  | {
      modalOpen: 'delete-personal-voice';
      data: VoiceInfo;
      activeTab: ListeningScreenTabs | null;
      downloadMp3ModalOpen: boolean;
    }
  | {
      modalOpen: Exclude<Modals, 'delete-personal-voice'>;
      activeTab: ListeningScreenTabs | null;
      downloadMp3ModalOpen: boolean;
    };

export const useNavStore = createNonPersistentStore<NavState>(
  () => {
    return {
      activeTab: null,
      modalOpen: null,
      downloadMp3ModalOpen: false
    };
  },
  {
    isListeningScreenStore: true
  }
);

const setModalState = (modal: Exclude<Modals, 'delete-personal-voice'>) => {
  useNavStore.setState({ modalOpen: modal });
};

const showDeletePersonalVoiceConfirmationModal = (voiceInfo: VoiceInfo) => {
  useNavStore.setState({
    modalOpen: 'delete-personal-voice',
    data: voiceInfo
  });
};

const setActiveTab = (tab: ListeningScreenTabs | null) => {
  const prevTab = useNavStore.getState().activeTab;
  useNavStore.setState({
    activeTab: tab
  });
  logListeningScreenTabNavigationAnalyticsEvent(tab, prevTab);
};

const toggleActiveTab = (tab: ListeningScreenTabs) => {
  const state = useNavStore.getState();

  if (state.activeTab === tab) {
    setActiveTab(null);
  } else {
    setActiveTab(tab);
  }
};

export const navStoreActions = {
  clearActiveTab: () => setActiveTab(null),
  clearModalState: () => setModalState(null),
  setActiveTab,
  setModalState,
  showDeletePersonalVoiceConfirmationModal,
  toggleActiveTab
};
