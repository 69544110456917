import { createNonPersistentStore } from 'lib/zustand';

enum SideBannerType {
  CSAT = 'csat',
  UNLIMITED_SPEED = 'unlimited_speed',
  UPSELL = 'upsell'
}

type CSATSideBanner = {
  type: SideBannerType.CSAT;
};

type MobileUpsellSideBanner = {
  type: SideBannerType.UPSELL;
};

type UnlimitedSpeed = {
  type: SideBannerType.UNLIMITED_SPEED;
};

type SideBanner = CSATSideBanner | MobileUpsellSideBanner | UnlimitedSpeed;

interface SideBannerState {
  queue: SideBanner[];
  typeSet: Set<SideBannerType>;
}

const useSideBannerStore = createNonPersistentStore<SideBannerState>(
  () => ({
    queue: [],
    // this key is required so that we don't have duplicate values
    // can consider a map here instead of queue + guard set if there are not too many keys
    typeSet: new Set()
  }),
  {
    isListeningScreenStore: true
  }
);

const sideBannerActions = {
  add: (banner: SideBanner) => {
    useSideBannerStore.setState(state => {
      if (state.typeSet.has(banner.type)) {
        return state; // Banner type already exists, no change
      }
      return {
        queue: [...state.queue, banner],
        typeSet: new Set(state.typeSet).add(banner.type)
      };
    });
  },

  remove: () => {
    useSideBannerStore.setState(state => {
      if (state.queue.length === 0) return state;
      const [removed, ...rest] = state.queue;
      const newTypeSet = new Set(state.typeSet);
      newTypeSet.delete(removed.type);
      return { queue: rest, typeSet: newTypeSet };
    });
  },

  clear: () => {
    useSideBannerStore.setState({ queue: [], typeSet: new Set() });
  },

  current: () => useSideBannerStore.getState().queue[0] || null,

  has: (type: SideBannerType) => useSideBannerStore.getState().typeSet.has(type),

  isEmpty: () => useSideBannerStore.getState().queue.length === 0
};

export { type SideBanner, sideBannerActions, SideBannerType, useSideBannerStore };
